import { useState, useEffect, useCallback } from 'react';

import { RefObject } from 'react';
interface UseSwipeOptions {
    ref: RefObject<HTMLElement>;
    onTouchEndHandler?: ({
        ref,
        startTime,
        stopTime,
        startY,
        stopY,
        event,
    }: {
        ref: RefObject<HTMLElement>;
        startTime: number;
        startY: number;
        stopTime: number;
        stopY: number;
        event: TouchEvent;
    }) => void;
    onSwipeHandler: ({
        ref,
        currentY,
        initialY,
        event,
    }: {
        ref: RefObject<HTMLElement>;
        currentY: number;
        initialY: number;
        event: TouchEvent;
    }) => void;
}
interface IUseSwipeState {
    initialX: number;
    initialY: number;
    startX: number;
    startY: number;
    currentX: number;
    currentY: number;
    stopX: number;
    stopY: number;
    startTime: number;
    currentTime: number;
    stopTime: number;
}

const isTouchDevice = () => 'ontouchstart' in window;

export const useSwipe = ({ ref, onSwipeHandler, onTouchEndHandler }: UseSwipeOptions) => {
    const [
        {
            initialX,
            initialY,
            startX,
            startY,
            currentX,
            currentY,
            stopX,
            stopY,
            startTime,
            currentTime,
            stopTime,
        },
        setState,
    ] = useState<IUseSwipeState>({
        initialX: 0,
        initialY: 0,
        startX: 0,
        startY: 0,
        currentX: 0,
        currentY: 0,
        stopX: 0,
        stopY: 0,
        startTime: new Date().getTime(),
        currentTime: new Date().getTime(),
        stopTime: new Date().getTime(),
    });

    const handleTouchStart = (event: TouchEvent) => {
        setState((prev) => ({
            ...prev,
            initialX: event.changedTouches[0].clientX,
            startX: event.changedTouches[0].clientX,
            currentX: event.changedTouches[0].clientX,
            stopX: 0,
            initialY: event.changedTouches[0].clientY,
            startY: event.changedTouches[0].clientY,
            currentY: event.changedTouches[0].clientY,
            stopY: 0,
            startTime: new Date().getTime(),
            stopTime: 0,
        }));
    };

    const handleTouchMove = useCallback(
        (event: TouchEvent) => {
            setState((prev) => {
                const currentTime = new Date().getTime();
                const isDelayed = currentTime - prev.currentTime > 500;
                const currentXPosition = event.changedTouches[0].clientX;
                const isChangeXDirection =
                    (currentXPosition < prev.startX && currentXPosition > prev.currentX) ||
                    (currentXPosition > prev.startX && currentXPosition < prev.currentX);
                const currentYPosition = event.changedTouches[0].clientY;
                const isChangeYDirection =
                    (currentYPosition < prev.startY && currentYPosition > prev.currentY) ||
                    (currentYPosition > prev.startY && currentYPosition < prev.currentY);

                onSwipeHandler({
                    ref,
                    currentY: currentYPosition,
                    initialY: prev.initialY,
                    event,
                });

                return {
                    ...prev,
                    startX: isDelayed || isChangeXDirection ? currentXPosition : prev.startX,
                    startY: isDelayed || isChangeYDirection ? currentYPosition : prev.startY,
                    startTime:
                        isDelayed || isChangeXDirection || isChangeYDirection
                            ? currentTime
                            : prev.startTime,
                    currentX: currentXPosition,
                    currentY: currentYPosition,
                    currentTime: currentTime,
                };
            });
        },
        [onSwipeHandler, ref]
    );

    const handleTouchEnd = useCallback(
        (event: TouchEvent) => {
            if (event.changedTouches && event.changedTouches.length > 0) {
                setState((prev) => {
                    if (onTouchEndHandler) {
                        onTouchEndHandler({
                            event,
                            ref,
                            stopTime: new Date().getTime(),
                            startTime: prev.startTime,
                            stopY: event.changedTouches[0].clientY,
                            startY: prev.startY,
                        });
                    }
                    return {
                        ...prev,
                        stopX: event.changedTouches[0].clientX,
                        stopY: event.changedTouches[0].clientY,
                        stopTime: new Date().getTime(),
                    };
                });
            }
        },
        [onTouchEndHandler, ref]
    );

    useEffect(() => {
        const currentElement = ref.current;

        if (currentElement && isTouchDevice()) {
            currentElement.addEventListener('touchstart', handleTouchStart);
            currentElement.addEventListener('touchmove', handleTouchMove);
            currentElement.addEventListener('touchend', handleTouchEnd);
        }
        return () => {
            if (currentElement && isTouchDevice()) {
                currentElement.addEventListener('touchstart', handleTouchStart);
                currentElement.addEventListener('touchmove', handleTouchMove);
                currentElement.addEventListener('touchend', handleTouchEnd);
            }
        };
    }, [handleTouchEnd, handleTouchMove, ref]);

    return {
        startX,
        startY,
        stopX,
        stopY,
        currentX,
        currentY,
        startTime,
        stopTime,
        currentTime,
        startXOnlyRead: initialX,
        startYOnlyRead: initialY,
    };
};
