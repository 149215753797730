export const getIcon = (name: string) => {
    return import(`./${name}`).then((Component) => Component);
};

export const Icons = [
    'AddressBookIcon',
    'AngleDoubleLeft',
    'AngleDoubleRight',
    'ArrowIcon',
    'BusinessTimeIcon',
    'CalendarRegularIcon',
    'CalendarSolid',
    'Call',
    'CaretDownSolid',
    'CaretUpSolid',
    'CartIconBW',
    'CartIcon',
    'Cart',
    'ChatBubble',
    'CheckCircle',
    'Check',
    'CheckSolid',
    'CloseIcon',
    'CommentsRegular',
    'Dollar',
    'ExclamationCircle',
    'Explore',
    'Favorite',
    'HeartIcon',
    'Help',
    'History',
    'IconMenu',
    'InfoCircleSolid',
    'LibraryBooks',
    'NonWorkingHoursIcon',
    'PencilSolid',
    'Phone',
    'PlusCircleSolid',
    'Satellite',
    'SearchIcon',
    'ShoppingCart',
    'Spinner',
    'SuccessfulIcon',
    'Tag',
    'TicketSolid',
    'TimesCircle',
    'Times',
    'WhitePhoneIcon',
    'Smile',
    'ComunicationIcon',
    'Refresh',
    'AccordionArrow',
    'GripLine',
    'Search',
    'Busket',
    'HappyCart',
    'Ticket',
    'Ticket2',
    'Tickets',
    'Vector',
    'Buy',
    'LowPrices',
    'LocalSupport',
    'Verified',
    'Secure',
    'Error',
    'Indicator',
    'UFO',
    'Timer',
    'Nearest',
    'Today',
    'Tomorrow',
    'SouldOut',
    'Star',
    'StarHalf',
    'starEmpty',
    'PeopleGroup',
];
