import { ReactElement, memo, ReactNode, ChangeEvent } from 'react';
import * as S from './InputForm.styles';

type InputFormProps = {
    id?: string;
    label: ReactNode;
    name: string;
    placeholder: string;
    required?: boolean;
    value: string;
    error?: boolean;
    type: string;
    onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
};

export const InputFormContainer = ({
    id,
    label,
    name,
    placeholder,
    required,
    value,
    error,
    type,
    onChange,
    onBlur,
}: InputFormProps): ReactElement => {
    return (
        <S.Container>
            <S.Label>
                {required && <S.RedStar>* </S.RedStar>}
                {label}
            </S.Label>
            <S.Input
                placeholder={placeholder}
                id={id}
                name={name}
                required={required}
                value={value}
                error={!!error}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
            />
        </S.Container>
    );
};

export const InputForm = memo(InputFormContainer);
