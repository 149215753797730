export const StarEmpty = () => {
    return (
        <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.16005 9.97272C3.02296 9.97272 2.81732 9.90347 2.68023 9.83421C2.40605 9.62645 2.26895 9.34943 2.3375 9.00315L2.74878 6.44072L0.89803 4.64009C0.692391 4.36307 0.623846 4.08605 0.692392 3.73977C0.760938 3.3935 1.03512 3.18573 1.37785 3.18573L3.91406 2.83946L5.07935 0.484785C5.21644 0.207765 5.55917 0 5.83335 0C6.17609 0 6.45027 0.207765 6.58736 0.484785L7.75265 2.83946L10.2889 3.18573C10.6316 3.25499 10.8372 3.46275 10.9743 3.73977C11.0429 4.01679 10.9743 4.36307 10.7687 4.57083L8.91793 6.44072L9.32921 9.00315C9.39776 9.34943 9.26066 9.62645 8.98648 9.83421C8.71229 10.042 8.36956 10.042 8.09538 9.90347L5.76481 8.65688L3.43424 9.90347C3.43424 9.90347 3.29715 9.97272 3.16005 9.97272ZM5.83335 7.68731C5.97045 7.68731 6.10754 7.68731 6.24463 7.75656L8.36956 8.86464L7.95829 6.50997C7.88974 6.23295 8.02683 5.95593 8.16393 5.74817L9.87758 4.08605L7.54701 3.73977C7.27282 3.67052 7.06719 3.53201 6.93009 3.25499L5.9019 1.10808L4.80516 3.32424C4.66807 3.60126 4.46243 3.73977 4.18825 3.80903L1.85768 4.1553L3.57133 5.81742C3.77697 6.02519 3.84552 6.30221 3.77697 6.57923L3.36569 8.9339L5.49062 7.82582C5.55917 7.68731 5.69626 7.68731 5.83335 7.68731Z"
                fill="#F2B718"
            />
        </svg>
    );
};
