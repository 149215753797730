import URL from '@/common/app/utils/urlsUtils';

export const DESTINATIONS_LIST = [
    { name: 'Destin', href: '/attractions/Destin/', subtitle: 'FL' },
    { name: 'New Orleans', href: '/attractions/New-Orleans/', subtitle: 'LA' },
    { name: 'Gulf Shores', href: '/attractions/Gulf-Shores-Orange-Beach/', subtitle: 'AL' },
    { name: 'View All Destinations', href: '/destination/' },
];

export const SUPPORT_LIST = [
    { name: 'Get Help', href: '/customer-service-guide/' },
    { name: 'Privacy Policy', href: '/policy/', rel: 'nofollow' },
    { name: 'GDPR', href: '/policy#gdpr', rel: 'nofollow' },
    { name: 'Terms of Use', href: '/terms/', rel: 'nofollow' },
    { name: 'Fishing Guide', href: '/official-fishing-guide/' },
    { name: 'Site Map', href: '/site-navigation/' },
];

export const WORK_WITH_US_LIST = [
    { name: 'Tour Operators', href: `${URL.partners}/activity-operators/` },
    { name: 'Affiliates', href: `${URL.partners}/affiliates/` },
    { name: 'Partner Solutions', href: `${URL.partners}/partner-solutions/` },
    { name: 'Business Development', href: `${URL.partners}/connect-with-us/` },
];

export const COMPANY_LIST = [
    { name: 'About Us', href: `${URL.partners}/about-us/` },
    { name: 'Partners', href: '/partners/' },
    { name: 'Blog', href: '/blog/' },
    { name: 'Careers', href: `${URL.partners}/careers/` },
    { name: 'Traveler Stories', href: '/traveler-stories/' },
    { name: 'Gift Cards', href: '/TripShock-com-Gift-Card/details/1025/' },
    { name: 'Landmarks', href: '/landmarks/' },
];
