import { PropsWithChildren, ReactElement } from 'react';
import * as S from './TalkToUsMessage.styles';

type TalkToUsMessageProps = PropsWithChildren<{
    icon: 'unavailable' | 'confirm';
}>;

const TalkToUsMessage = ({ children, icon }: TalkToUsMessageProps): ReactElement => {
    return (
        <S.InfoMessage>
            <S.WrapperForIcon>
                {icon === 'unavailable' ? <S.WeAreClosedIcon /> : <S.Calling />}
            </S.WrapperForIcon>
            <S.ClosedCallingForm>{children}</S.ClosedCallingForm>
        </S.InfoMessage>
    );
};

export { TalkToUsMessage };
