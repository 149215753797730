import { ReactElement } from 'react';
import { TInputProps } from './Input.types';
import { InputWrapper, InputStyled } from './Input.styles';

export const Input = (props: TInputProps): ReactElement => {
    const { variant } = props;

    return (
        <InputWrapper data-testid="test-input" variant={variant} {...props}>
            <InputStyled {...props} />
        </InputWrapper>
    );
};

export default Input;
