export const StarHalf = () => {
    return (
        <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.24337 9.97272C3.10628 9.97272 2.90064 9.90347 2.76354 9.83421C2.48936 9.62645 2.35227 9.34943 2.42081 9.00315L2.83209 6.44072L0.981343 4.64009C0.775704 4.36307 0.707159 4.08605 0.775705 3.73977C0.844251 3.3935 1.11844 3.18573 1.46117 3.18573L3.99738 2.83946L5.16266 0.484785C5.29975 0.207765 5.64248 0 5.91667 0C6.2594 0 6.53358 0.207765 6.67068 0.484785L7.83596 2.83946L10.3722 3.18573C10.7149 3.25499 10.9205 3.46275 11.0576 3.73977C11.1262 4.01679 11.0576 4.36307 10.852 4.57083L9.00125 6.44072L9.41252 9.00315C9.48107 9.34943 9.34398 9.62645 9.06979 9.83421C8.79561 10.042 8.45288 10.042 8.17869 9.90347L5.84812 8.65688L3.51755 9.90347C3.51755 9.90347 3.38046 9.97272 3.24337 9.97272ZM5.91667 7.68731C6.05376 7.68731 6.19085 7.68731 6.32795 7.75656L8.45288 8.86464L8.0416 6.50997C7.97305 6.23295 8.11015 5.95593 8.24724 5.74817L9.96089 4.08605L7.63032 3.73977C7.35614 3.67052 7.1505 3.53201 7.01341 3.25499L5.98521 1.10808L4.88848 3.32424C4.75138 3.60126 4.54574 3.73977 4.27156 3.80903L1.94099 4.1553L3.65464 5.81742C3.86028 6.02519 3.92883 6.30221 3.86028 6.57923L3.44901 8.9339L5.57394 7.82582C5.64248 7.68731 5.77958 7.68731 5.91667 7.68731Z"
                fill="#F2B718"
            />
            <path
                d="M5.91667 0C5.57394 0 5.29975 0.207765 5.16266 0.484785L3.99738 2.83946L1.46117 3.18573C1.11844 3.25499 0.912797 3.46275 0.775705 3.73977C0.707159 4.01679 0.775704 4.36307 0.981343 4.57083L2.83209 6.44072L2.42081 9.00315C2.35227 9.34943 2.48936 9.62645 2.76354 9.83421C3.03773 10.042 3.38046 10.042 3.65464 9.90347L5.98521 8.65688V0H5.91667Z"
                fill="#F2B718"
            />
        </svg>
    );
};
