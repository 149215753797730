import Palette from '@/styles/Themes/Palette';
import Screen from '@/styles/Themes/Screen';
import styled, { DefaultTheme } from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';

export const ModalWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

export const ModalContent = styled.div`
    position: relative;
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_modal};
    width: 340px;
    padding: 24px 22px;

    ${Screen.desktop} {
        width: 550px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 25px;
    width: 100%;
`;

export const Title = styled.div`
    margin: 0;
    ${Text.Full.medium.medium};
    font-size: 12px;
    line-height: 18px;
    color: ${Palette.green.normal};
    display: flex;
    align-items: center;
`;

export const ModalBody = styled.div`
    color: ${cssVars.fg_gray1_white};
`;

export const ModalTime = styled.div`
    padding: 10px 0;
    color: ${cssVars.fg_gray1_white};
`;

export const ModalAuthor = styled.h3`
    color: ${cssVars.fg_gray1_white};
    margin: 17px 0 9px;
    ${Text.Mobile.medium.semibold};

    ${Screen.desktop} {
        ${Text.Desktop.medium.semibold};
    }
`;

interface ICloseIcon extends DefaultTheme {
    onClick: () => void;
}

export const CloseButtonWithIcon = styled(CloseIcon)``;

export const CloseButton = styled.div<ICloseIcon>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${cssVars.bg_ash_normal};
    border-radius: ${radius.huge};
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 40px;
        height: 40px;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;
