import { ReactElement } from 'react';
import * as S from './SearchOption.styles';

type SearchOptionProps = {
    label: string;
    counter?: string;
    selected?: boolean;
};

export const SearchOption = ({ label, counter, selected }: SearchOptionProps): ReactElement => {
    return (
        <S.Container>
            <S.Label selected={selected}>{label}</S.Label>
            <S.Counter selected={selected}>{counter ?? 0}</S.Counter>
        </S.Container>
    );
};
