import styled, { css, ThemedStyledProps } from 'styled-components';
import { TDayPickerProps } from './DayPicker.types';
import { TTheme } from '@/styles/Themes/Theme.domain';
import { radius } from '@/styles/Themes/Metrics';
import { fonts } from '@/styles/Themes/Fonts';
import { FlexPresets } from '@/styles/Themes/Presets';
import { cssVars } from '@/styles/Themes/cssVars';
import Text from 'src/styles/Themes/Text';
import Palette from '@/styles/Themes/Palette';
import Screen from '@/styles/Themes/Screen';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';

export const Frame = styled.div`
    width: 100%;
    min-height: 375px;
    ${Screen.desktop} {
        min-height: auto;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 15px;
    font: ${fonts.largeBold};
`;

export const MonthTitle = styled.div<ThemedStyledProps<TDayPickerProps, TTheme>>`
    ${Text.Desktop.large.semibold};
    color: ${cssVars.fg_gray2_white};
`;

export const Button = styled.button<ThemedStyledProps<TDayPickerProps, TTheme>>`
    cursor: pointer;
    border-radius: ${radius.large};
    background-color: ${cssVars.bg_white_blue};
    width: 36px;
    height: 36px;
    border: none;
    box-shadow: 0px 4px 23px ${cssVars.shadow_normal};
`;

export const Body = styled.div<ThemedStyledProps<TDayPickerProps, TTheme>>`
    width: 266px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    font-size: ${Text.Desktop.small.medium};
    color: ${(props) =>
        props.isLoad ? `${cssVars.fg_gray_light} !important` : `${cssVars.fg_gray2_white}`};
`;

export const Day = styled.div<ThemedStyledProps<TDayPickerProps, TTheme>>`
    ${FlexPresets.flexRowCenter};
    width: 42px;
    height: 36px;
    cursor: pointer;
    font-size: ${Text.Mobile.medium.regular};
    margin: 3px 0;

    &.hover {
        ${(props) =>
            !(props.isSelected && !props.daysBetween) &&
            css`
                border-radius: ${props.firstWeekDay
                    ? '35px 0 0 35px'
                    : props.lastWeekDay
                    ? '0 35px 35px 0'
                    : '0'};
                background-color: ${cssVars.bg_lightBlue_blue};
                color: ${cssVars.svg_gray2_gray3};
            `};
    }
    &.emptyDay {
        cursor: default;
        background-color: transparent;
    }
    &.notAvail {
        background-color: transparent !important;
        &:before {
            background-color: transparent;
        }
    }
    &.endHover,
    &.startHover {
        ${(props) =>
            !(props.isSelected && !props.daysBetween) &&
            css`
                position: relative;
                z-index: 1;
                background-color: ${cssVars.bg_lightBlue_blue};
                &:before {
                    border-radius: 35px;
                    z-index: -1;
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    shadow: ${cssVars.shadow_normal};
                }
            `};
    }
    &.endHover {
        ${(props) =>
            !(props.isSelected && !props.daysBetween) &&
            css`
                border-radius: ${props.daysBetween ? '0' : '0 35px 35px 0'};
                &:before {
                    background-color: ${Palette.gray.gray4};
                }
            `};
    }
    &.startSelected,
    &.endSelected {
        position: relative;
        z-index: 1;
        background-color: ${cssVars.bg_lightBlue_blue};
        &:before {
            border-radius: 35px;
            z-index: -1;
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: ${cssVars.shadow_normal};
            background-color: ${Palette.green.normal};
        }
    }
    &.startSelected {
        ${({ lastWeekDay }) =>
            !lastWeekDay &&
            css`
                border-radius: 35px 0 0 35px !important;
            `}
    }

    &.endSelected {
        ${({ firstWeekDay }) =>
            !firstWeekDay &&
            css`
                border-radius: 0 35px 35px 0 !important;
            `}
    }
    &.endSelected.hoverAfter {
        ${({ firstWeekDay }) =>
            !firstWeekDay &&
            css`
                border-radius: 0 !important;
            `}
    }

    &.startHover {
        ${(props) =>
            !(props.isSelected && !props.daysBetween) &&
            css`
                border-radius: ${props.daysBetween ? '0' : '35px 0 0 35px'};
                &:before {
                    background-color: ${Palette.gray.gray4};
                }
            `};
    }

    &.startHover.woCorners {
        ${(props) =>
            !(props.isSelected && !props.daysBetween) &&
            css`
                border-radius: ${props.daysBetween ? '0' : '35px'};
            `};
    }

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.3;
            cursor: not-allowed;
            background-color: ${cssVars.bg_white_dark3};
        `}

    ${(props) =>
        props.isToday &&
        css`
            color: ${Palette.green.normal};
        `}

    ${(props) =>
        props.isSelected &&
        css`
            width: 36px;
            margin: 3px;
            background-color: ${Palette.green.normal};
            color: ${Palette.white.normal};
            border-radius: ${radius.button};
        `}
    
    ${(props) =>
        props.daysBetween &&
        css`
            width: 42px;
            margin: 3px 0;
            background-color: ${cssVars.bg_lightBlue_blue} !important;
            border-radius: 0;
            color: ${cssVars.svg_gray2_gray3};
        `}

    ${(props) =>
        props.daysBetween &&
        props.lastWeekDay &&
        css`
            border-radius: 0 35px 35px 0;
            padding-right: 5px;
        `}
    
    ${(props) =>
        props.daysBetween &&
        props.firstWeekDay &&
        css`
            border-radius: 35px 0 0 35px;
            padding-left: 5px;
        `}

    
        width: 38px;
    height: 38px;
    margin: 3px 0;

    ${Screen.desktop} {
        width: 38px;
        height: 38px;
        margin: 3px 0;
        ${Text.Desktop.normal.regular};

        ${(props) =>
            props.isToday &&
            css`
                font: ${fonts.normalBold};
            `}
    }
`;

export const Sign = styled.div`
    ${Text.Desktop.medium.medium}
    color: ${cssVars.fg_gray2_white};
`;

export const Placeholder = styled(Sign)`
    display: none;

    @media (min-width: 668px) {
        display: flex;
    }
`;

export const Reset = styled(Sign)`
    display: flex;
    justify-content: center;

    &:hover {
        text-decoration: underline;
    }
`;

export const ButtonWrapper = styled.div<{ withIcon?: boolean }>`
    display: grid;
    grid-template: auto / 1fr 1fr 1fr;
    align-items: center;
    margin: 15px 0 10px;

    button {
        max-height: 36px;
        max-width: 140px;
        ${({ withIcon }) =>
            withIcon &&
            css`
                padding: 10px 10px 10px 0px;
            `}
    }
`;

export const TopDateLine = styled.div`
    display: flex;
    background-color: ${cssVars.bg_white_blue};
    position: absolute;
    top: -34px;
    left: 20px;
    min-width: 198px;
`;

export const TopSign = styled.div`
    ${Text.Desktop.large.medium}
    color: ${cssVars.fg_gray1_white};
`;

export const IconClose = styled(CloseIcon)`
    cursor: pointer;
    color: ${cssVars.fg_gray3_dark1};
    width: 8px;
    height: 8px;
`;

export const ButtonClose = styled.button`
    appearance: none;
    cursor: pointer;
    border: none;
    background-color: ${cssVars.bg_white_dark3};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 100%;
    position: absolute;
    right: 0;
    top: -6px;

    &:hover {
        border: none;
    }
`;
