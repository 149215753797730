import { ReactElement } from 'react';
import { DestinationWrapper } from './Skeleton.styles';
import { TSkeletonStyled } from './Skeleton.types';

const DestinationSkeleton = ({ isLoading, styles, ...props }: TSkeletonStyled): ReactElement => {
    return (
        <>
            {isLoading && (
                <DestinationWrapper styles={styles} {...props} data-testid="destSkeleton" />
            )}
        </>
    );
};

export default DestinationSkeleton;
