import { IInputSearchResultProps } from '@/shared/SearchBar/types/index.types';
import { SearchOption } from '@/shared/SearchBar/ui/SearchOption/SearchOption';
import { memo, ReactElement, useRef, useEffect } from 'react';
import * as S from './InputSearch.styles';

const InputSearchResultComponent = ({
    value,
    filterResults,
    footer,
    chooseResult,
}: IInputSearchResultProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value) {
            const el = ref.current;
            const choosenEl = el?.querySelector('.selected');
            const choosenElPosition = (choosenEl as HTMLDivElement)?.offsetTop;

            if (choosenElPosition && el) {
                const scrollDistance = Math.max(0, choosenElPosition - 100);
                el.scrollTo({
                    top: scrollDistance,
                });
            }
        }
    }, [value]);

    return (
        <>
            <S.ScrollResultContainer
                footer={footer}
                ref={ref}
                className="scrollResult"
                onScroll={(e) => {
                    e.stopPropagation();
                }}
                onTouchMove={(e) => {
                    e.stopPropagation();
                }}
            >
                {filterResults.map((result) => (
                    <S.ResultsItem
                        key={result.value}
                        onClick={() => chooseResult(result)}
                        className={result.isSelected ? 'selected' : undefined}
                    >
                        <SearchOption
                            label={result.label}
                            counter={result.counter}
                            selected={result.isSelected}
                        />
                    </S.ResultsItem>
                ))}
            </S.ScrollResultContainer>
            {footer && <S.ResultsFooter>{footer}</S.ResultsFooter>}
        </>
    );
};

export const InputSearchResult = memo(InputSearchResultComponent);
