import styled, { DefaultTheme, css } from 'styled-components';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';

interface ICircleDots extends DefaultTheme {
    isActive?: boolean;
    size?: number;
    desktop?: boolean;
}

export const SliderDots = styled.div<ICircleDots>`
    display: flex;
    justify-content: center;
    border-radius: 100%;
    width: ${({ size }) => (size ? `${size}px` : '6px')};
    height: ${({ size }) => (size ? `${size}px` : '6px')};
    margin: 20px 5px;
    ${({ isActive, size }) =>
        isActive
            ? css`
                  background-color: ${cssVars.bg_gray3_green};
                  ${size &&
                  `
            width: ${~~(size * 1.5)}px;
            height: ${~~(size * 1.5)}px;
        `}
              `
            : css`
                  background-color: ${cssVars.bg_gray4_dark1};
              `}

    ${Screen.desktop} {
        display: ${({ desktop }) => (desktop ? 'flex' : 'none')};
    }
`;
