import { ReactElement, useCallback } from 'react';
import { TDarkArrowsProps } from './DarkArrows.domain';
import * as S from './DarkArrows.styles';

export const DarkArrows = ({
    onLeft,
    onRight,
    leftOff,
    rightOff,
}: TDarkArrowsProps): ReactElement => {
    const left = useCallback(() => {
        if (!leftOff) {
            onLeft();
        }
    }, [leftOff, onLeft]);

    const right = useCallback(() => {
        if (!rightOff) {
            onRight();
        }
    }, [rightOff, onRight]);

    return (
        <S.Container>
            <div onClick={left}>
                <S.Left disabled={leftOff} />
            </div>
            <div onClick={right}>
                <S.Right disabled={rightOff} />
            </div>
        </S.Container>
    );
};

export default DarkArrows;
