import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    gap: 24px;
    padding: 24px;
    ${Screen.ipad} {
        padding: 32px;
    }
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 24px 24px;

    ${Screen.ipad} {
        padding: 0 32px 32px;
    }
`;

export const FooterButton = styled.div`
    width: 100%;
    height: 50px;
    button: {
        align-items: center;
    }
    ${Screen.desktop} {
        width: 50%;
    }
`;

export const ButtonText = styled.div<{ isLoading: boolean }>`
    margin: ${({ isLoading }) => (isLoading ? '0 15px' : '0')};
`;

export const PhoneWrapper = styled.div`
    position: relative;
    width: 100%;
`;
