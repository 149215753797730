import { ReactElement } from 'react';
import { TSingleArrow } from './LightArrows.domain';
import * as S from './LightArrows.styles';

export const LeftArrow = ({ onClick, disabled, className }: TSingleArrow): ReactElement => {
    return (
        <div onClick={() => !disabled && onClick()} className={`LeftArrow ${className}`}>
            <S.Left disabled={disabled} />
        </div>
    );
};

export default LeftArrow;
