export const Refresh = () => {
    return (
        <div className="Refresh">
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.7042 2.29158C10.4958 1.08325 8.83749 0.333252 6.99583 0.333252C3.31249 0.333252 0.337494 3.31658 0.337494 6.99992C0.337494 10.6833 3.31249 13.6666 6.99583 13.6666C10.1042 13.6666 12.6958 11.5416 13.4375 8.66658H11.7042C11.0208 10.6083 9.17083 11.9999 6.99583 11.9999C4.23749 11.9999 1.99583 9.75825 1.99583 6.99992C1.99583 4.24159 4.23749 1.99992 6.99583 1.99992C8.37916 1.99992 9.61249 2.57492 10.5125 3.48325L7.82916 6.16658H13.6625V0.333252L11.7042 2.29158Z"
                    fill="black"
                    fillOpacity="0.54"
                />
            </svg>
        </div>
    );
};
