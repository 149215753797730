export const ArrowLeftCard = (props: { className?: string }) => {
    return (
        <svg
            className={`ArrowRight ${props.className}`}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                opacity="0.7"
                x="2.5116"
                y="2.51172"
                width="30.1395"
                height="30.1395"
                rx="15.0698"
                fill="var(--bg-ash-darker)"
            />
            <path
                d="M13.8139 17.3511C13.8139 17.1837 13.8977 17.0162 13.9814 16.9325L18.1674 12.7465C18.4186 12.4953 18.8372 12.4953 19.0884 12.7465C19.3395 12.9976 19.3395 13.4162 19.0884 13.6674L15.3209 17.4349L19.0884 21.2023C19.3395 21.4535 19.3395 21.8721 19.0884 22.1232C18.8372 22.3744 18.4186 22.3744 18.1674 22.1232L13.9814 17.9372C13.8977 17.6023 13.8139 17.4348 13.8139 17.3511Z"
                fill="var(--fg-gray1-white)"
            />
        </svg>
    );
};
