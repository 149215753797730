import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';

export const Map = styled.iframe`
    width: 308px;
    height: 300px;

    ${Screen.ipad} {
        width: 650px;
        height: 325px;
    }

    ${Screen.desktop} {
        width: 1000px;
        height: 415px;
    }
`;

export const Address = styled.div`
    margin: 9px 0 0;

    ${Screen.ipad} {
        margin: 9px 0;
    }
`;
