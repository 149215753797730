export const ArrowRight = (props: { className?: string }) => {
    return (
        <svg
            className={`ArrowRight ${props.className}`}
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.53857 4.41247L5.34312 1.25997C5.24914 1.16725 5.0925 1.16725 4.99851 1.25997C4.90453 1.35269 4.90453 1.50722 4.99851 1.59995L7.7867 4.35065H2.11635C1.99103 4.35065 1.86572 4.44337 1.86572 4.59791C1.86572 4.75244 1.95971 4.84516 2.11635 4.84516H7.81803L5.0925 7.53405C4.99851 7.62677 4.99851 7.78131 5.0925 7.87403C5.12383 7.90494 5.18648 7.93584 5.24914 7.93584C5.31179 7.93584 5.37445 7.90494 5.40578 7.87403L8.53857 4.78335C8.63255 4.62881 8.63255 4.47428 8.53857 4.41247Z"
                fill="white"
            />
        </svg>
    );
};
