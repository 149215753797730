import styled, { ThemeProps, ThemedStyledProps } from 'styled-components';
import { TTheme } from '@/styles/Themes/Theme.domain';
import { TStyleOverlay } from './Modal.types';
import { radius } from '@/styles/Themes/Metrics';
import { fonts } from '@/styles/Themes/Fonts';
import { FlexPresets } from '@/styles/Themes/Presets';
import Palette from '@/styles/Themes/Palette';

export const Overlay = styled.div<ThemedStyledProps<TStyleOverlay, TTheme>>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    opacity: 0.3;
    display: ${({ open }) => (open ? 'block' : 'none')};
    background: ${Palette.black};
`;

export const Modal = styled.div<ThemeProps<TTheme>>`
    background: ${Palette.white.normal};
    box-shadow: 0px 10.89px 43.36px rgba(17, 15, 15, 0.53);
    border-radius: ${radius.small};

    padding: 22px;

    max-width: 550px;
    min-width: 340px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ModalTop = styled.div<ThemeProps<TTheme>>`
    ${FlexPresets.flexColBetween}
    padding: 8px;
`;

export const ModalTitle = styled.h4<ThemeProps<TTheme>>`
    font: ${fonts.normal};
    color: ${Palette.green.normal};
    margin: 0;
    padding: 0;
`;

export const CloseButton = styled.div<ThemeProps<TTheme>>`
    ${FlexPresets.flexRowCenter}
    background-color: ${Palette.ash.normal};
    width: 43px;
    height: 43px;
    border-radius: 50%;
    cursor: pointer;
`;
