import styled from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';

export const Lable = styled.label`
    display: flex;
    cursor: pointer;
    width: auto;
`;

export const Input = styled.input`
    appearance: none;
    background-color: transparent;
    margin: 0 10px 0 0;

    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 2px solid ${cssVars.fg_gray2_gray3};
    border-radius: ${radius.large};
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: ${radius.large};
        transform: scale(0);
        transition: 0.3s transform ease-in-out;
        background-color: ${Palette.green.normal};
    }

    &:checked::before {
        transform: scale(1);
    }

    &:checked {
        border: 2px solid ${Palette.green.normal};
    }
`;
