import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';

type TSelected = {
    selected?: boolean;
};

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${cssVars.fg_gray2_white};
    ${Text.Mobile.large.medium};
    width: 100%;
    padding: 0 15px;
`;

export const Label = styled.label<TSelected>`
    flex: 1;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) => (props.selected ? Palette.green.normal : cssVars.fg_gray2_white)};
`;

export const Counter = styled.span<TSelected>`
    color: ${(props) => (props.selected ? cssVars.fg_green_greenLight : Palette.gray.gray3)};

    &:hover {
        color: ${(props) => (props.selected ? Palette.green.dark : Palette.green.normal)};
    }
`;
