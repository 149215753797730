import styled from 'styled-components';
import Screen from 'src/styles/Themes/Screen';

export const Container = styled.div`
    width: 100%;
    max-width: 1124px;
    margin: 0 auto;
    padding: 0 16px;

    ${Screen.ipad} {
        padding: 0 29px;
    }
    ${Screen.desktop} {
        padding: 0 30px;
    }
`;
