export const HelpSign = (props: { className?: string }) => {
    return (
        <svg
            className={`HelpSign ${props.className}`}
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.117188"
                y="0.816406"
                width="40"
                height="40"
                rx="20"
                fill="var(--bg-ash-dark3)"
            />
            <path
                d="M19.652 11.9792C15.0008 11.9792 11.2799 15.7002 11.2799 20.3513C11.2799 25.0025 15.0008 28.7234 19.652 28.7234C24.3032 28.7234 28.0241 25.0025 28.0241 20.3513C28.0241 15.7002 24.2256 11.9792 19.652 11.9792ZM19.652 27.4831C15.6985 27.4831 12.4427 24.3048 12.4427 20.2738C12.4427 16.2428 15.621 13.0645 19.652 13.0645C23.683 13.0645 26.7838 16.3203 26.7838 20.3513C26.7838 24.3823 23.6055 27.4831 19.652 27.4831Z"
                fill="#0DD1C5"
            />
            <path
                d="M19.652 24.5374C19.3419 24.5374 19.1094 24.7699 19.1094 25.08C19.1094 25.3901 19.3419 25.6226 19.652 25.6226C19.9621 25.6226 20.1946 25.3901 20.1946 25.08C20.1171 24.7699 19.8846 24.5374 19.652 24.5374Z"
                fill="#0DD1C5"
            />
            <path
                d="M21.8226 16.1652C21.2024 15.6226 20.3497 15.3125 19.497 15.3125C18.6443 15.3125 17.7916 15.7001 17.249 16.3203C16.6288 16.9404 16.3187 17.7931 16.3187 18.6458C16.3187 18.9559 16.5513 19.266 16.9389 19.1885C17.249 19.1885 17.4815 18.8784 17.4815 18.5683C17.4815 18.0257 17.7141 17.483 18.1017 17.0954C18.4893 16.7078 19.0319 16.4753 19.5745 16.4753C20.1172 16.4753 20.6598 16.6303 21.0474 17.0179C21.435 17.4055 21.7451 17.8706 21.7451 18.4908C21.8226 19.421 21.3575 20.1187 20.5048 20.5838C19.497 21.0489 19.0319 21.9016 19.0319 22.9869V23.452C19.0319 23.7621 19.2645 24.0722 19.6521 24.0722C20.0397 24.0722 20.2722 23.8396 20.2722 23.452V22.9869C20.2722 22.3668 20.5048 21.9792 21.1249 21.6691C22.3652 21.0489 23.0629 19.8861 22.9854 18.4908C22.8304 17.483 22.4428 16.7078 21.8226 16.1652Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
