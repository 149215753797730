import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';
import styled from 'styled-components';
import { radius } from '@/styles/Themes/Metrics';
import Text from '@/styles/Themes/Text';
import { Palette } from '@/styles/Themes/Palette';

export const ReviewCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 20px 23px;
    margin: 10px 0;
    width: 100%;
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_default};
    background: ${cssVars.bg_white_dark3};

    ${Screen.ipad} {
        margin: 0;
        width: calc(50% - 12px);
    }

    ${Screen.desktop} {
        padding: 30px 23px;
        width: calc(50% - 12px);
    }
`;

export const ContentContainer = styled.div`
    margin: 0 0 30px;

    ${Screen.ipad} {
        margin: 0 0 40px;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    ${Text.Full.large.medium};
    color: ${Palette.green.normal};
    margin-bottom: 3px;
`;

export const Description = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.medium.regular};
`;

export const RatingContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin: 6px 0;
`;

export const Rate = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.medium.regular};
`;

export const RightPart = styled.div`
    display: flex;
    align-self: center;
    height: 30px;
    ${Text.Full.medium.medium};

    ${Screen.desktop} {
        ${Text.Desktop.medium.semibold};
    }
`;

export const Author = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.medium.regular};
`;

export const Time = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.medium.regular};
`;

export const BottomWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
