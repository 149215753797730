import { CartIconHeader } from '@/shared/images/icons/CartIconHeader';
import { GripLine } from '@/shared/images/icons/GripLine';
import { HeartIconWhite } from '@/shared/images/icons/HeartIconWhite';
import { TalkToUsIcon } from '@/shared/images/icons/TalkToUsIcon';
import { SearchIcon } from '@/shared/images/icons/SearchIcon';
import Logotype from '@/shared/images/Logo/Logotype';
import { cssVars } from '@/styles/Themes/cssVars';
import { Transition } from '@/styles/Themes/KeyFrames';
import Palette from '@/styles/Themes/Palette';
import Screen from '@/styles/Themes/Screen';
import styled, { css } from 'styled-components';
import { radius } from '@/styles/Themes/Metrics';
import { FlexPresets } from '@/styles/Themes/Presets';
import Text from '@/styles/Themes/Text';
import { WrapperWithTransparent, HeaderWithTransparent, HeaderExpanded } from './Header.types';
import { CloseIconPromo } from '@/shared/images/icons/CloseIconPromo';

const getHeaderHeight = (isExpanded?: boolean, isRecents?: boolean) => {
    if (isExpanded) {
        return isRecents ? '274px' : '195px';
    }
    return '65px';
};

export const PromoBlock = styled.a`
    position: relative;
    text-decoration: none;
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 66px;
    background: url('/promo.svg') no-repeat right bottom,
        linear-gradient(90deg, rgb(3, 217, 177) 0%, rgb(28, 187, 217) 100%);
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 16px 4px 10px;

    ${Screen.ipad} {
        padding: 0 70px 0 30px;
        height: 60px;
    }

    ${Screen.desktop} {
        font-size: 18px;
        padding: 0 30px;
        height: 48px;
    }
`;

export const PromoCloseWrapper = styled.div`
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 4px;
    width: 32px;
    height: 32px;

    ${Screen.ipad} {
        position: absolute;
        right: 8px;
        top: 4px;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
    }
`;

export const PromoCloseIcon = styled(CloseIconPromo)`
    color: #fff;
    width: 16px;
    height: 16px;
`;

export const TopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
`;

export const Wrapper = styled.div<WrapperWithTransparent>`
    background-color: ${cssVars.bg_blue_dark3};
    height: 50px;
    box-shadow: 0px 4px 23px ${cssVars.shadow_normal};

    ${Screen.ipad} {
        height: 65px;
    }

    ${Screen.desktop} {
        border-radius: 0px 0px 15px 15px;
        box-shadow: none;
        backdrop-filter: blur(10px);
        height: ${({ isExpanded, isRecents }) => getHeaderHeight(isExpanded, isRecents)};
        transition: height ease 0.4s;
        padding-bottom: 40px;

        ${({ transparent, scrolled }) =>
            transparent &&
            !scrolled &&
            css`
                border-radius: 0;
                background-color: rgba(255, 255, 255, 0.19);
            `}
        box-shadow: ${({ scrolled }) => (scrolled ? '0px 1px 7px #110f0f' : 'none')}

        ${({ transparent }) =>
            !transparent &&
            css`
                border-radius: 0;
                box-shadow: 0px 4px 23px ${cssVars.shadow_normal};
            `}
    }
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const PartnerButtonWrapper = styled.div`
    display: none;
    align-items: center;
    padding: 0 24px;
    height: 46px;

    @media (min-width: 760px) {
        display: flex;
    }

    ${Screen.desktop} {
        border-right: 0.7px solid ${cssVars.bg_gray4_dark2};
        margin-left: -24px;
    }
`;

export const PartnerButton = styled.a`
    color: #fff;
    background: ${Palette.gradient.dark};
    border-radius: ${radius.large};
    height: 30px;
    width: 148px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;

    ${Screen.desktop} {
        height: 35px;
    }
`;

export const HeaderLogo = styled(Logotype)`
    display: block;
    cursor: pointer;

    ${Screen.ipad} {
        width: 157px;
        height: auto;
    }
    ${Screen.desktop} {
        margin-left: 40px;
    }
`;

export const SideBarMenu = styled(GripLine)`
    *:first-child {
        fill: ${Palette.white.normal};
    }
    *:last-child {
        fill: ${Palette.white.normal};
    }

    ${Screen.ipad} {
        margin: 18px 36px 17px 0;
    }

    ${Screen.desktop} {
        height: 36px;
        width: 36px;
        margin: 0 36px 14px 0;
    }
    &:hover {
        ${Transition.normal}

        *:first-child {
            fill: ${Palette.green.normal};
        }
        *:last-child {
            fill: ${Palette.green.normal};
        }
    }
`;

export const Line = styled.div`
    display: none;
    ${Screen.desktop} {
        display: block;
        background-color: ${Palette.gray.gray4};
        width: 1px;
        height: 46px;
        margin: 9px 33px 10px 0;
    }
`;

export const SearchBtn = styled.button`
    display: flex;
    appearance: none;
    border: none;
    padding: 0;
    background: transparent;
    color: ${Palette.white.normal};
`;

export const IconSearch = styled(SearchIcon)`
    margin: 16px 10px;
    cursor: pointer;
    height: 18px;
    width: 18px;

    ${Screen.ipad} {
        height: 20px;
        width: 21px;
        margin: 22px 14px 23px;
    }

    ${Screen.desktop} {
        height: 20px;
        width: 21px;
        margin: 22px 14px 23px;
    }

    &:hover {
        ${Transition.normal}

        *:first-child {
            fill: ${Palette.green.normal};
        }
    }
`;

export const HeartBtn = styled(HeartIconWhite)`
    display: block;
    margin: 0 10px;
    cursor: pointer;
    ${Screen.ipad} {
        height: 20px;
        width: 21px;
        margin: 22px 14px 23px;
    }
    ${Screen.desktop} {
        margin: 0 0 0 32px;
    }
    &:hover {
        ${Transition.normal}

        *:first-child {
            fill: ${Palette.green.normal};
        }
    }
`;

export const CartBtn = styled(CartIconHeader)`
    display: block;
    margin-left: 8px;
    cursor: pointer;

    ${Screen.ipad} {
        height: 20px;
        width: 23px;
        margin: 22px 0 23px 13px;
    }
    ${Screen.desktop} {
        margin: 0 0 0 32px;
    }
    &:hover {
        ${Transition.normal}

        *:first-child {
            fill: ${Palette.green.normal};
        }
    }
`;

export const LeftSection = styled.div`
    display: flex;
    justify-content: flex-start;
`;
export const RightSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const SectionIcons = styled.div<HeaderExpanded>`
    display: flex;
    align-items: center;

    ${Screen.desktop} {
        display: ${({ isExpanded }) => (isExpanded ? 'none' : 'flex')};
        height: 46px;
    }
`;

export const LetsFind = styled.div`
    display: flex;
    color: ${Palette.white.normal};
    ${Text.Desktop.heading4};
    align-items: center;
`;

export const SectionSearch = styled.div<{
    isExpanded?: boolean;
    transparent?: boolean;
    scrolled?: boolean;
}>`
    display: ${({ scrolled, transparent }) => (!scrolled && transparent ? 'none' : 'block')};
    margin-top: ${({ isExpanded }) => (isExpanded ? '65px' : '0')};

    ${Screen.desktop} {
        margin: 0 32px 0 0;
    }
`;

export const ButtonMenu = styled.div<HeaderWithTransparent>`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 23px rgba(16, 31, 46, 0.53);
    height: 30px;
    width: 30px;
    border-radius: 12px;
    margin: 12px 17px 0 0;

    ${Screen.ipad} {
        margin: 3px 17px 0 0;
        display: block;
    }
    ${Screen.desktop} {
        height: 36px;
        width: 36px;
        margin: 15px 0 0;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: none;

        &:hover {
            background-color: rgba(13, 209, 197, 0.2);
            color: ${Palette.green.normal};
            cursor: pointer;
        }

        ${({ scrolled, transparent }) =>
            (scrolled || !transparent) &&
            css`
                background-color: transparent;
                box-shadow: 0px 3px 18px rgba(16, 31, 46, 0.53);
                &:hover {
                    background-color: transparent;
                    color: ${Palette.green.normal};
                }
            `}
    }
`;

export const CartHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    width: 39px;

    ${Screen.ipad} {
        width: 49px;
        height: 65px;
    }
    ${Screen.desktop} {
        width: 55px;
    }
`;

export const CartItems = styled.div`
    ${FlexPresets.flexRowCenter};
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 1;
    background-color: ${Palette.green.normal};
    color: ${Palette.white.normal};
    height: 18px;
    min-width: 18px;
    max-width: 25px;
    border-radius: ${radius.huge};
    ${Text.Full.normal.medium};
    cursor: pointer;

    ${Screen.ipad} {
        top: 14px;
        right: 6px;
    }
    ${Screen.desktop} {
        top: 14px;
        right: -8px;
    }
`;

export const PhoneIconWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 4px;
    ${Screen.ipad} {
        gap: 24px;
    }
`;

export const TalkToUsBtnWrapper = styled.div<{ isItWorkingTime: boolean }>`
    display: flex;
    flex: none;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${(props) =>
        props.isItWorkingTime ? `${Palette.green.normal}` : `${cssVars.button_disabled}`};
    border-radius: ${radius.button};
    color: ${Palette.white.normal};
    & span {
        display: none;
    }
    order: 1;

    ${Screen.ipad} {
        width: 150px;
        & span {
            display: block;
            ${Text.Desktop.large.medium}
        }
    }
`;

export const TalkToUsBtn = styled(TalkToUsIcon)`
    display: block;
    cursor: pointer;

    ${Screen.ipad} {
        height: 15px;
        width: 15px;
        margin-right: 5px;
    }
`;

export const PhoneAvailText = styled.span<{ isItWorkingTime: boolean }>`
    color: ${(props) =>
        props.isItWorkingTime ? `${Palette.green.normal}` : `${Palette.gray.light}`};

    ${Text.Mobile.normal.medium}
    line-height: 12px;
    width: 60px;

    ${Screen.ipad} {
        ${Text.Desktop.medium.medium};
        width: 150px;
    }
`;
