import { ReactElement, memo, ReactNode } from 'react';
import * as S from './RadioButton.styles';

type RadioButtonProps = {
    children: ReactNode;
    checked?: boolean;
    name: string;
    onClick: () => void;
};

export const RadioButton = ({
    children,
    checked,
    name,
    onClick,
}: RadioButtonProps): ReactElement => {
    return (
        <S.Lable onClick={onClick}>
            <S.Input type="radio" name={name} checked={checked} readOnly></S.Input>
            {children}
        </S.Lable>
    );
};

export default memo(RadioButton);
