import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { getScrollbarWidth } from 'utils/browser';
import { IPortalProps } from './Portal.types';

export const Portal = ({ children }: IPortalProps) => {
    const elRef = useRef<HTMLDivElement | null>(null);

    if (!elRef.current) elRef.current = document.createElement('div');

    useEffect(() => {
        const scrollBarWidth = getScrollbarWidth();
        const el = elRef.current;

        if (el) {
            el.style.cssText += `background-color:rgba(0, 0, 0, 0.3);
                position:fixed;
                inset:0;
                z-index:1000;
                animation-name:fadeIn;
                animation-duration:0.4s;
                animation-timing-function:ease;
                transition: all 0.4s ease-out;
                top:0;
                bottom:0;
                left:0;
                right:0;`;
            document.body.appendChild(el);
            document.documentElement.style.overflow = 'hidden';
            document.body.style.marginRight = `${scrollBarWidth}px`;
            const header = document.getElementById('header');
            if (header) {
                header.style.right = `${scrollBarWidth}px`;
            }
        }

        return () => {
            if (el) {
                document.body.removeChild(el);
                document.documentElement.style.overflow = 'visible';
                document.body.style.marginRight = '0';
                const header = document.getElementById('header');
                if (header) {
                    header.style.right = `0`;
                }
            }
        };
    }, []);

    return createPortal(children, elRef.current);
};
