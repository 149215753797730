import { TMetrics } from './Theme.domain';

const FontFamily = 'Montserrat, Helvetica, Verdana, Arial, sans-serif';

export type TFontWeight = {
    thin: string;
    light: string;
    regular: string;
    medium: string;
    semiBold: string;
    bold: string;
    extraBold: string;
    black: string;
};

export const fontSize: TMetrics = {
    none: 'inherit',
    tiny: '0.75rem',
    small: '0.875rem',
    medium: '1rem',
    normal: '1rem',
    large: '1.125rem',
    xlarge: '1.25rem',
    xxlarge: '1.5rem',
    huge: '2rem',
    giant: '2.5rem',
};

export const fontWeight: TFontWeight = {
    thin: '100',
    light: '300',
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
    extraBold: '800',
    black: '900',
};

//font: font-style font-variant font-weight font-size/line-height font-family|caption|icon|menu|message-box|small-caption|status-bar|initial|inherit;
export const fonts = {
    Input: `${fontWeight.regular} ${fontSize.normal} ${FontFamily}`,
    ChipsTitles: `${fontWeight.regular} ${fontSize.normal} ${FontFamily}`,
    normal: `${fontWeight.regular} ${fontSize.normal}/22px ${FontFamily}`,
    normalBold: `${fontWeight.bold} ${fontSize.normal}/22px ${FontFamily}`,
    largeBold: `${fontWeight.bold} ${fontSize.large}/22px ${FontFamily}`,
    xxLargeBold: `${fontWeight.bold} ${fontSize.xxlarge}/22px ${FontFamily}`,
};
