export const GripLine = (props: { className?: string }) => {
    return (
        <svg
            className={`GripLine ${props.className}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.3874 10.5H6.61257C6.27225 10.5 6 10.1667 6 9.75C6 9.33333 6.27225 9 6.61257 9H18.3874C18.7277 9 19 9.33333 19 9.75C19 10.1667 18.7277 10.5 18.3874 10.5Z"
                fill="white"
            />
            <path
                d="M14.391 15.1667H6.60902C6.27068 15.1667 6 14.8334 6 14.4167C6 14.0001 6.27068 13.6667 6.60902 13.6667H14.391C14.7293 13.6667 15 14.0001 15 14.4167C15 14.8334 14.7293 15.1667 14.391 15.1667Z"
                fill="white"
            />
        </svg>
    );
};
