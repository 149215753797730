import { borders, radius } from '@/styles/Themes/Metrics';
import styled, { keyframes, ThemedStyledProps } from 'styled-components';
import { TTheme } from 'src/styles/Themes/Theme.domain';
import { TLoaderProps } from './Loader.types';
import Palette from '@/styles/Themes/Palette';

const loadingAnimation = ({ ...props }) => keyframes`
    0%  { transform: ${props.theme.keyframes.bgRotation0};   
    transform: ${props.theme.keyframes.bgRotation0}; }
    100% { transform: ${props.theme.keyframes.bgRotation100}; 
    transform: ${props.theme.keyframes.bgRotation100}; }
`;

export const LoadingIcon = styled.div<ThemedStyledProps<TLoaderProps, TTheme>>`
    ${({ theme }) => theme.presets.loader};
    border: ${`${borders.xlarge} transparent`};
    border-top-color: ${Palette.gray.gray3};
    border-left-color: ${Palette.gray.gray3};
    border-radius: ${radius.button};
`;

export const LoadingBar = styled.div<ThemedStyledProps<TLoaderProps, TTheme>>`
    ${LoadingIcon}
    left: 50%;
    position: absolute;
    z-index: 19;
    animation: ${({ theme }) => theme.animation.animationLinear4};
    animation-name: ${loadingAnimation};
`;
