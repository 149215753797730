export const ModeSun = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.16561 10.2883C5.37602 10.2883 4.60416 10.0541 3.94763 9.61543C3.2911 9.17676 2.7794 8.55325 2.47724 7.82376C2.17507 7.09427 2.09601 6.29155 2.25005 5.51713C2.40409 4.7427 2.78432 4.03135 3.34265 3.47302C3.90098 2.91469 4.61233 2.53447 5.38676 2.38042C6.16118 2.22638 6.9639 2.30544 7.69339 2.60761C8.42288 2.90977 9.04639 3.42147 9.48507 4.078C9.92374 4.73452 10.1579 5.50639 10.1579 6.29598C10.1569 7.35451 9.73601 8.3694 8.98752 9.11789C8.23903 9.86638 7.22414 10.2873 6.16561 10.2883ZM6.16561 3.02516C5.51828 3.02516 4.88547 3.21715 4.34726 3.57683C3.80904 3.93651 3.38958 4.44772 3.14194 5.04582C2.89429 5.64392 2.82959 6.30203 2.95601 6.9369C3.08243 7.57177 3.3943 8.1549 3.85216 8.61251C4.31002 9.07012 4.89332 9.38167 5.52826 9.50774C6.1632 9.63381 6.82127 9.56874 7.41924 9.32077C8.0172 9.0728 8.52819 8.65306 8.88757 8.11464C9.24696 7.57623 9.43859 6.94332 9.43823 6.29598C9.43681 5.42864 9.0915 4.59725 8.47802 3.98411C7.86454 3.37097 7.03296 3.02612 6.16561 3.02516Z"
                fill="#202020"
            />
            <path
                d="M6.16564 1.04619C6.38524 1.04619 6.56325 0.868177 6.56325 0.648584C6.56325 0.428991 6.38524 0.250977 6.16564 0.250977C5.94605 0.250977 5.76804 0.428991 5.76804 0.648584C5.76804 0.868177 5.94605 1.04619 6.16564 1.04619Z"
                fill="#202020"
            />
            <path
                d="M6.16744 1.13804H6.14765C6.01293 1.13567 5.88461 1.08018 5.79061 0.983659C5.6966 0.887138 5.64452 0.757394 5.64571 0.622665C5.6469 0.487936 5.70125 0.35913 5.79694 0.264279C5.89263 0.169428 6.02191 0.116211 6.15664 0.116211C6.29138 0.116211 6.42066 0.169428 6.51635 0.264279C6.61204 0.35913 6.66639 0.487936 6.66758 0.622665C6.66876 0.757394 6.61669 0.887138 6.52268 0.983659C6.42868 1.08018 6.30035 1.13567 6.16564 1.13804H6.16744ZM6.16744 0.341023C6.10738 0.341397 6.04873 0.359239 5.99864 0.392373C5.94855 0.425506 5.90919 0.472499 5.88534 0.52762C5.8615 0.582742 5.85422 0.64361 5.86438 0.702802C5.87453 0.761994 5.90169 0.816951 5.94254 0.860971C5.99902 0.920369 6.07653 0.95525 6.15844 0.958124C6.23957 0.960029 6.31826 0.930357 6.37794 0.875365C6.42507 0.834356 6.4586 0.77997 6.47406 0.71944C6.48953 0.658909 6.48621 0.595104 6.46453 0.536511C6.44285 0.477918 6.40384 0.427313 6.3527 0.391428C6.30156 0.355544 6.24071 0.33608 6.17824 0.335625L6.16744 0.341023Z"
                fill="#202020"
            />
            <path
                d="M10.1417 2.7083C10.3613 2.7083 10.5393 2.53029 10.5393 2.31069C10.5393 2.0911 10.3613 1.91309 10.1417 1.91309C9.9221 1.91309 9.74408 2.0911 9.74408 2.31069C9.74408 2.53029 9.9221 2.7083 10.1417 2.7083Z"
                fill="#202020"
            />
            <path
                d="M10.1417 2.80027C10.0742 2.80075 10.0072 2.78707 9.94525 2.76011C9.88328 2.73315 9.82764 2.69351 9.78191 2.64374C9.69395 2.54919 9.64676 2.4238 9.65057 2.29471C9.65265 2.23063 9.66739 2.16759 9.69394 2.10923C9.7205 2.05086 9.75834 1.99834 9.8053 1.95468C9.90008 1.86631 10.0261 1.81914 10.1556 1.82352C10.2851 1.82791 10.4076 1.88349 10.4962 1.97807C10.5618 2.04765 10.6056 2.13501 10.622 2.22928C10.6383 2.32355 10.6266 2.42055 10.5883 2.50821C10.5499 2.59586 10.4866 2.67029 10.4062 2.72223C10.3259 2.77416 10.232 2.8013 10.1363 2.80027H10.1417ZM10.1417 2.00325C10.0621 2.00365 9.9857 2.03473 9.9284 2.09001C9.87111 2.1453 9.83732 2.22055 9.83408 2.30011C9.83151 2.38188 9.86125 2.46138 9.91685 2.5214C9.94356 2.55441 9.97677 2.58157 10.0144 2.60118C10.0521 2.6208 10.0934 2.63246 10.1357 2.63543C10.1781 2.6384 10.2206 2.63262 10.2606 2.61846C10.3007 2.60429 10.3374 2.58204 10.3684 2.55308C10.3995 2.52413 10.4242 2.48909 10.4412 2.45015C10.4581 2.41122 10.4668 2.36921 10.4668 2.32675C10.4668 2.28429 10.4581 2.24228 10.4412 2.20334C10.4242 2.1644 10.3995 2.12936 10.3684 2.10041C10.3407 2.07068 10.3073 2.04677 10.2703 2.03009C10.2332 2.01341 10.1932 2.00429 10.1525 2.00325H10.1417Z"
                fill="#202020"
            />
            <path
                d="M11.7771 6.69365C11.9967 6.69365 12.1747 6.51564 12.1747 6.29605C12.1747 6.07645 11.9967 5.89844 11.7771 5.89844C11.5575 5.89844 11.3795 6.07645 11.3795 6.29605C11.3795 6.51564 11.5575 6.69365 11.7771 6.69365Z"
                fill="#202020"
            />
            <path
                d="M11.7771 6.7855H11.7591C11.6244 6.78313 11.4961 6.72764 11.4021 6.63112C11.3081 6.5346 11.256 6.40485 11.2572 6.27013C11.2584 6.1354 11.3127 6.00659 11.4084 5.91174C11.5041 5.81689 11.6334 5.76367 11.7681 5.76367C11.9029 5.76367 12.0321 5.81689 12.1278 5.91174C12.2235 6.00659 12.2779 6.1354 12.2791 6.27013C12.2802 6.40485 12.2282 6.5346 12.1342 6.63112C12.0402 6.72764 11.9118 6.78313 11.7771 6.7855ZM11.7771 5.98848C11.6995 5.98825 11.6248 6.01789 11.5684 6.07124C11.5384 6.09888 11.5143 6.1322 11.4973 6.16926C11.4803 6.20632 11.4708 6.24639 11.4695 6.28714C11.467 6.36892 11.4971 6.44833 11.5531 6.50801C11.609 6.56768 11.6864 6.60277 11.7681 6.60559C11.8498 6.60758 11.9291 6.57793 11.9894 6.52283C12.0488 6.46635 12.0837 6.38884 12.0866 6.30693C12.0886 6.22525 12.0589 6.14596 12.0038 6.08564C11.9473 6.02624 11.8698 5.99136 11.7879 5.98848H11.7771Z"
                fill="#202020"
            />
            <path
                d="M10.1147 10.6697C10.3343 10.6697 10.5123 10.4917 10.5123 10.2721C10.5123 10.0525 10.3343 9.87451 10.1147 9.87451C9.89512 9.87451 9.7171 10.0525 9.7171 10.2721C9.7171 10.4917 9.89512 10.6697 10.1147 10.6697Z"
                fill="#202020"
            />
            <path
                d="M10.1147 10.7601C10.0165 10.7605 9.92048 10.7312 9.83919 10.676C9.7579 10.6209 9.69516 10.5425 9.65918 10.4511C9.62321 10.3597 9.61568 10.2596 9.63758 10.1638C9.65948 10.0681 9.70979 9.98116 9.78191 9.91448C9.8764 9.82622 10.0021 9.7791 10.1313 9.78348C10.2605 9.78787 10.3827 9.8434 10.471 9.93787C10.5593 10.0327 10.6065 10.1586 10.6021 10.2881C10.5977 10.4177 10.5422 10.5401 10.4476 10.6287C10.3575 10.7134 10.2384 10.7604 10.1147 10.7601ZM9.90425 10.0404C9.87125 10.0671 9.84409 10.1004 9.82447 10.138C9.80485 10.1757 9.7932 10.217 9.79023 10.2593C9.78725 10.3017 9.79303 10.3442 9.8072 10.3842C9.82136 10.4242 9.84362 10.4609 9.87257 10.492C9.90153 10.523 9.93656 10.5478 9.9755 10.5647C10.0144 10.5817 10.0564 10.5904 10.0989 10.5904C10.1414 10.5904 10.1834 10.5817 10.2223 10.5648C10.2613 10.5478 10.2963 10.5231 10.3252 10.492C10.355 10.4643 10.3789 10.4309 10.3956 10.3938C10.4122 10.3568 10.4214 10.3167 10.4224 10.2761C10.4239 10.2359 10.4174 10.1957 10.4032 10.158C10.389 10.1203 10.3674 10.0858 10.3396 10.0566C10.3121 10.0269 10.2791 10.0029 10.2423 9.98604C10.2055 9.96914 10.1658 9.95967 10.1253 9.95817C10.0849 9.95666 10.0445 9.96316 10.0066 9.97727C9.96865 9.99138 9.93388 10.0128 9.90425 10.0404Z"
                fill="#202020"
            />
            <path
                d="M6.16564 12.3055C6.38524 12.3055 6.56325 12.1275 6.56325 11.9079C6.56325 11.6883 6.38524 11.5103 6.16564 11.5103C5.94605 11.5103 5.76804 11.6883 5.76804 11.9079C5.76804 12.1275 5.94605 12.3055 6.16564 12.3055Z"
                fill="#202020"
            />
            <path
                d="M6.16744 12.3949H6.14765C6.01293 12.3925 5.88461 12.337 5.79061 12.2405C5.6966 12.144 5.64452 12.0142 5.64571 11.8795C5.6469 11.7448 5.70125 11.616 5.79694 11.5211C5.89263 11.4263 6.02191 11.373 6.15664 11.373C6.29138 11.373 6.42066 11.4263 6.51635 11.5211C6.61204 11.616 6.66639 11.7448 6.66758 11.8795C6.66876 12.0142 6.61669 12.144 6.52268 12.2405C6.42868 12.337 6.30035 12.3925 6.16564 12.3949H6.16744ZM6.16744 11.5979C6.08951 11.6026 6.01625 11.6365 5.96233 11.693C5.9084 11.7494 5.8778 11.8242 5.87666 11.9022C5.87553 11.9803 5.90393 12.0559 5.95619 12.1139C6.00844 12.1719 6.08069 12.208 6.15844 12.215C6.24022 12.2174 6.31963 12.1873 6.37931 12.1314C6.43899 12.0754 6.47407 11.9981 6.47689 11.9163C6.47888 11.8346 6.44923 11.7553 6.39413 11.695C6.33765 11.6356 6.26014 11.6007 6.17824 11.5979H6.16744Z"
                fill="#202020"
            />
            <path
                d="M2.15353 10.6429C2.37312 10.6429 2.55114 10.4649 2.55114 10.2453C2.55114 10.0257 2.37312 9.84766 2.15353 9.84766C1.93393 9.84766 1.75592 10.0257 1.75592 10.2453C1.75592 10.4649 1.93393 10.6429 2.15353 10.6429Z"
                fill="#202020"
            />
            <path
                d="M2.15536 10.7328H2.13737C2.07323 10.731 2.0101 10.7164 1.9517 10.6898C1.89329 10.6633 1.84081 10.6253 1.79734 10.5781C1.7431 10.5193 1.70414 10.4481 1.68389 10.3708C1.66364 10.2934 1.66273 10.2123 1.68123 10.1345C1.69973 10.0567 1.73707 9.9847 1.78997 9.92474C1.84287 9.86479 1.90971 9.81877 1.98458 9.79073C2.05946 9.76269 2.14008 9.7535 2.21935 9.76396C2.29862 9.77442 2.3741 9.80421 2.43914 9.85071C2.50418 9.89721 2.55678 9.959 2.59232 10.0306C2.62786 10.1022 2.64524 10.1815 2.64292 10.2614C2.64085 10.3255 2.62612 10.3886 2.59956 10.4469C2.573 10.5053 2.53516 10.5578 2.4882 10.6015C2.39831 10.6865 2.27908 10.7335 2.15536 10.7328ZM2.15536 9.93578C2.09495 9.93534 2.03575 9.9527 1.98513 9.98569C1.93452 10.0187 1.89474 10.0658 1.87076 10.1213C1.84677 10.1767 1.83965 10.238 1.85026 10.2975C1.86088 10.357 1.88878 10.412 1.93047 10.4557C1.95819 10.4855 1.99157 10.5094 2.02864 10.526C2.0657 10.5427 2.10573 10.5518 2.14637 10.5529C2.18666 10.5547 2.2269 10.5483 2.26463 10.5341C2.30237 10.5199 2.33682 10.4981 2.36586 10.4701C2.39578 10.4428 2.41995 10.4098 2.43694 10.3731C2.45394 10.3363 2.46341 10.2965 2.46481 10.256C2.46738 10.1743 2.43765 10.0948 2.38205 10.0347C2.35276 10.0035 2.31737 9.97858 2.27807 9.96156C2.23877 9.94454 2.19639 9.93577 2.15356 9.93578H2.15536Z"
                fill="#202020"
            />
            <path
                d="M0.51812 6.69365C0.737713 6.69365 0.915732 6.51564 0.915732 6.29605C0.915732 6.07645 0.737713 5.89844 0.51812 5.89844C0.298527 5.89844 0.120514 6.07645 0.120514 6.29605C0.120514 6.51564 0.298527 6.69365 0.51812 6.69365Z"
                fill="#202020"
            />
            <path
                d="M0.519949 6.7855H0.501957C0.367244 6.78313 0.238919 6.72764 0.144914 6.63112C0.0509094 6.5346 -0.00116626 6.40485 1.98254e-05 6.27013C0.00120591 6.1354 0.0555607 6.00659 0.15125 5.91174C0.24694 5.81689 0.376222 5.76367 0.510956 5.76367C0.64569 5.76367 0.774966 5.81689 0.870656 5.91174C0.966345 6.00659 1.0207 6.1354 1.02189 6.27013C1.02307 6.40485 0.970997 6.5346 0.876992 6.63112C0.782987 6.72764 0.654662 6.78313 0.519949 6.7855ZM0.519949 5.98848C0.469932 5.9891 0.420808 6.00183 0.37679 6.02559C0.332771 6.04935 0.295171 6.08343 0.267205 6.1249C0.239238 6.16637 0.221741 6.214 0.216214 6.26372C0.210687 6.31343 0.217295 6.36375 0.235469 6.41035C0.253644 6.45695 0.282847 6.49845 0.320572 6.5313C0.358298 6.56414 0.403421 6.58736 0.452081 6.59894C0.500741 6.61053 0.551487 6.61015 0.599968 6.59783C0.648449 6.58552 0.693221 6.56163 0.73045 6.52822C0.760426 6.50059 0.784609 6.46727 0.801596 6.43021C0.818583 6.39314 0.828035 6.35308 0.8294 6.31233C0.831396 6.23065 0.801745 6.15136 0.746642 6.09103C0.690169 6.03164 0.612652 5.99675 0.530744 5.99388L0.519949 5.98848Z"
                fill="#202020"
            />
            <path
                d="M2.18057 2.68145C2.40016 2.68145 2.57818 2.50343 2.57818 2.28384C2.57818 2.06425 2.40016 1.88623 2.18057 1.88623C1.96098 1.88623 1.78296 2.06425 1.78296 2.28384C1.78296 2.50343 1.96098 2.68145 2.18057 2.68145Z"
                fill="#202020"
            />
            <path
                d="M2.1895 2.77329C2.12209 2.77378 2.0553 2.76028 1.99337 2.73365C1.93143 2.70702 1.8757 2.66783 1.82967 2.61856C1.7413 2.52378 1.69414 2.39781 1.69852 2.26829C1.70291 2.13878 1.75848 2.01629 1.85306 1.9277C1.9485 1.83942 2.07508 1.79267 2.20498 1.79774C2.33488 1.8028 2.45746 1.85925 2.54573 1.95468C2.634 2.05012 2.68075 2.17671 2.67569 2.30661C2.67063 2.43651 2.61417 2.55907 2.51874 2.64735C2.42882 2.72938 2.31121 2.77437 2.1895 2.77329ZM2.1895 1.97627C2.10944 1.97624 2.03252 2.00742 1.97508 2.06318C1.91764 2.11894 1.88419 2.1949 1.88185 2.27493C1.87838 2.31545 1.88313 2.35625 1.8958 2.3949C1.90848 2.43354 1.92882 2.46923 1.95562 2.49982C1.98311 2.52952 2.01619 2.55351 2.05297 2.5704C2.08975 2.58729 2.1295 2.59676 2.16995 2.59827C2.21039 2.59977 2.25074 2.59328 2.28867 2.57917C2.3266 2.56505 2.36138 2.54359 2.391 2.51601C2.43887 2.47441 2.47272 2.41903 2.4879 2.35745C2.50308 2.29587 2.49886 2.23111 2.47582 2.17202C2.45278 2.11293 2.41203 2.06241 2.35917 2.02737C2.3063 1.99233 2.2439 1.97448 2.18051 1.97627H2.1895Z"
                fill="#202020"
            />
        </svg>
    );
};
