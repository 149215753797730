import { cssVars } from '@/styles/Themes/cssVars';
import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';
import { BreadcrumbsArrowRight } from '@/shared/images/icons/BreadcrumbsArrowRight';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import { ArrowLeftCard } from '@/shared/images/icons/ArrowLeftCard';
import { borders } from '@/styles/Themes/Metrics';

export const ResponsiveContainer = styled.div<{ height: number | undefined }>`
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : '242px')};
    position: relative;
    transition: height ease 0.4s;
`;

export const RecentSearchesAbsoluteContainer = styled.div<{ isVisible: boolean }>`
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: -24px;
    left: -20px;
    right: -20px;
    padding: 10px 20px;
    background-color: ${cssVars.bg_white_dark3};
    transition: transform ease 0.4s;
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(110%)')};

    @keyframes slideLeft {
        from {
            transform: translateX(110%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slideRight {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(110%);
        }
    }
    animation-duration: 0.3s;
    animation-delay: 0.15s;
    animation-name: slideLeft;
    animation-timing-function: ease;
    &.hide {
        animation-duration: 0.3s;
        animation-delay: 0.15s;
        animation-name: slideRight;
        animation-timing-function: ease;
    }
`;

export const TopSearchWrapper = styled.div`
    width: 100%;
    border-radius: ${radius.small};
    transition: all 0.4s ease;
    @keyframes appearance {
        from {
            opacity: 0;
        }
        to {
            opacity: 100%;
        }
    }

    animation-duration: 0.3s;
    animation-delay: 0.15s;
    animation-name: appearance;
    animation-timing-function: ease;
    animation-fill-mode: both;
`;
export const TopSearchRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
export const TopSearchContainer = styled.div`
    background-color: ${cssVars.bg_white_dark3};
    width: 100%;
    margin: 20px 0 0;
    max-width: 910px;
    border-radius: ${radius.large};
    padding: 0;
    height: 60px;
`;

export const SearchTitle = styled.div`
    ${Text.Mobile.large.medium}
    color: ${cssVars.fg_gray2_white};
`;

export const SearchLink = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${Text.Mobile.medium.medium}
    color: ${Palette.gray.gray3};
`;

export const SearchLeftLink = styled.div`
    display: flex;
    align-items: center;
`;

export const SearchLinkRightArrow = styled(BreadcrumbsArrowRight)`
    margin: 0 10px 0 0;
`;

export const SearchLinkLeftArrow = styled(ArrowLeftCard)`
    margin: 0 10px 0 0;
`;

export const IconClose = styled(CloseIcon)`
    stroke: ${Palette.white.normal};
`;

export const ButtonClose = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 0 0 17px;
    appearance: none;
    border: none;
    padding: 4px 4px;
    background: ${Palette.blue.normal};
    border: ${borders.small} ${Palette.white.normal};
    border-radius: ${radius.large};
    cursor: pointer;
`;
