import { memo, ReactElement } from 'react';
import { getDatesFormatted } from '../../app/utils';
import * as S from './RecentSearches.styles';

import { IAttractionsSearch } from '@/common/app/contexts/AttractionsContext';
import { ALL_EXPERIENCES } from '@/common/app/constants/constants';

interface IRecentSearchesProps {
    handleRecentSearch: (recent: IAttractionsSearch, callback?: () => void) => void;
    recents: IAttractionsSearch[];
    callback?: () => void;
}

const RecentSearchesComponent = ({
    recents,
    handleRecentSearch,
    callback,
}: IRecentSearchesProps): ReactElement => {
    return (
        <S.RecentsContainer>
            {recents?.map((recent) => {
                const { dateStartFormatted, dateEndFormatted, isSameMonth } = getDatesFormatted(
                    recent.dateRange
                );

                return (
                    <S.CollapsedContainer
                        key={`${recent.destination.id}-${
                            recent.category?.id ?? 'N'
                        }${dateStartFormatted}${dateEndFormatted}`}
                        isExpandible={null}
                    >
                        <S.RecentButton onClick={() => handleRecentSearch(recent, callback)}>
                            <S.RecentIconWrapper />
                            <S.CollapsedLeft>
                                <S.RecentDestination>{recent.destination.name}</S.RecentDestination>
                                <S.RecentCategory>
                                    <>{`${
                                        recent.category?.name ??
                                        recent.partner?.title ??
                                        ALL_EXPERIENCES
                                    }`}</>
                                </S.RecentCategory>
                            </S.CollapsedLeft>
                            <S.CollapsedRight>
                                <S.CollapsedDate>
                                    {dateStartFormatted}
                                    {isSameMonth ? '-' : <br />}
                                    {dateEndFormatted}
                                </S.CollapsedDate>
                            </S.CollapsedRight>
                        </S.RecentButton>
                    </S.CollapsedContainer>
                );
            })}
        </S.RecentsContainer>
    );
};

export const RecentSearches = memo(RecentSearchesComponent);
