export const ButtonLoading = () => {
    return (
        <div className="buttonLoading">
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="6.7998"
                    width="2.4"
                    height="3.6"
                    rx="1.2"
                    fill="url(#paint0_linear_12252_341111)"
                />
                <rect
                    x="6.7998"
                    y="12.3999"
                    width="2.4"
                    height="3.6"
                    rx="1.2"
                    fill="url(#paint1_linear_12252_341111)"
                />
                <rect
                    x="15.9995"
                    y="6.7998"
                    width="2.4"
                    height="3.6"
                    rx="1.2"
                    transform="rotate(90 15.9995 6.7998)"
                    fill="url(#paint2_linear_12252_341111)"
                />
                <rect
                    x="3.6001"
                    y="6.7998"
                    width="2.4"
                    height="3.6"
                    rx="1.2"
                    transform="rotate(90 3.6001 6.7998)"
                    fill="url(#paint3_linear_12252_341111)"
                />
                <rect
                    x="5.73608"
                    y="4.04004"
                    width="2.4"
                    height="3.6"
                    rx="1.2"
                    transform="rotate(135 5.73608 4.04004)"
                    fill="url(#paint4_linear_12252_341111)"
                />
                <rect
                    x="3.19141"
                    y="14.5054"
                    width="2.4"
                    height="3.6"
                    rx="1.2"
                    transform="rotate(-135 3.19141 14.5054)"
                    fill="url(#paint5_linear_12252_341111)"
                />
                <rect
                    x="11.9595"
                    y="5.7373"
                    width="2.4"
                    height="3.6"
                    rx="1.2"
                    transform="rotate(-135 11.9595 5.7373)"
                    fill="url(#paint6_linear_12252_341111)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_12252_341111"
                        x1="6.7998"
                        y1="1.8"
                        x2="9.1998"
                        y2="1.8"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#03D9B1" />
                        <stop offset="1" stopColor="#1CBBD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_12252_341111"
                        x1="6.7998"
                        y1="14.1999"
                        x2="9.1998"
                        y2="14.1999"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#03D9B1" />
                        <stop offset="1" stopColor="#1CBBD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_12252_341111"
                        x1="15.9995"
                        y1="8.5998"
                        x2="18.3995"
                        y2="8.5998"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#03D9B1" />
                        <stop offset="1" stopColor="#1CBBD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_12252_341111"
                        x1="3.6001"
                        y1="8.5998"
                        x2="6.0001"
                        y2="8.5998"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#03D9B1" />
                        <stop offset="1" stopColor="#1CBBD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_12252_341111"
                        x1="5.73608"
                        y1="5.84004"
                        x2="8.13608"
                        y2="5.84004"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#03D9B1" />
                        <stop offset="1" stopColor="#1CBBD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_12252_341111"
                        x1="3.19141"
                        y1="16.3054"
                        x2="5.59141"
                        y2="16.3054"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#03D9B1" />
                        <stop offset="1" stopColor="#1CBBD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_12252_341111"
                        x1="11.9595"
                        y1="7.5373"
                        x2="14.3595"
                        y2="7.5373"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#03D9B1" />
                        <stop offset="1" stopColor="#1CBBD9" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
