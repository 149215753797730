import { ReactElement } from 'react';
import * as S from './SliderDots.styles';
import { TSliderDots } from './SliderDots.domain';

export const SliderDots = ({ onClick, isActive }: TSliderDots): ReactElement => {
    return (
        <div onClick={onClick}>
            <S.SliderDots isActive={isActive} />
        </div>
    );
};
