import { ReactElement, ReactNode } from 'react';
import * as S from './Block.styles';

export const Block = ({
    children,
    noBlock,
}: {
    children: ReactNode;
    noBlock?: boolean;
}): ReactElement => {
    if (noBlock) return <>{children}</>;
    return (
        <S.Block>
            <S.Container>{children}</S.Container>
        </S.Block>
    );
};
