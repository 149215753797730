export const WeAreClosed = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="33.5" cy="33.5" r="33.5" fill="var(--bg-ash-darker)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.3712 21.0379H19.0195C17.3174 21.0379 15.9375 22.4177 15.9375 24.1199V38.4536C15.9375 40.1557 17.3174 41.5356 19.0195 41.5356H48.3712C50.0734 41.5356 51.4532 40.1557 51.4532 38.4536V24.1199C51.4532 22.4177 50.0734 21.0379 48.3712 21.0379ZM19.0195 20.0999C16.7993 20.0999 14.9995 21.8997 14.9995 24.1199V38.4536C14.9995 40.6738 16.7993 42.4736 19.0195 42.4736H48.3712C50.5914 42.4736 52.3912 40.6738 52.3912 38.4536V24.1199C52.3912 21.8997 50.5914 20.0999 48.3712 20.0999H19.0195Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.4356 42.0049C33.6946 42.0049 33.9046 42.2149 33.9046 42.4739V47.0712C33.9046 47.3302 33.6946 47.5402 33.4356 47.5402C33.1765 47.5402 32.9666 47.3302 32.9666 47.0712V42.4739C32.9666 42.2149 33.1765 42.0049 33.4356 42.0049Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.5303 48.9097C26.5303 48.6507 26.7403 48.4407 26.9993 48.4407H39.8718C40.1308 48.4407 40.3408 48.6507 40.3408 48.9097C40.3408 49.1687 40.1308 49.3787 39.8718 49.3787H26.9993C26.7403 49.3787 26.5303 49.1687 26.5303 48.9097Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.3304 36.567C25.2041 36.3409 25.285 36.0552 25.5111 35.9289C26.9281 35.1372 29.5055 33.774 33.6753 33.774C37.5591 33.774 39.81 35.0499 41.1863 35.9451C41.4035 36.0864 41.465 36.3769 41.3238 36.594C41.1825 36.8111 40.892 36.8727 40.6749 36.7314C39.3901 35.8958 37.3166 34.712 33.6753 34.712C29.736 34.712 27.3237 35.9907 25.9686 36.7477C25.7425 36.8741 25.4568 36.7932 25.3304 36.567Z"
                fill="#0DD1C5"
            />
            <path
                d="M38.4261 28.8498C38.4261 29.3192 38.0455 29.6998 37.5761 29.6998C37.1066 29.6998 36.7261 29.3192 36.7261 28.8498C36.7261 28.3803 37.1066 27.9998 37.5761 27.9998C38.0455 27.9998 38.4261 28.3803 38.4261 28.8498Z"
                fill="#0DD1C5"
            />
            <path
                d="M30.6995 28.8498C30.6995 29.3192 30.319 29.6998 29.8495 29.6998C29.3801 29.6998 28.9995 29.3192 28.9995 28.8498C28.9995 28.3803 29.3801 27.9998 29.8495 27.9998C30.319 27.9998 30.6995 28.3803 30.6995 28.8498Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
