export const ArrowIcon = (props: { className?: string }) => {
    return (
        <svg
            className={`ArrowIcon ${props.className}`}
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.7171 9.5249H3.55046L10.1338 2.94157C10.3838 2.69157 10.3838 2.2749 10.1338 2.0249C9.88379 1.7749 9.46712 1.7749 9.21712 2.0249L1.55046 9.69157C1.46712 9.85823 1.38379 9.94157 1.38379 10.1082C1.38379 10.2749 1.46712 10.4416 1.55046 10.5249L9.13379 18.1082C9.21712 18.1916 9.38379 18.2749 9.55046 18.2749C9.71712 18.2749 9.88379 18.1916 9.96712 18.1082C10.2171 17.8582 10.2171 17.4416 9.96712 17.1916L3.46712 10.6916H18.6338C18.9671 10.6916 19.3005 10.4416 19.3005 10.0249C19.3005 9.60824 19.0505 9.5249 18.7171 9.5249Z"
                fill="var(--svg-dark4-white)"
            />
        </svg>
    );
};
