import { DropdownOption } from '@/common/ui/inputs/Dropdown/Dropdown.types';
import { QUESTION_OPTIONS } from '@/shared/TalkToUs/domain/constants';
import { useState } from 'react';

export const useSelect = (options?: DropdownOption[]) => {
    const [questionValue, setQuestion] = useState<string>();

    const hookQuestions = options ?? QUESTION_OPTIONS;

    const question = {
        label: 'How can we help you?',
        value: questionValue,
        onChange: (newValue?: string) => setQuestion(newValue),
        options: hookQuestions,
        placeholder: 'Choose one ...',
    };

    return {
        ...question,
    };
};
