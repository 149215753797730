export const Chat = (props: { className?: string }) => {
    return (
        <svg
            className={`Chat ${props.className}`}
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.240234"
                y="0.666016"
                width="40"
                height="40"
                rx="20"
                fill="var(--bg-ash-dark3)"
            />
            <path
                d="M12.7053 29.0382C12.3332 29.0382 12.0542 28.8521 11.8681 28.573C11.5891 28.201 11.5891 27.6428 11.8681 27.2707C12.8914 25.7823 12.4263 25.1312 12.1472 24.48C11.0309 23.2707 10.4728 21.6893 10.4728 20.1079C10.4728 15.5498 15.0309 11.8289 20.7053 11.8289C26.2867 11.8289 30.9379 15.5498 30.9379 20.1079C30.9379 24.6661 26.3798 28.387 20.7053 28.387C19.6821 28.387 18.7519 28.294 17.7286 28.0149C17.3565 27.9219 17.0774 27.5498 17.1705 27.1777C17.2635 26.8056 17.6356 26.5265 17.9146 26.6196H18.0077C18.8449 26.8056 19.7751 26.8986 20.6123 26.8986C25.4495 26.8986 29.4495 23.8289 29.4495 20.0149C29.4495 16.201 25.5426 13.1312 20.6123 13.1312C15.7751 13.1312 11.7751 16.201 11.7751 20.0149C11.7751 21.3172 12.2402 22.5265 13.0774 23.6428C13.7286 24.6661 14.1007 25.7823 13.1705 27.3637C13.3565 27.2707 13.6356 27.2707 13.8216 27.1777C14.1937 27.0847 14.5658 27.2707 14.6588 27.6428C14.7518 28.0149 14.5658 28.387 14.1937 28.48C13.7286 28.573 13.2635 28.7591 12.7984 28.8521C12.8914 29.0382 12.7984 29.0382 12.7053 29.0382Z"
                fill="#0DD1C5"
            />
            <path
                d="M16.6124 21.2243C16.7984 21.2243 16.9845 21.1312 17.1705 21.0382C17.3566 20.9452 17.4496 20.7591 17.5426 20.5731C17.6356 20.387 17.6356 20.201 17.6356 20.0149C17.6356 19.8289 17.5426 19.6429 17.3566 19.4568C17.1705 19.2708 16.9845 19.1777 16.7984 19.0847C16.6124 19.0847 16.4263 19.0847 16.2403 19.1777C16.0542 19.2708 15.8682 19.3638 15.7752 19.5498C15.5891 19.7359 15.5891 19.9219 15.5891 20.108C15.5891 20.387 15.6821 20.6661 15.8682 20.8522C16.0542 21.0382 16.3333 21.2243 16.6124 21.2243Z"
                fill="#0DD1C5"
            />
            <path
                d="M20.6124 21.2243C20.7984 21.2243 20.9845 21.1312 21.1705 21.0382C21.3566 20.9452 21.4496 20.7591 21.5426 20.5731C21.6356 20.387 21.6356 20.108 21.6356 19.9219C21.6356 19.7359 21.5426 19.5498 21.3566 19.3638C21.1705 19.1777 20.9845 19.0847 20.7984 19.0847C20.6124 19.0847 20.4263 19.0847 20.2403 19.1777C19.9612 19.2708 19.8682 19.3638 19.6821 19.5498C19.5891 19.7359 19.4961 19.9219 19.4961 20.108C19.4961 20.387 19.5891 20.6661 19.7752 20.8522C20.0542 21.0382 20.3333 21.2243 20.6124 21.2243Z"
                fill="#0DD1C5"
            />
            <path
                d="M24.5194 21.224C24.7054 21.224 24.8914 21.131 25.0775 21.038C25.2635 20.9449 25.3566 20.7589 25.4496 20.5728C25.5426 20.3868 25.5426 20.2007 25.5426 20.0147C25.5426 19.8287 25.4496 19.6426 25.2635 19.4566C25.0775 19.2705 24.8914 19.1775 24.7054 19.1775C24.5193 19.1775 24.3333 19.1775 24.1473 19.2705C23.9612 19.3635 23.7752 19.4566 23.6821 19.6426C23.5891 19.7356 23.4961 19.9217 23.4961 20.1077C23.4961 20.3868 23.5891 20.6659 23.7752 20.8519C24.0542 21.038 24.2403 21.224 24.5194 21.224Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
