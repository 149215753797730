import { Button } from '@/common/ui';
import { SearchIcon } from '@/shared/images/icons/SearchIcon';
import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';

export const SearchBarForm = styled.div`
    width: 100%;
    display: block;
    position: relative;
    ${Screen.desktop} {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        border-radius: ${radius.large};
    }
`;

export const Tooltip = styled.div`
    color: ${Palette.white.normal};
    background: ${Palette.gradient.light};
    border-radius: ${radius.small};
    padding: 12px 14px;
    position: absolute;
    right: 33px;
    bottom: calc(100% - 7px);
    z-index: 1;
    transition: all ease 0.3s;
    min-width: 180px;
    width: 180px;
    font-size: 12px;
    font-weight: 500;

    &:before {
        content: '';
        height: 14px;
        width: 14px;
        background: #07d4b8;
        transform: rotate(45deg);
        position: absolute;
        bottom: -4px;
        left: 30px;
        border-radius: 3px;
    }

    ${Screen.ipad} {
        width: 220px;
        right: 40%;
        font-size: 14px;
    }

    ${Screen.desktop} {
        padding: 12px 20px;
        left: -0px;
        width: 220px;
        bottom: 55px;
        &:before {
            content: '';
            height: 10px;
            width: 10px;
            background: #07d4b8;
            transform: rotate(45deg);
            position: absolute;
            bottom: -4px;
            left: 30px;
            border-radius: 3px;
        }
    }
`;

export const SearchBarButton = styled(Button)`
    padding: 0;
    border-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: ${Palette.white.normal};

    ${Screen.desktop} {
        box-shadow: none;
        border-radius: 0 32px 32px 0;
        width: 16%;
        max-width: 130px;
        height: 60px;
        max-height: 60px;
        padding: 0 20px;
        font-size: ${Text.Desktop.large.medium};
    }
`;

export const SearchBarButtonIcon = styled(SearchIcon)`
    width: 14px;
    height: 13px;
    margin: 16px 10px;

    ${Screen.desktop} {
        margin: 0 6px;
    }
`;
