import { TStyleOverlay } from '@/common/ui/modals/Modal/Modal.types';
import { borders, radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { TTheme } from '@/styles/Themes/Theme.domain';
import styled, { DefaultTheme, ThemedStyledProps, ThemeProps } from 'styled-components';
import Logotype from '../images/Logo/Logotype';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Text from '@/styles/Themes/Text';
import { FlexPresets } from '@/styles/Themes/Presets';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import { Instagram } from '../images/icons/Instagram';
import { Facebook } from '../images/icons/Facebook';

interface ICloseIcon extends DefaultTheme {
    onClick: () => void;
}

export const Overlay = styled.div<ThemedStyledProps<TStyleOverlay, TTheme>>`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    display: ${({ open }) => (open ? 'block' : 'none')};
    background: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div<ThemeProps<TTheme>>`
    background-color: ${cssVars.bg_white_blueDark};
    box-shadow: 0px 10.89px 43.36px rgba(17, 15, 15, 0.53);
    border-radius: ${radius.small};

    padding: 22px;

    max-width: 640px;
    min-width: 340px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ModalTop = styled.div<ThemeProps<TTheme>>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
`;

export const ModalBody = styled.div`
    margin-top: 20px;
`;

export const FormHeader = styled.div`
    text-align: center;
`;

export const Logo = styled(Logotype)`
    width: 107px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 170px;
        height: 70px;
    }
`;

export const FormTitle = styled.h3`
    ${Text.Full.heading3};
    color: ${cssVars.fg_gray1_white};
`;

export const FormList = styled.ul`
    margin-top: 22px;
    color: ${cssVars.fg_gray2_gray3};
    list-style-type: none;
    padding-left: 15px;
    ${Screen.ipad} {
        padding: 10px 0 10px 20px;
    }
    ${Screen.desktop} {
        padding-left: 30px;
    }
`;

export const FormListItem = styled.li`
    ${Text.Full.large.semibold};
    color: ${cssVars.fg_gray1_white};
`;

export const Description = styled.p`
    ${Text.Full.medium.medium};
    color: ${cssVars.fg_gray1_white};
`;

export const FormInput = styled.input`
    ${FlexPresets.flexRowCenter};
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    border-radius: ${radius.large};
    background: ${cssVars.bg_white_dark3};
    color: ${cssVars.fg_gray2_gray3};
    padding: 0px 25px 0px 20px;
    width: 100%;
    height: 40px;
    outline: none;
    ${Text.Full.large.medium};

    ${Screen.desktop} {
        height: 50px;
    }
`;

export const Form = styled.div`
    ${FlexPresets.flexColCenter};
    margin: auto;
    margin-top: 2em;
    padding: 1em 0;
    gap: 15px;
    max-width: 320px;
`;

export const CloseButtonWithIcon = styled(CloseIcon)``;

export const CloseButton = styled.div<ICloseIcon>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${cssVars.bg_ash_normal};
    border-radius: ${radius.huge};
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 40px;
        height: 40px;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;
export const Button = styled.button`
    color: ${Palette.green.normal};
    background-color: transparent;
    border: ${borders.small} ${Palette.green.normal};
    border-radius: ${radius.large};
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${Text.Mobile.medium.medium}

    :active,
    :hover {
        border: ${borders.small} ${Palette.green.light};
    }

    ${Screen.ipad} {
        height: 55px;
        ${Text.Ipad.medium.medium}
    }
    ${Screen.desktop} {
        ${Text.Desktop.large.medium}
        width: 50%;
        height: 50px;
    }
`;

export const SuccessInstructions = styled.h4`
    ${Text.Full.heading4}
    color: ${cssVars.fg_gray1_white};
    text-align: center;
    margin-top: 20px;
`;

export const SocialIconsWrapper = styled.div`
    display: flex;
    ${Text.Full.large.semibold};
    color: ${cssVars.fg_gray2_gray3};
    flex-direction: row;
    justify-content: center;
    gap: 20px;
`;

export const HugeSocialIcons = styled.div`
    display: flex;
    align-items: center;
    background-color: ${cssVars.bg_white_dark3};
    width: 36px;
    height: 36px;
    border-radius: ${radius.small};
    margin: 5px 0;
    cursor: pointer;

    ${Screen.ipad} {
        height: 36px;
        margin: 6px 0;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
        margin: 0;
    }
`;

export const FacebookCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #5572b0 0%, #405c98 48.23%, #22438a 100%);
    border-radius: 36px;
`;

export const InstagramCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        #4c61d3 0%,
        #893fb8 19.06%,
        #c32994 40.42%,
        #ea2a67 56.25%,
        #f05631 73.75%,
        #f7a74b 87.81%,
        #fde174 98.44%
    );
    border-radius: 36px;
`;

export const InstagramIcon = styled(Instagram)`
    ${Screen.ipad} {
        width: 24px;
        height: 24px;
    }
`;

export const FacebookIcon = styled(Facebook)`
    ${Screen.ipad} {
        width: 24px;
        height: 24px;
    }
`;
