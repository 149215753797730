import { useEffect, useRef, RefObject, useCallback } from 'react';
import { useSwipe } from '@/common/app/hooks/useSwipe';
import { useOnClickOutside } from '@/common/service/hooks';
import { Portal } from '../portal';
import { IDrawerProps } from './Drawer.types';
import * as S from './Drawer.styles';

export const Drawer = ({
    open,
    setOpen,
    closeWithDelay,
    children,
    title,
    footer,
    customBtn,
}: IDrawerProps) => {
    const ref: RefObject<HTMLDivElement> = useRef(null);

    const handlerOnClose = useCallback(() => {
        if (ref.current) {
            ref.current.classList.add('close');
            setTimeout(() => setOpen(), 300);
        }
    }, [ref, setOpen]);

    const onSwipeHandler = useCallback(
        ({
            ref,
            currentY,
            initialY,
            event,
        }: {
            currentY: number;
            initialY: number;
            ref: RefObject<HTMLElement>;
            event: TouchEvent;
        }) => {
            const blockWithScroll = (event.target as HTMLDivElement).closest('.scrollResult');
            if (blockWithScroll && blockWithScroll.clientHeight < blockWithScroll.scrollHeight) {
                return;
            }
            const track = currentY - initialY;
            const el = ref.current;
            if (el) {
                const style = window.getComputedStyle(el).bottom;
                const bottom = +style.split('px')[0] || 0;

                el.style.bottom = `-${Math.min(
                    el.clientHeight,
                    Math.max(0, track < 0 ? Math.abs(bottom) + track : track)
                )}px`;

                if (el.clientHeight - track < 20) {
                    handlerOnClose();
                }
            }
        },
        [handlerOnClose]
    );

    const onTouchEndHandler = useCallback(
        ({
            ref,
            stopTime,
            startTime,
            stopY,
            startY,
            event,
        }: {
            startTime: number;
            startY: number;
            stopTime: number;
            stopY: number;
            ref: RefObject<HTMLElement>;
            event: TouchEvent;
        }) => {
            const blockWithScroll = (event.target as HTMLDivElement).closest('.scrollResult');
            if (blockWithScroll && blockWithScroll.clientHeight < blockWithScroll.scrollHeight) {
                return;
            }
            const el = ref.current;
            const differenceTime = stopTime - startTime;
            const differenceLength = stopY - startY;
            const isValidTime: boolean = differenceTime < 500;
            const isValidLength: boolean = differenceLength > 20;
            const isSpeedValid: boolean =
                isValidTime && isValidLength && Math.abs(differenceLength) / differenceTime > 0.5;

            if (el && !isSpeedValid) {
                el.style.bottom = `0`;
            }
            if (isSpeedValid) {
                handlerOnClose();
            }
        },
        [handlerOnClose]
    );

    useSwipe({
        ref,
        onSwipeHandler,
        onTouchEndHandler,
    });

    useOnClickOutside(ref, handlerOnClose);

    useEffect(() => {
        if (closeWithDelay) {
            handlerOnClose();
        }
    }, [closeWithDelay, handlerOnClose]);

    const isStringTitle = typeof title === 'string';

    if (!open) {
        return null;
    }

    return (
        <Portal>
            <S.DrawerWrapper ref={ref}>
                <S.DrawerContainer>
                    <S.DrawerHeader>
                        {isStringTitle ? <S.DrawerTitle>{title}</S.DrawerTitle> : title}
                        {customBtn ? (
                            customBtn
                        ) : (
                            <S.CloseButton onClick={handlerOnClose}>
                                <S.CloseButtonWithIcon />
                            </S.CloseButton>
                        )}
                    </S.DrawerHeader>
                    <S.DrawerBody>{children}</S.DrawerBody>
                    {!!footer && <S.DrawerFooter>{footer}</S.DrawerFooter>}
                </S.DrawerContainer>
            </S.DrawerWrapper>
        </Portal>
    );
};
