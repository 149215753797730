import styled from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import Text from '@/styles/Themes/Text';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import Screen from 'src/styles/Themes/Screen';

export const DrawerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1001;
    box-shadow: ${cssVars.shadow_modal};
    border-radius: 15px 15px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 80vh;
    background-color: ${cssVars.bg_white_dark3};
    padding: 24px 20px;
    width: 100%;
    box-sizing: border-box;
    transition: transform 0.4s ease;

    @keyframes slideon {
        from {
            transform: translateY(100%);
        }

        to {
            transform: translateY(0);
        }
    }

    animation-duration: 0.4s;
    animation-name: slideon;
    animation-timing-function: ease;

    &.close {
        transform: translateY(100%);
        transition: all 0.4s ease;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        left: 50%;
        width: 38px;
        height: 5px;
        transform: translateX(-50%);
        background-color: ${cssVars.bg_gray4_dark1};
        border-radius: ${radius.small};
    }
`;

export const DrawerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

export const DrawerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
`;

export const DrawerTitle = styled.div`
    ${Text.Mobile.heading4.normal};
    color: ${cssVars.fg_gray1_white};
    line-height: 40px;
`;

export const DrawerCloseIcon = styled(CloseIcon)``;

export const DrawerBody = styled.div`
    flex: 1;
    width: calc(100% + 40px);
    margin-top: 16px;
    overflow: hidden;
    margin: 0 -20px -24px;
    padding: 0 20px 24px;
`;

export const DrawerFooter = styled.div`
    margin-top: 16px;
`;

export const CloseButtonWithIcon = styled(CloseIcon)``;

export const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${cssVars.bg_ash_normal};
    border-radius: ${radius.huge};
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 40px;
        height: 40px;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;
