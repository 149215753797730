import { css } from 'styled-components';

export const FlexPresets = {
    flexColCenter: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `,
    flexColTop: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    `,
    flexColBottom: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    `,
    flexColBetween: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    `,
    flexRowCenter: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    `,
    flexRowTop: css`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    `,
    flexRowBottom: css`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
    `,
};
