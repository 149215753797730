export const TalkToUsIcon = (props: { className?: string }) => {
    return (
        <svg
            className={`TalkToUsIcon ${props.className}`}
            width="13px"
            height="13px"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.80324 12.9312C6.76751 12.9312 4.48501 12.0123 2.75772 10.2358C1.40056 8.88808 0.475224 7.11156 0.166779 5.3963C-0.141667 3.49727 0.290157 1.84327 1.40056 0.740602C1.52394 0.618084 1.709 0.495565 1.89407 0.434306C2.01745 0.373047 2.20252 0.373047 2.38758 0.373047C2.57265 0.373047 2.75772 0.495565 2.94279 0.556825C3.12785 0.618084 3.25123 0.801861 3.31292 0.985639L4.97852 3.19097C5.22528 3.55853 5.28697 3.92608 5.22528 4.29364C5.22528 4.66119 5.04021 5.02875 4.73177 5.33504L4.5467 5.51882C4.29994 5.76386 4.11488 6.13141 4.11488 6.56023C4.11488 6.92778 4.23826 7.29534 4.5467 7.60163L5.47204 8.52052C6.02724 9.07186 6.95257 9.07186 7.50777 8.52052L7.69284 8.33674C7.9396 8.09171 8.30973 7.90793 8.67986 7.84667C9.05 7.78541 9.42013 7.90793 9.79027 8.09171L12.0728 9.80697C12.1961 9.86823 12.3195 9.99074 12.4429 10.1745C12.5663 10.3583 12.628 10.5421 12.628 10.7259C12.628 10.9096 12.628 11.0934 12.5663 11.2772C12.5046 11.461 12.3812 11.6447 12.2578 11.7673C11.3942 12.5024 10.1604 12.9312 8.80324 12.9312ZM2.26421 1.29194C2.20252 1.29194 2.20252 1.29194 2.14083 1.29194C2.07914 1.29194 2.07914 1.35319 2.01745 1.35319C1.09211 2.21082 0.783669 3.61979 1.03043 5.21252C1.27718 6.80526 2.14083 8.398 3.37461 9.56193C5.96555 12.1348 9.79027 12.7474 11.6409 10.9096C11.7026 10.8484 11.7026 10.8484 11.7026 10.7871C11.7026 10.7259 11.7026 10.6646 11.7026 10.6646C11.7026 10.6033 11.7026 10.5421 11.6409 10.5421C11.6409 10.4808 11.5793 10.4808 11.5176 10.4196L9.29676 8.76556C9.17338 8.7043 8.98831 8.64304 8.80324 8.7043C8.61818 8.7043 8.4948 8.82682 8.30973 8.94934L8.12466 9.13311C7.19933 9.99074 5.6571 9.99074 4.79346 9.13311L3.86812 8.21423C3.49799 7.72415 3.25123 7.11156 3.25123 6.49897C3.25123 5.88638 3.49799 5.27378 3.92981 4.84497L4.11488 4.66119C4.23826 4.53867 4.29994 4.3549 4.36163 4.17112C4.36163 3.98734 4.36163 3.80356 4.23826 3.68104L2.57265 1.47571C2.51096 1.41445 2.44927 1.35319 2.44927 1.35319C2.38758 1.29194 2.32589 1.29194 2.26421 1.29194Z"
                fill="white"
            />
        </svg>
    );
};
