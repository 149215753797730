export const ModeMoon = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.57188 9.3487C3.62975 9.3505 2.70826 9.07273 1.92399 8.55053C1.13971 8.02834 0.527906 7.28519 0.165965 6.4151C0.139888 6.35277 0.132902 6.2841 0.145895 6.21779C0.158889 6.15149 0.191277 6.09053 0.238949 6.04267C0.286621 5.9948 0.34743 5.96217 0.413666 5.94892C0.479902 5.93567 0.548577 5.94239 0.610987 5.96824C1.36008 6.28218 2.18563 6.36606 2.98253 6.20918C3.77944 6.0523 4.51165 5.66177 5.08595 5.08729C5.66026 4.51282 6.05067 3.78039 6.2075 2.98325C6.36433 2.18611 6.28049 1.36032 5.96663 0.611005C5.94079 0.548576 5.93407 0.479878 5.94732 0.413623C5.96056 0.347367 5.99319 0.28654 6.04104 0.238854C6.08889 0.191168 6.14983 0.158772 6.21612 0.145775C6.2824 0.132778 6.35105 0.139765 6.41336 0.165849C7.4319 0.59105 8.27171 1.35611 8.7899 2.33088C9.30809 3.30564 9.47264 4.42988 9.25557 5.51232C9.0385 6.59477 8.45322 7.56855 7.59929 8.268C6.74537 8.96744 5.67556 9.34933 4.57188 9.3487ZM1.17367 6.85855C1.51178 7.35788 1.95601 7.77631 2.4746 8.08391C2.9932 8.39152 3.57333 8.58069 4.17352 8.63792C4.77371 8.69514 5.37914 8.61899 5.94648 8.41492C6.51383 8.21086 7.02908 7.88391 7.45541 7.45746C7.88173 7.03101 8.20859 6.5156 8.4126 5.94808C8.61661 5.38056 8.69273 4.77497 8.63553 4.1746C8.57832 3.57423 8.38919 2.99392 8.08168 2.47517C7.77417 1.95643 7.35587 1.51206 6.85669 1.17385C7.02745 1.95588 6.99895 2.7683 6.77383 3.53643C6.5487 4.30457 6.13416 5.00378 5.56831 5.5698C5.00247 6.13581 4.30346 6.55047 3.53555 6.77567C2.76764 7.00086 1.95546 7.02936 1.17367 6.85855Z"
                fill="#202020"
            />
        </svg>
    );
};
