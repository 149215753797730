import { ReactElement } from 'react';
import { LoadingBar, LoadingIcon } from './Loader.styles';

export const Loader = (): ReactElement => {
    return (
        <>
            <LoadingBar data-testid="loader">
                <LoadingIcon />
            </LoadingBar>
        </>
    );
};
