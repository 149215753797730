import { memo, ReactElement, useRef, useState, useEffect } from 'react';

import { useSearchContext } from '@/common/app/contexts';

import { useMedia } from '@/common/service/hooks/useMedia';

import { SearchForm } from '../SearchForm/SearchForm';
import { RecentSearches } from '../RecentsSearches/RecentSearches';

import * as S from './TopSearch.styles';
import { Drawer } from '@/common/ui/drawer';
import { IMobileSearchState, ITopSearch } from '../../types/index.types';

const TopSearchComponent = ({
    isOpen,
    closeHandler,
    onHandlerSearch,
}: ITopSearch): ReactElement => {
    const { recents, onRecentSearch } = useSearchContext();
    const ref = useRef<HTMLDivElement>(null);

    const isDesktop = useMedia('(min-width: 992px)');
    const isRecents = recents && Boolean(recents.length);

    const [mobileState, setMobileState] = useState<IMobileSearchState>({});
    const { isOpenRecents, label, height } = mobileState;

    const activeLabel = label ? (
        <S.SearchLeftLink
            onClick={() => {
                setMobileState((prev) => ({
                    ...prev,
                    goBack: true,
                }));
            }}
        >
            <S.SearchLinkLeftArrow /> {label}
        </S.SearchLeftLink>
    ) : !isRecents ? (
        "Let's find your adventure!"
    ) : (
        'New Search'
    );

    useEffect(() => {
        if (!isDesktop && mobileState.goBack && isOpenRecents) {
            ref.current && ref.current.classList.add('hide');
            setTimeout(() => setMobileState({}), 300);
        }
    }, [isDesktop, isOpenRecents, mobileState.goBack]);

    if (isDesktop) {
        return (
            <S.TopSearchWrapper>
                <S.TopSearchRow>
                    <S.TopSearchContainer>
                        <SearchForm onHandleSearch={onHandlerSearch} />
                    </S.TopSearchContainer>
                </S.TopSearchRow>
                <S.TopSearchRow>
                    {!!recents?.length && (
                        <RecentSearches
                            recents={recents}
                            handleRecentSearch={onRecentSearch}
                            callback={closeHandler}
                        />
                    )}
                </S.TopSearchRow>
            </S.TopSearchWrapper>
        );
    }

    return (
        <Drawer
            open={isOpen}
            setOpen={closeHandler}
            title={<S.SearchTitle>{activeLabel}</S.SearchTitle>}
            customBtn={
                !label &&
                isRecents && (
                    <S.SearchLink
                        onClick={() => {
                            setMobileState((prev) => ({
                                ...prev,
                                isOpenRecents: true,
                                label: 'New Search',
                            }));
                        }}
                    >
                        Recent searches&nbsp;
                        <S.SearchLinkRightArrow />
                    </S.SearchLink>
                )
            }
        >
            <S.ResponsiveContainer height={height}>
                <SearchForm
                    onHandleSearch={onHandlerSearch}
                    mobileState={mobileState}
                    setMobileState={setMobileState}
                />
                <S.RecentSearchesAbsoluteContainer isVisible={!!isOpenRecents} ref={ref}>
                    <RecentSearches
                        recents={recents || []}
                        handleRecentSearch={onRecentSearch}
                        callback={closeHandler}
                    />
                </S.RecentSearchesAbsoluteContainer>
            </S.ResponsiveContainer>
        </Drawer>
    );
};

export const TopSearch = memo(TopSearchComponent);
