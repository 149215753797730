import { ReactElement } from 'react';
import { SpinnerWrapper, DotSpinner } from './Spinner.styles';
import { TSpinnerProps } from './Spinner.types';

export const Spinner = ({ isLoading, ...props }: TSpinnerProps): ReactElement => {
    return (
        <>
            {isLoading && (
                <SpinnerWrapper {...props}>
                    <DotSpinner data-testid="spinner" />
                </SpinnerWrapper>
            )}
        </>
    );
};

export default Spinner;
