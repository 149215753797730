import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';
import styled, { DefaultTheme } from 'styled-components';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import Text from '@/styles/Themes/Text';
import { radius } from '@/styles/Themes/Metrics';

export const StyledNotification = styled.div<{ hide: boolean }>`
    display: flex;
    justify-content: flex-start;
    position: fixed;
    left: 1rem;
    top: 5em;
    background-color: ${cssVars.bg_white_dark3};
    padding: 0.75rem;
    z-index: 999999;
    box-shadow: ${cssVars.shadow_card_default};
    border-radius: 80px 15px 15px 80px;
    width: 90%;
    height: 96px;
    color: ${cssVars.fg_gray3_dark1};

    @keyframes showToast {
        from {
            transform: translateX(-100%);
        }

        to {
            transform: translateX(0);
        }
    }
    @keyframes hideToast {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100%);
        }
    }

    animation-duration: 0.3s;
    animation-name: ${({ hide }) => (hide ? 'hideToast' : 'showToast')};
    transition: all 0.35s ease-in-out;

    ${Screen.ipad} {
        width: 433px;
        height: 108px;
    }

    ${Screen.desktop} {
        width: 480px;
        height: 140px;
    }
`;

export const LeftPart = styled.div`
    width: 30%;
    position: relative;

    > svg {
        position: absolute;
        left: 0;
        top: -1px;
        width: 78px;
        height: 78px;

        ${Screen.ipad} {
            width: 92px;
            height: 92px;
            left: -2px;
            top: -2px;
        }

        ${Screen.desktop} {
            width: 120px;
            height: 120px;
            left: 0;
            top: -1px;
        }
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: end;

    > button {
        width: 70px;
        height: 20px;
        box-shadow: none;

        ${Screen.ipad} {
            width: 90px;
            height: 26px;
        }
        ${Screen.desktop} {
            width: 100px;
            height: 30px;
        }
    }
`;

export const RightPart = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    width: 70%;

    ${Screen.desktop} {
        justify-content: space-around;
    }
`;

export const NotificationTitle = styled.div`
    display: flex;
    align-items: center;
    ${Text.Full.medium.semibold};
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    width: 85%;
    max-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;

    ${Screen.ipad} {
        max-height: 40px;
    }

    ${Screen.desktop} {
        width: 90%;
    }
`;

export const NotificationParagraph = styled.div`
    display: flex;
    justify-content: space-between;

    ${Screen.desktop} {
        display: flex;
        align-items: end;
        justify-content: space-between;
    }
`;

export const NotificationText = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.normal.medium};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const NotificationTextWithBtn = styled.div`
    width: 65%;
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.normal.medium};
`;

interface ICloseIcon extends DefaultTheme {
    onClick: () => void;
}

export const CloseButtonWithIcon = styled(CloseIcon)``;

export const CloseButton = styled.div<ICloseIcon>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: ${cssVars.bg_ash_normal};
    border-radius: ${radius.huge};
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
    z-index: 1;

    ${Screen.ipad} {
        width: 40px;
        height: 40px;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
        right: 6px;
        top: 6px;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;
