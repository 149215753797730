import { ReactElement } from 'react';
import { StarEmpty } from '@/shared/images/icons/StarEmpty';
import { Star } from '@/shared/images/icons/Star';
import { StarHalf } from '@/shared/images/icons/StarHalf';

import * as S from './Rating.styles';
import { TRatingStars } from '@/common/domain/Rating.domain';

export type RatingProps = {
    rating: TRatingStars;
};

const Rating = ({ rating }: RatingProps): ReactElement => {
    const { full, half } = rating;

    if (full + half < 1 || full + half > 5) {
        return <></>;
    }

    return (
        <S.Rating data-test-id="Rating">
            {Array.from({ length: 5 }, (_, i) => {
                if (i < full) {
                    return <Star key={i} />;
                }
                if (i === full && half === 1) {
                    return <StarHalf key={i} />;
                }
                return <StarEmpty key={i} />;
            })}
        </S.Rating>
    );
};

export { Rating };
