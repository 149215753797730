import styled, { css } from 'styled-components';

export const Container = styled.div<{ isLoading: boolean }>`
    position: relative;

    ${({ isLoading }) =>
        isLoading &&
        css`
            > * {
                &:first-child {
                    filter: blur(4px);
                }
            }
        `}
`;

export const SpinnerWrap = styled.div<{ isLoading: boolean }>`
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;
    cursor: not-allowed;

    ${({ isLoading }) =>
        isLoading &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            > * {
                &:first-child {
                    filter: drop-shadow(2px 4px 6px black);
                }
            }
        `}
`;
