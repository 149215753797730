import { useCallback, useEffect, useState } from 'react';
import { GetBoundingBox, MapPoint } from './locationMap/bboxUtils';
import * as S from './LocationMap.styles';

type LocationMapProps = {
    latitude: string;
    longitude: string;
    width?: string;
    height?: string;
    address: string | undefined;
};

export const LocationMap = ({ latitude, longitude, address }: LocationMapProps) => {
    const [bbox, setBbox] = useState('');
    const src = `https://www.openstreetmap.org/export/embed.html?bbox=${bbox}&layer=mapnik&marker=${latitude},${longitude}`;

    const calcBbox = useCallback(() => {
        const mapPoint = new MapPoint(parseFloat(longitude), parseFloat(latitude));
        const bboxObject = GetBoundingBox(mapPoint, 2);
        const bboxArray = [
            bboxObject.minPoints.lat,
            bboxObject.minPoints.lng,
            bboxObject.maxPoints.lat,
            bboxObject.maxPoints.lng,
        ];

        setBbox(bboxArray.join(','));
        return bboxObject;
    }, [latitude, longitude]);

    useEffect(() => {
        calcBbox();

        return () => {
            setBbox('');
        };
    }, [calcBbox, latitude, longitude]);

    return (
        <>
            {address && <S.Address>{address}</S.Address>}
            <S.Map
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
                src={src}
                title="Location Map"
            />
        </>
    );
};
