import styled from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import { Palette } from '@/styles/Themes/Palette';

export const StyledProgressBar = styled.progress<{ value: number }>`
    width: 100%;
    appearance: none;
    block-size: 6px;
    margin: 6px 0 0;

    &::-webkit-progress-bar {
        background-color: ${cssVars.bg_ash_dark3};
        height: 6px;
        border-radius: 6px;
    }

    &::-webkit-progress-value {
        background-color: ${Palette.green.normal};
        max-height: 6px;
        border-radius: 6px;
    }
`;
