import { memo, ReactElement, useEffect, useMemo, useState } from 'react';
import * as S from './ProgressBar.styles';

const START_PERCENTS = 100;
const STEP_PERCENTS = 1;

type TProgressBarProps = {
    delayMs: number;
};

const ProgressBarComponent = ({ delayMs }: TProgressBarProps): ReactElement => {
    const [remains, setRemains] = useState(START_PERCENTS);

    const intervalTime = useMemo(() => (delayMs / START_PERCENTS) * STEP_PERCENTS, [delayMs]);

    useEffect(() => {
        const changeLine = setInterval(() => {
            setRemains((prev) => {
                if (prev) {
                    return prev - STEP_PERCENTS;
                } else {
                    clearInterval(changeLine);
                    return 0;
                }
            });
        }, intervalTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <S.StyledProgressBar value={remains} max={START_PERCENTS} />;
};

const ProgressBar = memo(ProgressBarComponent);
export { ProgressBar };
export default ProgressBar;
