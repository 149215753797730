import { ArrowRight } from '@/shared/images/icons/ArrowRight';
import { FooterPhone } from '@/shared/images/icons/FooterPhone';
import Logotype from '@/shared/images/Logo/Logotype';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import Screen from '@/styles/Themes/Screen';
import Text from '@/styles/Themes/Text';
import styled from 'styled-components';

export const Footer = styled.footer`
    background-color: ${cssVars.bg_blue_dark3};
    border-radius: 15px 15px 0px 0px;
    width: 100%;
`;

export const Container = styled.div`
    padding: 30px 20px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    ${Screen.ipad} {
        padding: 30px 30px 0;
    }
    ${Screen.desktop} {
        padding: 46px 30px 0;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 1124px;
        margin: auto;
    }
`;

export const Title = styled.h3`
    color: ${Palette.white.normal};
    ${Text.Full.large.semibold}
    margin: 0 0 10px;
    ${Screen.ipad} {
        margin: 0 0 16px;
    }
    ${Screen.desktop} {
        margin: 0 0 24px;
    }
`;

export const PhoneLink = styled.div`
    color: ${Palette.white.normal};
    ${Text.Full.large.semibold}
    cursor: pointer;
    display: flex;
    align-items: center;

    margin: 32px 0;
    ${Screen.desktop} {
        margin: 20px 0;
    }
`;

export const PhoneIcon = styled(FooterPhone)`
    margin-right: 18px;
`;

export const CopyrightContainer = styled.div`
    width: 100%;
    border-top: 1px solid ${Palette.dark.dark2};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const Sign = styled.span`
    color: ${Palette.white.normal};
    ${Text.Full.normal.regular}
    display: flex;
    position: absolute;
    top: 40px;
    align-items: center;
    ${Screen.ipad} {
        left: 0;
        top: auto;
    }
`;

export const Logo = styled(Logotype)`
    width: 107px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 170px;
        height: 70px;
    }
`;

export const SmallArrow = styled(ArrowRight)`
    height: 16px;
    width: 13px;
`;

export const ColoredSign = styled.span`
    color: ${Palette.green.normal};
    ${Text.Full.normal.medium}
`;
