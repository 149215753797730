import styled from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import Text from '@/styles/Themes/Text';
import Palette from '@/styles/Themes/Palette';
import { borders } from '@/styles/Themes/Metrics';
import Screen from '@/styles/Themes/Screen';

export const Label = styled.label`
    display: flex;
    padding: 20px 16px 15px;
    *:nth-child(2) {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        border: ${borders.normal} ${cssVars.fg_gray2_gray3};
        border-radius: 6px;
        cursor: pointer;

        :hover {
            border: ${borders.normal} ${Palette.green.normal};
        }
    }
    ${Screen.ipad} {
        padding: 24px 19px 15px;
    }

    ${Screen.desktop} {
        padding: 24px 25px 30px;
    }
`;

export const Input = styled.input`
    display: none;

    &:checked + i:before {
        position: absolute;
        top: 2px;
        left: 2px;
        content: '';
        width: 10px;
        height: 5px;
        border-left: ${borders.large} ${Palette.white.normal};
        border-bottom: ${borders.large} ${Palette.white.normal};
        transform: rotate(-45deg);
    }
    &:checked + i {
        background-color: ${Palette.green.normal};
        border: ${borders.normal} ${Palette.green.normal};
    }
`;

export const Title = styled.span`
    color: ${cssVars.fg_gray2_white};
    ${Text.Full.medium.medium}
    margin-left: 10px;
`;
