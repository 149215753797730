import { ReactElement, memo } from 'react';
import { Spinner } from '../Spinner';
import * as S from './Dimmer.styles';

type DimmerProps = {
    children: ReactElement;
    isLoading: boolean;
};

export const DimmerContainer = ({ children, isLoading }: DimmerProps): ReactElement => {
    return (
        <S.Container isLoading={isLoading}>
            {children}
            <S.SpinnerWrap isLoading={isLoading}>
                <Spinner isLoading />
            </S.SpinnerWrap>
        </S.Container>
    );
};

export const Dimmer = memo(DimmerContainer);
