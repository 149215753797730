import { ReplyProps } from './ResponseModal.types';
import * as S from './ResponseModal.styles';
import { useOnClickOutside } from '@/common/service/hooks';
import { useRef } from 'react';

const ResponseModal = ({ show, setShow, reply, title }: ReplyProps) => {
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => closeModal());

    if (!show) {
        return null;
    }

    const closeModal = () => {
        setShow(false);
    };

    return (
        <>
            {show && (
                <S.ModalWrapper>
                    <S.ModalContent ref={ref}>
                        <S.Header>
                            <S.Title>{title}</S.Title>
                            <S.CloseButton onClick={() => closeModal()}>
                                <S.CloseButtonWithIcon />
                            </S.CloseButton>
                        </S.Header>

                        <S.ModalBody>{reply?.content}</S.ModalBody>
                        <S.ModalAuthor>Management</S.ModalAuthor>
                        <S.ModalTime>{reply?.time}</S.ModalTime>
                    </S.ModalContent>
                </S.ModalWrapper>
            )}
        </>
    );
};

export { ResponseModal };
export default ResponseModal;
