import { ApiUrls } from '@/common/domain/Endpoints.domain';
import instance from '@/common/service/api/Axios';
import { TSelected } from '@/common/app/contexts/ActivityContext';
import { TAddToCartResponse } from '../domain/Cart.domain';

export const addCartItem = (session_id: string, data: TSelected): Promise<TAddToCartResponse> => {
    return instance
        .post<TAddToCartResponse>(ApiUrls.cart.add, { session_id, data })
        .then(({ data }) => data);
};
