export const RedCross = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6.5" cy="6.5" r="6.5" fill="#EC465A" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.87819 4.30804C4.72018 4.14927 4.46372 4.14927 4.30571 4.30804C4.1481 4.46643 4.1481 4.72293 4.30571 4.88131L5.92709 6.51055L4.32654 8.11886C4.16892 8.27725 4.16892 8.53375 4.32654 8.69213C4.48454 8.8509 4.74101 8.8509 4.89901 8.69213L6.49858 7.08481L8.09812 8.69211C8.25612 8.85088 8.51259 8.85088 8.67059 8.69211C8.82821 8.53372 8.82821 8.27722 8.67059 8.11884L7.07006 6.51055L8.69142 4.88134C8.84903 4.72295 8.84903 4.46645 8.69142 4.30807C8.53341 4.1493 8.27695 4.1493 8.11894 4.30807L6.49858 5.93629L4.87819 4.30804Z"
                fill="white"
            />
        </svg>
    );
};
