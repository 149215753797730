import { ReactElement, memo, useMemo } from 'react';
import * as S from './VoucherCard.styles';
import { Button } from '@/common/ui';
import { CalendarIcon } from '@/shared/images/icons/CalendarIcon';
import URL from '@/common/app/utils/urlsUtils';
import Link from 'next/link';
import { format, parse } from 'date-fns';
import { SERVER_FORMAT_DATE } from '@/common/app/constants/timeConstants';
import { ICartItem } from '@/entities/Checkout/domain/Checkout.domain';

type TVoucherCardProps = {
    item: ICartItem;
    accessKey: string;
    inactive?: boolean;
};

const VoucherCard = ({ item, accessKey, inactive }: TVoucherCardProps): ReactElement => {
    const url = `/order/voucher/?id=${item.id}&key=${accessKey}`;
    const imageUrl = `${URL.image}/activity/${item.activity.id}/600x400/${item.activity.slug}.jpg`;
    const time = item.schedule && item.schedule.time;
    const arrivalTime = item.trip_id === '1453' ? 'All day admission' : time;

    const arrivalDate = useMemo(() => {
        if (!item.arrival_date) {
            return null;
        }
        const date = parse(item.arrival_date, SERVER_FORMAT_DATE, 0);
        if (!date || !date.getTime()) {
            return null;
        }
        return format(date, 'EEEE, MMMM do, yyyy');
    }, [item.arrival_date]);

    return (
        <S.Container data-test-id="voucher-card" style={{ opacity: inactive ? '0.4' : '1' }}>
            <S.VoucherInfoImage src={imageUrl} data-test-id="voucher-image" />
            <S.VoucherInfoWrapper data-test-id="voucher-content">
                <S.ContentWrapper>
                    <Link href={url} passHref>
                        <S.VoucherInfoTitle data-test-id="voucher-link">
                            {item.trip_name}
                        </S.VoucherInfoTitle>
                    </Link>

                    {!!arrivalDate && (
                        <S.VoucherInfoDate>
                            <CalendarIcon />
                            {arrivalDate}
                            {arrivalTime && ` @ ${arrivalTime}`}
                        </S.VoucherInfoDate>
                    )}
                    {(item.state === 'cancelled' || item.state === 'deleted') && (
                        <div
                            style={{
                                fontWeight: 600,
                                color: 'red',
                                textTransform: 'uppercase',
                                marginLeft: '10px',
                            }}
                        >
                            {item.state}
                        </div>
                    )}
                </S.ContentWrapper>
                <Link href={url} passHref>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            return;
                        }}
                        data-test-id="voucher-button-link"
                    >
                        Get Voucher
                    </Button>
                </Link>
            </S.VoucherInfoWrapper>
        </S.Container>
    );
};

export { VoucherCard };
export default memo(VoucherCard);
