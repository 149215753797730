import { memo, useCallback, useMemo, useState } from 'react';
import { useAppContext, useSearchContext } from '@/common/app/contexts';
import { Button } from '@/common/ui';
import { InputSearch } from '@/common/ui/inputs/InputSearch/InputSearch';
import { DatePicker } from '@/common/ui/inputs/DatePicker/DatePicker';
import { IDateRange } from '@/common/domain/Date.domain';
import { ISearchFormProps, ISearchOption } from '../../types/index.types';
import * as S from './SearchForm.styles';
import { ALL_COMPANIES, ALL_EXPERIENCES } from '@/common/app/constants/constants';

const SearchFormComponent = ({ onHandleSearch, setMobileState, mobileState }: ISearchFormProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const { destinations } = useAppContext();
    const {
        selectedDestination,
        selectedCategory,
        selectedDateRange,
        selectedPartner,
        isExperience,
        destinationCategories,
        destinationPartners,
        onSearch,
        setExperience,
        saveDestinationById,
        saveCategoryById,
        savePartnerById,
        saveDateRange,
    } = useSearchContext();

    const destinationOptions = useMemo(() => {
        return (
            destinations
                ?.filter((i) => i.content_only !== '1')
                .map((destination) => ({
                    value: destination.id,
                    label: `${destination.name}, ${destination.state.code}`,
                    counter: destination.state.code,
                    state: destination.state.name,
                    isSelected: destination.id === selectedDestination?.id,
                })) || []
        );
    }, [destinations, selectedDestination?.id]);

    const categoriesOptions = useMemo(() => {
        const categories: ISearchOption[] = [];

        if (!selectedDestination) return [];

        if (isExperience) {
            const experiences =
                destinationCategories
                    ?.filter(({ category, counter }) => !!category && +counter)
                    ?.map(({ counter, category }) => ({
                        value: category?.id || '',
                        label: category?.name || '',
                        counter: counter,
                        isSelected: category?.id === selectedCategory?.id,
                    })) || [];
            categories.push(
                {
                    value: '',
                    label: ALL_EXPERIENCES,
                    counter: `${selectedDestination?.total_activities || 0}`,
                },
                ...experiences
            );

            return categories;
        }

        const companies =
            destinationPartners.items
                ?.filter(
                    (partner) =>
                        partner.destination_id === selectedDestination.id &&
                        partner.available_trips_count
                )
                .map((partner) => ({
                    value: partner.id,
                    label: partner.title,
                    counter: `${partner.available_trips_count}`,
                    isSelected: selectedCategory?.id === partner.id,
                })) || [];

        categories.push(
            {
                value: '',
                label: ALL_COMPANIES,
                counter: `${selectedDestination?.total_activities || 100}`,
            },
            ...companies
        );
        return categories;
    }, [
        destinationCategories,
        destinationPartners,
        isExperience,
        selectedCategory?.id,
        selectedDestination,
    ]);

    const handleSubmit = useCallback(() => {
        if (!selectedDestination) {
            setShowTooltip(true);
        }
        onSearch({
            searchDestination: selectedDestination,
            searchCategory: selectedCategory,
            searchPartner: selectedPartner,
            searchDateRange: selectedDateRange,
        });
        if (onHandleSearch) {
            onHandleSearch();
        }
    }, [
        onSearch,
        selectedDestination,
        selectedCategory,
        selectedPartner,
        selectedDateRange,
        onHandleSearch,
    ]);

    return (
        <S.SearchBarForm data-test-id="Search_bar">
            {showTooltip && <S.Tooltip>Choose Your Destination</S.Tooltip>}
            <InputSearch
                label="Destination"
                placeholder="Where?"
                value={
                    selectedDestination
                        ? `${selectedDestination.name}, ${selectedDestination.state.code}`
                        : undefined
                }
                options={destinationOptions}
                onChange={(value) => {
                    saveDestinationById(value);
                    setShowTooltip(false);
                }}
                mobileState={mobileState}
                setMobileState={setMobileState}
            />
            <InputSearch
                label={isExperience ? 'Experience' : 'Company'}
                placeholder={isExperience ? ALL_EXPERIENCES : ALL_COMPANIES}
                value={isExperience ? selectedCategory?.name : selectedPartner?.title}
                options={categoriesOptions}
                onChange={(value) =>
                    isExperience ? saveCategoryById(value) : savePartnerById(value)
                }
                mobileState={mobileState}
                setMobileState={setMobileState}
                loading={!isExperience ? destinationPartners.loading : undefined}
                footer={
                    <Button variant="primary" onClick={() => setExperience((prev) => !prev)}>
                        {`Search by ${isExperience ? 'company' : 'experience'}`}
                    </Button>
                }
            />
            <DatePicker
                label="Trip dates"
                onChange={(newDateRange: IDateRange) => saveDateRange(newDateRange)}
                dateStart={selectedDateRange?.dateStart}
                dateEnd={selectedDateRange?.dateEnd}
                mobileState={mobileState}
                setMobileState={setMobileState}
                pastDisabled
            />
            <S.SearchBarButton
                //onEnter={() => handleSubmit()}
                variant="primary"
                onClick={() => handleSubmit()}
                aria-label="Search"
            >
                <S.SearchBarButtonIcon />
                Search
            </S.SearchBarButton>
        </S.SearchBarForm>
    );
};

export const SearchForm = memo(SearchFormComponent);
