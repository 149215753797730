import { ReactElement, memo } from 'react';
import * as S from './HeroImage.styles';
import { IHeroImageProps } from './HeroImage.domain';
import { SearchBar } from '@/shared/SearchBar/ui';
import { Container } from '@/common/ui/containers/Container';

export const HeroImage = ({
    title,
    subtitle,
    images,
    withMobileHeroImage,
    isHomePage,
    noSearch,
}: IHeroImageProps): ReactElement => {
    return (
        <S.Wrapper
            withMobileHeroImage={withMobileHeroImage}
            isHomePage={isHomePage}
            image={images[0]}
            noSearch={noSearch}
        >
            <Container>
                <S.HeroContent>
                    {typeof title === 'string' ? <S.Title>{title}</S.Title> : title}
                    {typeof subtitle === 'string' ? <S.Subtitle>{subtitle}</S.Subtitle> : subtitle}
                    <S.SearchContainer mobileNoSearch={noSearch}>
                        <SearchBar />
                    </S.SearchContainer>
                </S.HeroContent>
            </Container>
        </S.Wrapper>
    );
};

export default memo(HeroImage);
