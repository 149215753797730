import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { borders, radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';

export const Container = styled.div`
    margin: 3px 0 17px;

    ${Screen.desktop} {
        margin: 0;
    }
`;

export const Label = styled.label`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular}
    display: flex;
    margin: 0 0 4px 18px;
`;

export const Input = styled.input<{ error: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${borders.small}
        ${({ error }) => (error ? Palette.state.error : cssVars.fg_gray3_dark1)};
    border-radius: ${radius.large};
    background: ${cssVars.bg_white_dark3};
    color: ${cssVars.fg_gray2_gray3};
    padding: 0px 25px 0px 20px;
    gap: 154px;
    width: 100%;
    height: 50px;
`;

export const RedStar = styled.span`
    color: ${Palette.state.error};
    padding-right: 3px;
`;
