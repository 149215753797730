import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { TTheme } from '@/styles/Themes/Theme.domain';
import styled, { ThemedStyledProps } from 'styled-components';
import { TIconButtonStyled } from './IconButton.types';

export const IconContainer = styled.button<ThemedStyledProps<TIconButtonStyled, TTheme>>`
    ${({ theme }) => theme.presets.icon[0]};
    color: ${({ theme, items }) => (items ? theme.colors?.white : theme.colors?.shades.gray3)};

    &:hover {
        color: ${({ theme, items }) =>
            (items ?? 0) > 0 ? theme.colors?.primary : theme.colors?.shades.gray3};
    }
`;

export const IconSavedValue = styled.div<TIconButtonStyled>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    background-color: ${Palette.white.normal};
    border-radius: ${radius.medium};
    color: ${Palette.gray.gray1};

    &:hover {
        color: ${({ theme, items }) =>
            (items ?? 0) > 0 ? theme.colors?.white : theme.colors?.shades.gray3};
        background-color: ${({ theme, items }) =>
            (items ?? 0) > 0 ? theme.colors?.primary : theme.colors?.white};
    }
`;
