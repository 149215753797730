import { TColorLevel } from './Palette';
import { TFilter, TMetrics } from './Theme.domain';

export const radius: TMetrics = {
    none: '0',
    tiny: '5px',
    small: '15px',
    designer: '17px',
    medium: '20px',
    normal: '25px',
    large: '50px',
    xlarge: '75px',
    huge: '90px',
    button: '50px',
};

export const margins: TMetrics = {
    none: '0',
    tiny: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    normal: '1.5rem',
    large: '2rem',
    xlarge: '3rem',
    huge: '5rem',
    button: '1rem',
};

export const paddings: TMetrics = {
    none: '0',
    tiny: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    normal: '1.5rem',
    large: '2rem',
    xlarge: '3rem',
    huge: '5rem',
    button: '1rem',
};

export const opacity: TColorLevel = {
    white: '0',
    lighter: '0.9',
    light: '0.8',
    normal: '1',
    dark: '0.6',
    darker: '0.4',
    strong: '0.2',
    black: '1',
    error: '1',
};

export const borders: TMetrics = {
    none: '0',
    tiny: '0.5px solid',
    small: '1px solid',
    medium: '2px solid',
    normal: '2px solid',
    large: '3px solid',
    xlarge: '4px solid',
    huge: '5px solid',
};

export const filter: TFilter = {
    brightness: 'brightness(1.25)',
};
