import { InputForm } from '@/common/ui/inputs/InputForm/InputForm';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';
import { ReactElement, memo } from 'react';
import * as S from './GuestForms.styles';

type GuestFormsProps = {
    ticketId: number;
    count: number;
};

export const GuestFormsContainer = ({ ticketId, count }: GuestFormsProps): ReactElement => {
    const { selected, selectGuests } = useActivityContext();

    const values = selected.guests[ticketId] || {};

    return (
        <S.Container data-test-id="guest-form">
            {Array(count)
                .fill(0)
                .map((_, index) => {
                    const countId = index + 1;

                    return (
                        <S.Line key={ticketId.toString() + countId}>
                            <InputForm
                                data-test-id="input"
                                label={`Ticket ${countId} - First Name`}
                                name="first_name"
                                placeholder="First name"
                                value={values[countId]?.first_name || ''}
                                onChange={(evt) => {
                                    selectGuests(ticketId, countId, {
                                        first_name: evt.target.value,
                                    });
                                }}
                                type="text"
                                required
                            />
                            <InputForm
                                data-test-id="input"
                                label={`Ticket ${countId} - Last Name`}
                                name="last_name"
                                placeholder="Last name"
                                value={values[countId]?.last_name || ''}
                                onChange={(evt) => {
                                    selectGuests(ticketId, countId, {
                                        last_name: evt.target.value,
                                    });
                                }}
                                type="text"
                                required
                            />
                        </S.Line>
                    );
                })}
        </S.Container>
    );
};

export const GuestForms = memo(GuestFormsContainer);
