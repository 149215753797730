import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';

export const Container = styled.div`
    margin-top: 20px;
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Full.heading2}
`;

export const Line = styled.div`
    display: grid;
    grid-template: auto / 1fr 1fr;
    grid-gap: 18px;
`;
