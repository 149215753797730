import styled, { css, DefaultTheme } from 'styled-components';
import { CircleArrow } from '@/shared/images/icons/CircleArrow';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';

export const Container = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    gap: 9px;
`;

interface ICircle extends DefaultTheme {
    disabled?: boolean;
}

const Circle = styled(CircleArrow)<ICircle>`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    fill: ${({ theme, disabled }) => (disabled ? theme.colors.grays.normal : Palette.gray.lighter)};
    background-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.blue.dark : theme.colors.extra.arrow};

    ${({ disabled, theme }) =>
        !disabled &&
        css`
            box-shadow: 0px 4px 23px rgba(16, 31, 46, 0.53);
            ${Screen.desktop} {
                :active,
                :hover {
                    fill: ${theme.colors.extra.arrowFill};
                    background-color: ${theme.colors.extra.arrowHover};
                    box-shadow: 0px 3.89px 23.3587px rgba(16, 31, 46, 0.53);
                }
            }
            ${Screen.mobile} {
                :active {
                    fill: ${theme.colors.extra.arrowFill};
                    background-color: ${theme.colors.extra.arrowHover};
                    box-shadow: 0px 3.89px 23.3587px rgba(16, 31, 46, 0.53);
                }
            }
        `}

    ${Screen.ipad} {
        height: 43px;
        width: 43px;
    }
    transition: 0.5s;
`;

export const Right = styled(Circle)`
    transform: scale(-1, 1);
`;

export const Left = styled(Circle)``;
