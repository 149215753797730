export const Facebook = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7025 11.059L12.8818 9.81158C12.8936 9.72762 12.8883 9.64193 12.8664 9.56034C12.8445 9.47875 12.8064 9.40318 12.7547 9.33881C12.703 9.27444 12.639 9.22278 12.5669 9.18734C12.4949 9.1519 12.4166 9.13353 12.3373 9.13348H10.8077V7.56205C10.8077 6.89784 11.1101 6.25214 12.0824 6.25214H12.5145C12.5872 6.25275 12.6593 6.23794 12.7266 6.20855C12.794 6.17917 12.8552 6.13579 12.9069 6.08093C12.9585 6.02607 12.9994 5.96081 13.0274 5.8889C13.0554 5.817 13.0697 5.73988 13.0697 5.66198V4.69922C13.0697 4.55517 13.0204 4.41612 12.9313 4.30826C12.8422 4.20041 12.7193 4.13121 12.5858 4.1137C12.1649 4.05841 11.7415 4.02827 11.3176 4.02344C9.52862 4.02344 8.35977 5.1806 8.35977 7.28665V9.13811H6.92301C6.77763 9.13811 6.63815 9.19965 6.53495 9.30932C6.43176 9.419 6.37321 9.56792 6.37207 9.72364V10.9711C6.37207 11.1276 6.43012 11.2777 6.53344 11.3884C6.63676 11.499 6.77689 11.5612 6.92301 11.5612H8.35977V16.8309C8.36034 16.9871 8.41863 17.1366 8.52189 17.2468C8.62515 17.3569 8.76496 17.4188 8.91071 17.4188H10.261C10.4068 17.4188 10.5466 17.3569 10.6499 17.2468C10.7531 17.1366 10.8114 16.9871 10.812 16.8309V11.5589H12.1623C12.2931 11.5578 12.4192 11.5069 12.5182 11.4153C12.6171 11.3237 12.6825 11.1974 12.7025 11.059V11.059Z"
                fill="white"
            />
        </svg>
    );
};
