import styled, { css, DefaultTheme } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius, borders } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { Transition } from '@/styles/Themes/KeyFrames';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import { RedCross } from '@/shared/images/icons/RedCross';

export const Wrapper = styled.div`
    position: relative;
    height: 50px;
    width: 100%;
`;

export const CommonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
`;

export const Container = styled.div<IWithIsActive>`
    width: 100%;
    height: 50px;
    background-color: ${cssVars.bg_white_blue};
    border-radius: ${radius.large};
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    position: absolute;

    ${({ isActive }) =>
        isActive &&
        css`
            border-radius: ${radius.normal};
            padding-bottom: 10px;
            height: auto;
            z-index: 1;
        `}
`;

export const Animation = styled.div`
    padding: 0 15px 15px 30px;
    margin-right: 15px;
    max-height: 170px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        height: 30px;
        background: ${Palette.gray.gray3};
        border-radius: 10px;
    }

    @keyframes rolldown {
        from {
            max-height: 0px;
        }

        to {
            max-height: 170px;
        }
    }
    animation-duration: 0.3s;
    animation-name: rolldown;
    animation-timing-function: ease-in-out;
`;

export const Title = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.medium}
    cursor: pointer;
    margin: 0;
    padding: 14px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
`;

export const TitleText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
`;

interface IWithIsActive extends DefaultTheme {
    isActive: boolean;
}

export const Arrow = styled(DropdownArrow)<IWithIsActive>`
    ${({ isActive }) => isActive && 'transform: rotate(-180deg);'}
    ${Transition.normal}
    position: absolute;
    right: 30px;
`;

export const Item = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.medium.medium}
    display: flex;
    justify-content: space-between;
    padding: 18px 0;
    border-top: ${borders.small} ${cssVars.bg_ash_darker};
    cursor: pointer;
    padding: 18px 16px;

    ${Screen.ipad} {
        ${Text.Ipad.normal.medium}
    }
    ${Screen.desktop} {
        ${Text.Desktop.normal.medium}
    }

    &:hover {
        border-radius: ${radius.small};
        color: ${Palette.green.normal};
        background-color: ${cssVars.bg_ash_blueDark};
        ${Transition.normal}
    }
    &:hover {
        *:last-child {
            color: ${Palette.green.normal};
        }
    }
`;

export const Code = styled.span`
    color: ${Palette.gray.light};
`;

export const Close = styled(CloseIcon)`
    background-color: ${cssVars.bg_gray4_dark2};
    padding: 6px;
    border-radius: ${radius.large};
    height: 22px;
    width: 22px;

    &:hover {
        padding: 4px;
    }
`;

export const CloseWrapper = styled.div`
    height: 22px;
    width: 22px;
    position: absolute;
    cursor: pointer;
    right: 60px;
    box-shadow: 0px 4px 24px ${cssVars.shadow_normal};
`;

export const SelectTicketType = styled.span`
    ${Text.Mobile.normal.semibold};
    display: flex;
    align-items: center;
    position: absolute;
    background: ${cssVars.bg_lightBg_darkBg};
    border-radius: ${radius.small};
    border: ${borders.small} ${cssVars.bg_gray4_dark2};
    color: ${Palette.state.error};
    height: 33px;
    padding: 8px 15px;
    box-shadow: ${cssVars.shadow_card_default};
    width: 144px;
    right: -20px;
    top: -3px;

    &:before {
        content: '';
        height: 8px;
        width: 8px;
        border-top: ${borders.small} ${cssVars.bg_gray4_dark2};
        border-right: ${borders.small} ${cssVars.bg_gray4_dark2};
        background: ${cssVars.bg_lightBg_darkBg};
        border-left: none;
        border-bottom: none;
        transform: rotate(135deg);
        position: absolute;
        right: 112px;
        top: 27px;
    }

    > span {
        z-index: 1;
        padding-right: 5px;
    }

    ${Screen.ipad} {
        ${Text.Ipad.small.semibold};
        right: 32%;

        &:before {
            left: 25px;
        }
    }

    ${Screen.desktop} {
        height: 40px;
        width: 44%;
        top: 38px;
        right: -200px;

        &:before {
            transform: rotate(225deg);
            top: 15px;
            left: -5px;
        }
    }
`;

export const RedCrossIcon = styled(RedCross)``;
