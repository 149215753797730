import styled, { ThemedStyledProps } from 'styled-components';
import { TTheme } from '@/styles/Themes/Theme.domain';
import { IAmountStyled } from './Amount.types';
import { paddings } from '@/styles/Themes/Metrics';
import { fonts } from '@/styles/Themes/Fonts';

export const StyledAmount = styled.p<ThemedStyledProps<IAmountStyled, TTheme>>`
    height: 100%;
    width: 100%;
    font: ${fonts.normal};
    text-align: 'center';
    padding: ${paddings.medium};
`;

export const StyledValue = styled.span<ThemedStyledProps<IAmountStyled, TTheme>>`
    font: ${fonts.xxLargeBold};
`;

export const StyledCurrentSymbol = styled.span(StyledValue);

export const StyledDollar = styled.span(StyledValue);

export const StyledCentWrapper = styled.span(StyledValue);

export const StyledSeparator = styled.span(StyledValue);

export const StyledCent = styled.span(StyledValue);
