import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { Palette } from '@/styles/Themes/Palette';
import { borders } from '@/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';

export const Wrapper = styled.div<{
    withPromo?: boolean;
}>`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: ${({ withPromo }) => (withPromo ? '66px' : '0')};

    ${Screen.ipad} {
        padding-top: ${({ withPromo }) => (withPromo ? '60px' : '0')};
    }
    ${Screen.desktop} {
        padding-top: ${({ withPromo }) => (withPromo ? '48px' : '0')};
    }
`;
export const HeaderWrapper = styled.div`
    background-color: ${Palette.blue.normal};
    height: 80px;
    display: flex;
    box-shadow: ${cssVars.shadow_normal};
    border-radius: 0px 0px 15px 15px;
`;
export const MainContent = styled.main<{
    withDesktopHeroImage: boolean;
    withMobileHeroImage: boolean;
}>`
    width: 100%;
    flex: 1;
    padding-top: ${({ withMobileHeroImage }) => (withMobileHeroImage ? '0' : '50px')};
    ${Screen.ipad} {
        padding-top: ${({ withMobileHeroImage }) => (withMobileHeroImage ? '0' : '65px')};
    }
    ${Screen.desktop} {
        padding-top: ${({ withDesktopHeroImage }) => (withDesktopHeroImage ? '0' : '65px')};
    }
`;

export const HeaderLogo = styled.div`
    display: block;
    margin: auto auto;
    width: 65%;
`;

export const SideBarMenu = styled.div`
    display: flex;
    background: ${Palette.blue.normal};
    box-shadow: ${cssVars.shadow_normal};
    border-radius: 10px;
    margin: auto 5%;
    padding: 4px 7px;
    &:hover {
        cursor: pointer;
    }
    > svg {
        height: 28px;
        width: 20px;
    }
`;

export const Divider = styled.div`
    opacity: 0.3;
    border: ${borders.small} ${Palette.gray.gray4};
    transform: rotate(180deg);
    flex: none;
    flex-grow: 0;
`;

export const RightSection = styled.div`
    display: flex;
    margin: auto;
    > button {
        > div {
            right: 10px;
            > svg {
                height: 30px;
                width: 30px;
                margin: 0 1em;
            }
        }
    }
`;
