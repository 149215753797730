import NextLink, { LinkProps } from 'next/link';
import { PropsWithChildren, ReactElement } from 'react';

type TLinkProps = PropsWithChildren<
    LinkProps & {
        onMouseEnter?: React.MouseEventHandler<Element>;
        onClick?: React.MouseEventHandler;
        href?: string;
    }
>;

export const Link = (props: TLinkProps): ReactElement => {
    return <NextLink {...props}>{props.children}</NextLink>;
};
