import { getPromocodeName } from '@/common/service/api/Promocodes/Promocode';
import { loadCookiePromocode, saveCookiePromocode } from '@/common/service/storage';
import { TRunToast } from '@/entities/Cart/domain/Cart.domain';

export const checkAndSavePromo = async (
    promo?: string | string[],
    runToast?: TRunToast
): Promise<string | undefined> => {
    const oldPromo = loadCookiePromocode();

    if (!promo && typeof promo !== 'string') {
        return oldPromo;
    }

    const newPromo = promo.toString().toLowerCase();

    if (newPromo === oldPromo) {
        return oldPromo;
    }

    const { name } = await getPromocodeName(newPromo);

    if (name) {
        saveCookiePromocode(newPromo);
        runToast?.('promocode', { promoName: name });

        return newPromo;
    }

    return undefined;
};
