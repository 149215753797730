import { TTheme } from 'src/styles/Themes/Theme.domain';
import styled, { css, FlattenSimpleInterpolation, ThemedStyledProps } from 'styled-components';
import { TButtonStyled } from './Button.types';
import Text from '@/styles/Themes/Text';
import { borders, filter, opacity, paddings, radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { cssVars } from '@/styles/Themes/cssVars';
import { buttonPresets } from '@/styles/Themes/LightTheme/Presets';

const getVariantCSS = (
    props: TButtonStyled & ThemedStyledProps<TButtonStyled, TTheme>
): FlattenSimpleInterpolation => {
    switch (props.variant) {
        case 'secondary':
            return css`
                background-color: ${cssVars.bg_green_dark3};
                color: ${Palette.white.normal};
                border: ${borders.small} ${Palette.green.normal};
                &:hover {
                    color: ${Palette.green.normal};
                    background-color: transparent;
                    box-shadow: ${cssVars.shadow_normal};
                }
            `;
        case 'transparent':
            return css`
                color: ${Palette.green.normal};
                background-color: transparent;
                border: ${borders.small} ${Palette.green.normal};
                &:hover {
                    border: ${borders.small} ${Palette.green.light};
                }
            `;
        case 'half':
            return css`
                color: ${Palette.white.normal};
                background: linear-gradient(90deg, #03d9b1 0%, #1cbbd9 100%);
                border-radius: ${radius.half};
                padding: ${paddings.small};
                border: ${borders.normal} ${Palette.white.normal};
            `;

        case 'dark':
            return css`
                background-color: ${Palette.gradient.light};
                color: ${Palette.green.normal};
                border: ${borders.small} ${Palette.green.normal};
                &:hover {
                    color: ${Palette.white.normal};
                    background-color: ${Palette.gradient.dark};
                    box-shadow: 0px 13px 28px rgba(29, 203, 192, 0.2),
                        0px 8px 16px rgba(29, 203, 192, 0.06);
                    filter: ${filter.brightness};
                    border: ${borders.none};
                    opacity: ${opacity.normal};
                }
            `;

        case 'none':
            return css`
                color: ${Palette.green.normal};
                background-color: transparent;
                border: ${borders.small} ${'transparent'};
                &:hover {
                    border: ${borders.small} ${Palette.green.light};
                }
            `;

        default:
            return css`
                border: none;
                max-height: 50px;
                background: ${Palette.gradient.light};
                color: ${Palette.white.normal};
                border: none;
                box-shadow: 0px 16px 28px rgba(29, 203, 192, 0.25),
                    0px 10px 16px rgba(29, 203, 192, 0.1);
                ${Text.Full.large.medium}
                border: none;
                &:hover {
                    background: ${Palette.gradient.dark};
                }

                ${props.disabled &&
                css`
                    background: ${cssVars.button_disabled};
                    cursor: not-allowed;
                    box-shadow: none;

                    &:hover {
                        background: ${cssVars.button_disabled};
                    }
                `}
            `;
    }
};

export const StyledButton = styled.button<ThemedStyledProps<TButtonStyled, TTheme>>`
    ${buttonPresets};
    padding: ${({ variant }) => (variant != 'half' ? paddings.medium : {})};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: ${({ variant }) => (variant != 'half' ? radius.button : {})};
    ${(props) => {
        return getVariantCSS(props);
    }}
`;

export default StyledButton;
