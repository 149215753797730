// Semi-axes of WGS-84 geoidal reference
const WGS84_a = 6378137.0; // Major semiaxis [m]
const WGS84_b = 6356752.3; // Minor semiaxis [m]

// Earth radius at a given latitude, according to the WGS-84 ellipsoid [m]
function WGS84EarthRadius(lat: number) {
    // http://en.wikipedia.org/wiki/Earth_radius
    const An = WGS84_a * WGS84_a * Math.cos(lat);
    const Bn = WGS84_b * WGS84_b * Math.sin(lat);
    const Ad = WGS84_a * Math.cos(lat);
    const Bd = WGS84_b * Math.sin(lat);
    return Math.sqrt((An * An + Bn * Bn) / (Ad * Ad + Bd * Bd));
}

// degrees to radians
export function Deg2rad(degrees: number) {
    return (Math.PI * degrees) / 180.0;
}

// radians to degrees
export function Rad2deg(radians: number) {
    return (180.0 * radians) / Math.PI;
}

export class MapPoint {
    lng: number;
    lat: number;
    constructor(lng: number, lat: number) {
        this.lng = lng;
        this.lat = lat;
    }
}

export class BoundingBox {
    minPoints: MapPoint;
    maxPoints: MapPoint;
    constructor(minPoints: MapPoint, maxPoints: MapPoint) {
        this.minPoints = minPoints;
        this.maxPoints = maxPoints;
    }
}

// 'halfSideInKm' is the half length of the bounding box you want in kilometers.
export function GetBoundingBox(mapPoint: { lat: number; lng: number }, halfSideInKm: number) {
    // Bounding box surrounding the point at given coordinates,
    // assuming local approximation of Earth surface as a sphere
    // of radius given by WGS84
    const lat = Deg2rad(mapPoint.lat);
    const lon = Deg2rad(mapPoint.lng);
    const halfSide = 1000 * halfSideInKm;

    // Radius of Earth at given latitude
    const radius = WGS84EarthRadius(lat);

    // Radius of the parallel at given latitude
    const pradius = radius * Math.cos(lat);

    const latMin = lat - halfSide / radius;
    const latMax = lat + halfSide / radius;
    const lonMin = lon - halfSide / pradius;
    const lonMax = lon + halfSide / pradius;

    return new BoundingBox(
        new MapPoint(Rad2deg(latMin), Rad2deg(lonMin)),
        new MapPoint(Rad2deg(latMax), Rad2deg(lonMax))
    );
}
