import { fonts } from '@/styles/Themes/Fonts';
import { borders, radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { TTheme } from 'src/styles/Themes/Theme.domain';
import styled, { ThemedStyledProps } from 'styled-components';
import { TChipStyled } from './Chips.types';

export const StyledChipContainer = styled.button<ThemedStyledProps<TChipStyled, TTheme>>`
    ${({ theme }) => theme.presets.chips};
    position: relative;
    border-radius: ${radius.button};
    border: ${({ theme }) => `${borders.small} ${theme.colors.shades.gray3}`};
    background: ${Palette.white.normal};
`;

export const StyledChipTextWrapper = styled.div<ThemedStyledProps<TChipStyled, TTheme>>`
    float: left;
    position: relative;
    left: 8%;
`;

export const StyledChipTitles = styled.span<ThemedStyledProps<TChipStyled, TTheme>>`
    display: block;
    font: ${fonts.normal};
    color: ${Palette.gray.gray1};
    max-width: 10%;
`;

export const StyledChipDesc = styled.span<ThemedStyledProps<TChipStyled, TTheme>>`
    display: block;
    font: ${fonts.normal};
    color: ${Palette.gray.gray2};
`;

export const StyledChipDate = styled.span<ThemedStyledProps<TChipStyled, TTheme>>`
    font: ${fonts.normal};
    color: ${Palette.gray.gray2};
    position: relative;
    float: right;
    right: 5%;
    margin: 12px 0 0 0;
`;

export const RefreshButton = styled.div<ThemedStyledProps<TChipStyled, TTheme>>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
`;
