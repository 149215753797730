/* eslint-disable react/no-unescaped-entities */
import Link from 'next/link';
import * as S from './DiscountWidget.styles';

export const DiscountWidgetSuccess = () => {
    return (
        <>
            <S.FormHeader>
                <S.FormTitle>Thank You!</S.FormTitle>
                <S.Description>Now that you're officially a Tripshock insider...</S.Description>
            </S.FormHeader>

            <S.SuccessInstructions>
                Please check your inbox to access your discount
            </S.SuccessInstructions>

            <S.SocialIconsWrapper data-test-id="Social_icons">
                <Link href="https://facebook.com/tripshockofficial" passHref>
                    <S.HugeSocialIcons data-test-id="Facebook">
                        <S.FacebookCircle>
                            <S.FacebookIcon />
                        </S.FacebookCircle>
                    </S.HugeSocialIcons>
                </Link>
                <Link href="https://www.instagram.com/tripshock/" passHref>
                    <S.HugeSocialIcons data-test-id="Instagram">
                        <S.InstagramCircle>
                            <S.InstagramIcon />
                        </S.InstagramCircle>
                    </S.HugeSocialIcons>
                </Link>
            </S.SocialIconsWrapper>
        </>
    );
};
