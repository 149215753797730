import { radius } from '@/styles/Themes/Metrics';
import styled, { keyframes } from 'styled-components';

export const SpinnerAnimation = ({ ...props }) => keyframes`
0%,
100% {
  box-shadow: ${props.theme.boxShadows.spinner0100};
}
12.5% {
  box-shadow: ${props.theme.boxShadows.spinner12};
}
25% {
  box-shadow: ${props.theme.boxShadows.spinner25};
}
37.5% {
  box-shadow: ${props.theme.boxShadows.spinner37};
}
50% {
  box-shadow: ${props.theme.boxShadows.spinner50};
}
62.5% {
  box-shadow: ${props.theme.boxShadows.spinner60};
}
75% {
  box-shadow: ${props.theme.boxShadows.spinner75};
}
87.5% {
  box-shadow: ${props.theme.boxShadows.spinner87};
}
`;

export const SpinnerWrapper = styled.div`
    border-radius: 15px;
    background-color: transparent;
    color: transparent;
    display: flex;
    justify-content: center;
`;

export const DotSpinner = styled.div`
    margin: 10px 0 10px 10px;
    width: 10px;
    width: 10px;
    height: 10px;
    border-radius: ${radius.small};
    background-color: transparent;
    color: transparent;
    animation: ${SpinnerAnimation} 1.5s infinite linear;
`;
