import { IInputSearchResultProps, ISearchOption } from '@/shared/SearchBar/types/index.types';
import { SearchOption } from '@/shared/SearchBar/ui/SearchOption/SearchOption';
import { memo, ReactElement, useRef, useEffect } from 'react';
import * as S from './InputSearch.styles';

const DestinationsInputSearchResultComponent = ({
    value,
    filterResults,
    chooseResult,
}: IInputSearchResultProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value) {
            const el = ref.current;
            const choosenEl = el?.querySelector('.selected');
            const choosenElPosition = (choosenEl as HTMLDivElement)?.offsetTop;

            if (choosenElPosition && el) {
                const scrollDistance = Math.max(0, choosenElPosition - 100);
                el.scrollTo({
                    top: scrollDistance,
                });
            }
        }
    }, [value]);

    const newData = filterResults
        .sort((a, b) => {
            if (a.counter === b.counter) {
                return a.label.localeCompare(b.label);
            } else {
                return a.counter.localeCompare(b.counter);
            }
        })
        .map((item) => ({
            ...item,
            label: item.label.split(',')[0].trim(),
        }));

    const splittedData = newData.reduce((acc, curr) => {
        if (curr.state) {
            return {
                ...acc,
                [curr.state]: [...(acc[curr.state] || []), curr],
            };
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);

    const states = Object.keys(splittedData);

    return (
        <>
            <S.ScrollResultContainer
                footer={null}
                ref={ref}
                className="scrollResult"
                onScroll={(e) => {
                    e.stopPropagation();
                }}
                onTouchMove={(e) => {
                    e.stopPropagation();
                }}
            >
                {states.map((item) => {
                    return (
                        <div key={item}>
                            <S.ResultTitle>{item}</S.ResultTitle>

                            <div>
                                {splittedData[item].map((result: ISearchOption) => (
                                    <S.ResultsItem
                                        key={result.value}
                                        onClick={() => chooseResult(result)}
                                        className={result.isSelected ? 'selected' : undefined}
                                    >
                                        <SearchOption
                                            label={result.label}
                                            counter={result.counter}
                                            selected={result.isSelected}
                                        />
                                    </S.ResultsItem>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </S.ScrollResultContainer>
        </>
    );
};

export const DestinationsInputSearchResult = memo(DestinationsInputSearchResultComponent);
