export const RecentIcon = () => {
    return (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.17532 0.492188C3.53024 0.492188 0.575317 3.44711 0.575317 7.09219C0.575317 10.7373 3.53024 13.6922 7.17532 13.6922C9.76614 13.6922 12.0071 12.1993 13.087 10.0297C13.2449 9.71249 13.1158 9.32729 12.7985 9.16937C12.4813 9.01145 12.0961 9.14061 11.9381 9.45786C11.0668 11.2084 9.26068 12.4089 7.17532 12.4089C4.239 12.4089 1.85865 10.0285 1.85865 7.09219C1.85865 4.15587 4.239 1.77552 7.17532 1.77552C9.63455 1.77552 11.704 3.44529 12.3114 5.713L11.275 4.95802C10.9886 4.74935 10.5872 4.8124 10.3786 5.09883C10.1699 5.38526 10.2329 5.78662 10.5194 5.99529L13.1968 7.94579C13.2368 7.97493 13.2927 7.96706 13.3231 7.928L15.3576 5.31385C15.5752 5.03419 15.525 4.63103 15.2453 4.41337C14.9656 4.19572 14.5625 4.24599 14.3448 4.52566L13.5831 5.50444C12.8722 2.6263 10.2731 0.492188 7.17532 0.492188Z"
                fill="#C4C4C4"
            />
        </svg>
    );
};
