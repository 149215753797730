import { memo } from 'react';
import { useSearchContext } from '@/common/app/contexts';
import { getDatesFormatted } from '@/shared/SearchBar/app/utils';
import { ISearchPreviewProps } from '@/shared/SearchBar/types/index.types';
import {
    Wrapper,
    SearchContainer,
    DestinationBlock,
    CategoryBlock,
    DateBlock,
    SearchButton,
    IconSearch,
} from './SearchPreview.styles';
import { ALL_EXPERIENCES } from '@/common/app/constants/constants';

const SearchPreviewComponent = ({
    openHandler,
    isOpenSearch,
    isHomepage,
    scrollEnd,
    transparent,
}: ISearchPreviewProps) => {
    const { selectedDestination, selectedCategory, selectedPartner, selectedDateRange } =
        useSearchContext();

    const { dateStartFormatted, dateEndFormatted, isSameMonth } =
        getDatesFormatted(selectedDateRange);

    return (
        <Wrapper
            isOpenSearch={isOpenSearch}
            isHomepage={isHomepage}
            scrollEnd={scrollEnd}
            transparent={transparent}
            onClick={openHandler}
        >
            <SearchContainer>
                {selectedDestination?.name ? (
                    <>
                        <DestinationBlock>{selectedDestination?.name}</DestinationBlock>
                        <CategoryBlock>
                            <>{`${
                                selectedCategory?.name || selectedPartner?.title || ALL_EXPERIENCES
                            }`}</>
                        </CategoryBlock>
                        <DateBlock>
                            {dateStartFormatted}
                            {isSameMonth ? '-' : <br />}
                            {dateEndFormatted}
                        </DateBlock>
                    </>
                ) : (
                    <DestinationBlock>Find your perfect adventure</DestinationBlock>
                )}
            </SearchContainer>
            <SearchButton>
                <IconSearch />
            </SearchButton>
        </Wrapper>
    );
};

export const SearchPreview = memo(SearchPreviewComponent);
