import styled, { css, DefaultTheme } from 'styled-components';
import { CircleArrow } from '@/shared/images/icons/CircleArrow';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';

export const Container = styled.div`
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    gap: 9px;
`;

interface ICircle extends DefaultTheme {
    disabled?: boolean;
}

const Circle = styled(CircleArrow)<ICircle>`
    height: 42px;
    width: 42px;
    border-radius: ${radius.large};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;

    ${({ disabled }) =>
        disabled
            ? css`
                  fill: ${Palette.gray.gray3};
                  background-color: ${cssVars.bg_ash_blueDark};
                  cursor: not-allowed;
              `
            : css`
                  fill: ${cssVars.bg_gray1_green};
                  background-color: ${cssVars.bg_white_blue};
                  box-shadow: 0px 4px 24px ${cssVars.shadow_normal};
                  ${Screen.desktop} {
                      :hover {
                          fill: ${Palette.white.normal};
                          background-color: ${Palette.green.normal};
                          box-shadow: 0px 5px 8px rgba(29, 203, 192, 0.1),
                              0px 8px 14px rgba(29, 203, 192, 0.2);
                      }
                  }
              `}

    ${Screen.desktop} {
        height: 36px;
        width: 36px;
    }
`;

export const Right = styled(Circle)`
    transform: scale(-1, 1);
`;

export const Left = styled(Circle)``;
