export const PromoCircle = () => {
    return (
        <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="120" height="120" rx="60" fill="var(--bg-ash-dark2)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.1061 39.7664C79.0069 39.6137 81.3775 42.0523 81.1427 44.9476L79.5955 64.0296C79.5035 65.1648 79.0107 66.2304 78.2053 67.0358L57.0822 88.1589C53.6456 91.5955 48.0738 91.5955 44.6371 88.1589L32.2024 75.7241C28.7658 72.2875 28.7658 66.7157 32.2024 63.279L53.2798 42.2016C54.1183 41.3632 55.2375 40.8647 56.4216 40.8024L76.1061 39.7664ZM79.5479 44.8183C79.7044 42.8881 78.1241 41.2624 76.1902 41.3642L56.5057 42.4002C55.7163 42.4417 54.9702 42.774 54.4112 43.333L33.3338 64.4104C30.522 67.2222 30.522 71.781 33.3338 74.5928L45.7685 87.0275C48.5803 89.8393 53.1391 89.8393 55.9509 87.0275L77.074 65.9044C77.6109 65.3675 77.9394 64.6571 78.0008 63.9003L79.5479 44.8183Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M86.7743 37.0822C83.9223 33.1977 78.4613 32.3606 74.5768 35.2126C70.6923 38.0646 69.8553 43.5257 72.7073 47.4102C72.9687 47.7663 72.892 48.267 72.5359 48.5285C72.1797 48.79 71.679 48.7132 71.4175 48.3571C68.0426 43.7603 69.0331 37.2979 73.6299 33.9229C78.2267 30.548 84.6891 31.5385 88.064 36.1353C90.802 39.8645 90.6662 44.8196 88.0657 48.3591C87.8041 48.7152 87.3034 48.7917 86.9473 48.5302C86.5913 48.2686 86.5147 47.7678 86.7763 47.4118C88.9747 44.4196 89.0867 40.2317 86.7743 37.0822Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.2715 57.3613L65.0218 61.1115C65.3342 61.424 65.3342 61.9305 65.0218 62.2429C64.7094 62.5553 64.2028 62.5553 63.8904 62.2429L60.1253 58.4778C60.1149 58.4674 60.1048 58.4567 60.095 58.4458C59.5041 57.786 58.6789 57.3858 57.8017 57.3314C56.9417 57.278 56.101 57.5612 55.4556 58.1173C54.9054 58.7636 54.6273 59.6023 54.6832 60.459C54.7402 61.3328 55.1402 62.1541 55.7978 62.7426C55.8088 62.7524 55.8195 62.7626 55.83 62.7731L57.864 64.8071C58.8254 65.676 59.4107 66.8865 59.4908 68.1778C59.5715 69.4771 59.1339 70.7526 58.2714 71.7232C58.2495 71.7479 58.226 71.7712 58.2012 71.7931C57.2289 72.6465 55.9564 73.0765 54.6621 72.9921C53.3759 72.9082 52.1713 72.3231 51.3059 71.365L47.5558 67.6149C47.2433 67.3025 47.2433 66.7959 47.5558 66.4835C47.8682 66.1711 48.3747 66.1711 48.6871 66.4835L52.4523 70.2486C52.4627 70.2591 52.4729 70.2698 52.4827 70.2809C53.0712 70.9384 53.8925 71.3385 54.7663 71.3955C55.623 71.4514 56.4617 71.1732 57.1081 70.6231C57.6642 69.9777 57.9473 69.1369 57.8939 68.2769C57.8395 67.3997 57.4393 66.5745 56.7795 65.9836C56.7686 65.9738 56.7579 65.9637 56.7476 65.9534L54.7136 63.9194C53.7555 63.054 53.1705 61.8494 53.0866 60.5632C53.0021 59.2689 53.4322 57.9964 54.2856 57.0242L54.8868 57.5519L54.3554 56.9539C55.326 56.0915 56.6015 55.6539 57.9008 55.7345C59.1921 55.8146 60.4026 56.3999 61.2715 57.3613Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.4363 69.2329C51.7487 69.5453 51.7487 70.0519 51.4363 70.3643L49.4078 72.3928C49.0954 72.7052 48.5888 72.7052 48.2764 72.3928C47.964 72.0804 47.964 71.5738 48.2764 71.2614L50.3049 69.2329C50.6173 68.9205 51.1239 68.9205 51.4363 69.2329Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.3196 56.3508C64.632 56.6633 64.632 57.1698 64.3196 57.4822L62.2911 59.5107C61.9787 59.8231 61.4721 59.8231 61.1597 59.5107C60.8473 59.1983 60.8473 58.6918 61.1597 58.3793L63.1882 56.3508C63.5006 56.0384 64.0072 56.0384 64.3196 56.3508Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
