import { ReactElement } from 'react';
import { TSingleArrow } from './LightArrows.domain';
import * as S from './LightArrows.styles';

export const RightArrow = ({ onClick, disabled, className }: TSingleArrow): ReactElement => {
    return (
        <div onClick={onClick} className={`RightArrow ${className}`}>
            <S.Right disabled={disabled} />
        </div>
    );
};

export default RightArrow;
