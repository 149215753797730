export const Ipad = {
    heading1: `
        font-weight: 600;
        font-size: 52px;
        line-height: 58px;
    `,
    heading2: `
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
    `,
    heading3: `
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
    `,
    heading4: `
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    `,
    large: {
        semibold: `
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
        `,
        medium: `
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        `,
        regular: `
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        `,
    },
    medium: {
        semibold: `
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        `,
        medium: `
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        `,
        regular: `
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        `,
    },
    normal: {
        semibold: `
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
        `,
        medium: `
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
        `,
        regular: `
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        `,
    },
    small: {
        semibold: `
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
        `,
        medium: `
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
        `,
        regular: `
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
        `,
    },
    caption: {
        semibold: `
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        `,
        medium: `
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        `,
    },
};
