import { ReactNode } from 'react';
import * as S from './Accordion.styles';
export interface IAccordionTab {
    title: string;
    content: ReactNode;
    id: string;
}

type AccordionProps = {
    tabs: IAccordionTab[];
    style?: React.CSSProperties;
    handleClickOnTab: (value: number) => void;
    indexOpen: number | null;
    withStars?: boolean;
};

export const Accordion = ({
    tabs,
    style,
    handleClickOnTab,
    indexOpen,
    withStars,
}: AccordionProps) => {
    const onClickHandler = (index: number) => {
        handleClickOnTab(index);

        if (index !== indexOpen) {
            setTimeout(() => {
                const activeTab = document.querySelector(`[data-index="${index}"]`);

                if (!activeTab) {
                    return;
                }

                const y = activeTab.getBoundingClientRect().top + window.scrollY;
                window.scroll({
                    top: y - 75,
                    behavior: 'smooth',
                });
            }, 100);
        }
    };

    return (
        <>
            {tabs.map(({ title, content, id }, index) => {
                const isOpen = indexOpen === index;
                return (
                    <S.Item key={index} style={style} id={id} data-index={index}>
                        <S.Title onClick={() => onClickHandler(index)}>
                            {title}
                            <S.Arrow isOpen={isOpen} />
                        </S.Title>
                        <S.Animation isOpen={isOpen}>
                            <S.Answer
                                indexOpen={indexOpen}
                                withStars={withStars}
                                data-test-id="Accordion-details"
                            >
                                {isOpen && content}
                            </S.Answer>
                        </S.Animation>
                    </S.Item>
                );
            })}
        </>
    );
};
