import { getLS, LocalStorageTypes, setLS } from '@/common/service/storage';
import { useEffect, useState } from 'react';
import * as S from './DiscountWidget.styles';
import { DiscountWidgetForm } from './DiscountWidgetForm';
import { DiscountWidgetSuccess } from './DiscountWidgetSuccess';

const getOpenCount = () => +(getLS(LocalStorageTypes.LS_DISCOUNT_WIDGET) || 0);

const setOpenCount = () => {
    const currentCount = getOpenCount();
    setLS(LocalStorageTypes.LS_DISCOUNT_WIDGET, +currentCount + 1);
};

export const DiscountWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (getOpenCount() >= 2) {
            return;
        }

        setTimeout(() => {
            setIsOpen(true);
            setOpenCount();
        }, 5000);
    }, []);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <S.Overlay open={isOpen}>
            <S.Modal>
                <S.ModalTop>
                    {/* <S.Logo /> */}
                    <S.CloseButton onClick={() => onClose()}>
                        <S.CloseButtonWithIcon />
                    </S.CloseButton>
                </S.ModalTop>
                <S.ModalBody>
                    {submitted ? (
                        <DiscountWidgetSuccess />
                    ) : (
                        <DiscountWidgetForm setSubmitted={setSubmitted} />
                    )}
                </S.ModalBody>
            </S.Modal>
        </S.Overlay>
    );
};
