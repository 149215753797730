import { ReactElement, ReactNode } from 'react';
import { IconContainer, IconSavedValue } from './IconButton.styles';
import { TIconButtonProps } from './IconButton.types';
import { Icons, getIcon } from 'src/shared/images/icons';
import CustomThemeProvider from '@/common/app/contexts/ThemeContext';

const selectIcon = (iconName: string) => {
    const findIcon = Icons.find((i) => i === iconName);
    return findIcon && (getIcon(findIcon) as unknown as ReactNode);
};

const IconButton = ({ items, styles, icon, onClick, ...props }: TIconButtonProps): ReactElement => {
    return (
        <CustomThemeProvider>
            <IconContainer
                data-testid="IconButton"
                items={items}
                styles={styles}
                onClick={onClick}
                {...props}
            >
                {items != 0 && <IconSavedValue items={items}>{items}</IconSavedValue>}
                {icon && selectIcon(icon)}
            </IconContainer>
        </CustomThemeProvider>
    );
};

export { IconButton };
