import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { CookiesTypes, getCS } from '../storage';
import { saveCSexpired } from '@/common/app/utils/affiliates';

const UTM_PARAMS = [
    'utm_campaign',
    'utm_source',
    'utm_target',
    'utm_medium',
    'campaign',
    'source',
    'target',
    'medium',
];
const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

export const useCRSParams = () => {
    const { query } = useRouter();

    useEffect(() => {
        if (query.crs && typeof query.crs === 'string') {
            const [launchDateSec, lifeTimeHrs] = query.crs.split('_');
            const today = new Date().getTime();
            const launchDate = +launchDateSec * 1000;

            if (launchDate && lifeTimeHrs && today - launchDate < ONE_WEEK) {
                const oldCRS = getCS(CookiesTypes.CS_CRS);

                if (launchDate !== oldCRS) {
                    const utmParams = UTM_PARAMS.reduce((result, param) => {
                        if (query[param]) {
                            result[param] = query[param];
                        }

                        return result;
                    }, {} as { [key: string]: string | string[] | undefined });

                    saveCSexpired(CookiesTypes.CS_CRS, launchDate, +lifeTimeHrs);
                    saveCSexpired(CookiesTypes.CS_CRS_UTM, utmParams, +lifeTimeHrs);
                }
            }
        }
    }, [query]);
};
