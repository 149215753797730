import { affiliatesInstance } from '@/common/service/api/Axios';
import { CookiesTypes, getCS, saveCS } from '@/common/service/storage';
//import { setCS } from '@/common/service/storage/CookieStorage/CookieStorage';
import { addHours, toDate } from 'date-fns';

const SEVEN_DAYS = 168;

type TAffParams = {
    utm_type?: string;
    utm_campaign?: string;
    utm_source?: string;
};

export type TCRSParams = {
    campaign?: string;
    source?: string;
    target?: string;
    medium?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_target?: string;
    utm_medium?: string;
    cartItemIds?: string;
};

type TAffData = TAffParams & { affCode: string };

export const createAffCode = (aff: string, path: string) =>
    `${aff}${path.split('/?')[0].replace(/\//g, '_')}`;

export const getAffData = (): TAffData | undefined => {
    const affCode = getCS(CookiesTypes.AFFILIATES_CODE) as string | undefined;
    if (!affCode) return;

    const data = getCS(CookiesTypes.AFFILIATES_DATA) as TAffParams | undefined;

    return {
        affCode,
        ...(data && { ...data }),
    };
};

export const saveAffData = (value: TAffData) => {
    const expires = toDate(addHours(new Date(), SEVEN_DAYS));
    const path = '/';

    saveCS(CookiesTypes.AFFILIATES_DATA, value, {
        path,
        expires,
    });
};

export const saveCSexpired = (key: string, value: string | object | number, hours: number) => {
    const expires = toDate(addHours(new Date(), hours));

    saveCS(key, value, {
        expires,
    });
};

export const isAffDataEqual = (current: TAffData, old?: TAffData) => {
    return (
        !!old &&
        old.affCode === current.affCode &&
        old.utm_type === current.utm_type &&
        old.utm_campaign === current.utm_campaign &&
        old.utm_source === current.utm_source
    );
};

export const affiliatesAddClick = (code: string, params: TAffParams) => {
    return affiliatesInstance.get(`/add/click/${code}`, { params });
};

// export const affiliatesSendCommission = (params: TCRSParams) => {
//     return affiliatesInstance.get(`/add/commission/crs`, { params });
// };

export const createAffId = () => {
    const affData = getCS(CookiesTypes.AFFILIATES_DATA) as TAffData | undefined;

    if (affData?.affCode) {
        const { affCode, ...rest } = affData;

        const utmParams = Object.entries(rest).map(([key, value]) => `${key}=${value}`);
        const sign = utmParams.length ? '?' : '';

        return affCode + sign + utmParams.join('&');
    }

    return '';
};

// export const checkAndTrackCRSParams = (cartItemIds: string, params?: TCRSParams) => {
//     const crsParams = params || (getCS(CookiesTypes.CS_CRS_UTM) as TCRSParams | undefined);

//     if (crsParams) {
//         affiliatesSendCommission({ cartItemIds, ...crsParams }).then(() => {
//             setCS(CookiesTypes.CS_CRS, '');
//             setCS(CookiesTypes.CS_CRS_UTM, '');
//         });
//     }
// };
