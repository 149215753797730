import { ModeSun } from '@/shared/images/icons/ModeSun';
import { ModeMoon } from '@/shared/images/icons/ModeMoon';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius, borders } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import styled, { css, DefaultTheme } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { Logo } from 'src/shared/images/Logo/Logo';
import { ArrowIcon } from '@/shared/images/icons/ArrowIcon';
import { HelpSign } from '@/shared/images/icons/HelpSign';
import { TalkToUs } from '@/shared/images/icons/TalkToUs';
import { Chat } from '@/shared/images/icons/Chat';
import { Transition } from '@/styles/Themes/KeyFrames';

interface IIsMoved extends DefaultTheme {
    isMoved: number;
}

export const Blur = styled.div<IIsMoved>`
    ${(props) =>
        props.isMoved <= 100
            ? ''
            : css`
                  background-color: rgba(0, 0, 0, 0.3);
                  position: fixed;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  z-index: 5;
              `};
`;

export const StyleSideMenuWrapper = styled.div`
    transition: all 0.5s ease-out;
    z-index: 4;
    box-shadow: 0px 11px 43px ${cssVars.shadow_normal};
    border-radius: 0px 15px 15px 0;
    position: fixed;
    height: 100%;
    overflow: hidden auto;
    background: ${cssVars.bg_white_blue};
    padding: 24px 36px 45px;
    width: 325px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        margin: 10px 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
    @keyframes slideon {
        from {
            left: -50vw;
        }

        to {
            left: 0;
        }
    }

    animation-duration: 0.3s;
    animation-name: slideon;
    animation-timing-function: ease;

    ${Screen.ipad} {
        width: 460px;
    }
    ${Screen.desktop} {
        width: 344px;
    }
`;

export const SwipeButton = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
    width: 6px;
    height: 150px;
    background-color: ${cssVars.bg_gray4_dark2};
    border-radius: 7px;
`;

export const IconsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${borders.small} ${cssVars.bg_gray4_dark2};
    padding-bottom: 16px;
`;

export const Logotype = styled(Logo)`
    cursor: pointer;
`;

export const ArrowButton = styled(ArrowIcon)`
    cursor: pointer;

    &:hover {
        *:first-child {
            fill: ${Palette.green.light};
        }
    }
`;

export const LinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: fit-content;
    padding: 20px 0;
    border-bottom: ${borders.small} ${cssVars.bg_gray4_dark2};
    ${Text.Mobile.heading4.normal};
`;

interface ILinkItem extends DefaultTheme {
    items?: number;
}

export const LinkItem = styled.a<ILinkItem>`
    color: ${cssVars.fg_gray1_white};
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 18px;
    height: 40px;

    &:hover {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: ${radius.medium};
        transition: ${Transition.normal};
    }

    ${({ items }) =>
        items &&
        css`
            &::after {
                content: '${items}';
                position: absolute;
                right: 18px;
                top: 11px;
                background-color: ${Palette.green.normal};
                color: ${Palette.white.normal};
                border-radius: ${radius.medium};
                ${Text.Full.normal.medium}
                padding: 0 7px;
            }
        `}

    ${Screen.ipad} {
        height: 50px;
    }
    ${Screen.desktop} {
        height: 45px;
    }
`;

export const LocalSuportItems = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.regular}
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    height: 40px;

    &:hover {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: ${radius.medium};
        transition: ${Transition.normal};
    }
    ${Screen.ipad} {
        height: 50px;
    }
    ${Screen.desktop} {
        height: 45px;
    }
`;

export const TalkToUsIcon = styled(TalkToUs)`
    margin-right: 16px;
`;

export const ChatIcon = styled(Chat)`
    margin-right: 16px;
`;

export const HelpIcon = styled(HelpSign)`
    margin-right: 16px;
`;

export const ColorTheme = styled.div`
    display: flex;
    align-items: center;
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.heading4.regular};
    cursor: pointer;
    user-select: none;
    padding: 20px 0;
    border-bottom: ${borders.small} ${cssVars.bg_gray4_dark2};
    margin-bottom: 30px;
`;

export const ListLink = styled.a`
    display: flex;
    justify-content: center;
    color: ${Palette.green.normal};
    ${Text.Full.large.regular}
    margin-top: 14px;

    &:hover {
        color: ${Palette.green.light};
    }
`;
interface IIsDark {
    isDark: boolean;
}

export const Toggle = styled.div<IIsDark>`
    background-color: ${({ isDark }) => (isDark ? Palette.green.normal : Palette.gray.gray4)};
    height: 26px;
    width: 48px;
    border-radius: 40px;
    display: flex;
    justify-content: ${({ isDark }) => (isDark ? 'flex-end' : 'flex-start')};
    margin-left: 10px;
`;

export const SwitchToLight = styled(ModeMoon)`
    width: 22px;
    height: 22px;
    background-color: ${Palette.white.normal};
    border-radius: 100%;
    margin: 2px;
    padding: 5px;
`;

export const SwitchToDark = styled(ModeSun)`
    width: 22px;
    height: 22px;
    background-color: ${Palette.white.normal};
    border-radius: 100%;
    margin: 2px;
    padding: 4px;
`;

export const Bottom = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
`;
