export const FooterPhone = (props: { className?: string }) => {
    return (
        <svg
            className={`FooterPhone ${props.className}`}
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="0.5" width="40" height="40" rx="20" fill="var(--svg-green-dark2)" />
            <path
                d="M23.7376 28.407C21.0233 28.407 17.9799 27.1818 15.6769 24.8132C13.8673 23.0162 12.6336 20.6475 12.2223 18.3605C11.811 15.8285 12.3868 13.6231 13.8673 12.1529C14.0318 11.9896 14.2786 11.8262 14.5253 11.7445C14.6899 11.6628 14.9366 11.6628 15.1834 11.6628C15.4301 11.6628 15.6769 11.8262 15.9236 11.9079C16.1704 11.9896 16.3349 12.2346 16.4171 12.4796L18.6379 15.4201C18.967 15.9101 19.0492 16.4002 18.967 16.8903C18.967 17.3804 18.7202 17.8704 18.3089 18.2788L18.0622 18.5239C17.7332 18.8506 17.4864 19.3407 17.4864 19.9124C17.4864 20.4025 17.6509 20.8926 18.0622 21.301L19.296 22.5261C20.0362 23.2613 21.27 23.2613 22.0103 22.5261L22.257 22.2811C22.586 21.9544 23.0796 21.7094 23.5731 21.6277C24.0666 21.546 24.5601 21.7094 25.0536 21.9544L28.0969 24.2414C28.2614 24.3231 28.4259 24.4864 28.5904 24.7315C28.7549 24.9765 28.8372 25.2215 28.8372 25.4666C28.8372 25.7116 28.8372 25.9567 28.7549 26.2017C28.6727 26.4467 28.5082 26.6918 28.3437 26.8551C27.1922 27.8353 25.5471 28.407 23.7376 28.407ZM15.0189 12.888C14.9366 12.888 14.9366 12.888 14.8544 12.888C14.7721 12.888 14.7721 12.9697 14.6899 12.9697C13.4561 14.1132 13.0448 15.9918 13.3738 18.1155C13.7028 20.2391 14.8544 22.3628 16.4994 23.9147C19.954 27.3452 25.0536 28.162 27.5212 25.7116C27.6034 25.6299 27.6034 25.6299 27.6034 25.5483C27.6034 25.4666 27.6034 25.3849 27.6034 25.3849C27.6034 25.3032 27.6034 25.2215 27.5212 25.2215C27.5212 25.1399 27.4389 25.1399 27.3567 25.0582L24.3956 22.8529C24.2311 22.7712 23.9843 22.6895 23.7376 22.7712C23.4908 22.7712 23.3263 22.9345 23.0796 23.0979L22.8328 23.3429C21.599 24.4864 19.5427 24.4864 18.3912 23.3429L17.1574 22.1177C16.6639 21.4643 16.3349 20.6475 16.3349 19.8307C16.3349 19.0139 16.6639 18.1972 17.2397 17.6254L17.4864 17.3804C17.6509 17.217 17.7332 16.972 17.8154 16.7269C17.8154 16.4819 17.8154 16.2369 17.6509 16.0735L15.4301 13.1331C15.3479 13.0514 15.2656 12.9697 15.2656 12.9697C15.1834 12.888 15.1011 12.888 15.0189 12.888Z"
                fill="var(--svg-white-green)"
            />
        </svg>
    );
};
