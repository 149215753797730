import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { radius, borders } from 'src/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';

export const StyledPhoneInput = styled(PhoneInput)`
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    border-radius: ${radius.large};
    background: ${cssVars.bg_white_dark3};
    color: ${cssVars.fg_gray2_gray3};
    padding: 0px 25px 0px 20px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    position: static !important;

    &&& {
        input {
            border: none;
            background: ${cssVars.bg_white_dark3};
            color: ${cssVars.fg_gray2_gray3};
        }
    }

    > div.flag-dropdown {
        border: none;
        background: ${cssVars.bg_white_dark3} !important;
        top: 1px;
        bottom: 1px;
    }

    .flag-dropdown .selected-flag {
        background: ${cssVars.bg_white_dark3} !important;
        :hover {
            background: ${cssVars.bg_white_dark3} !important;
        }
    }

    .country-list .country {
        background: ${cssVars.bg_white_dark3} !important;
        &:hover,
        &.highlight {
            background: ${cssVars.bg_ash_dark2} !important;
        }
    }

    > div.flag-dropdown ul.country-list {
        background: ${cssVars.bg_white_dark3};
        border-radius: ${radius.small};
        ::-webkit-scrollbar {
            width: 6px;
        }
        ::-webkit-scrollbar-track {
            background-color: ${cssVars.bg_ash_blueDark};
            border-radius: 10px;
            margin: 10px 0;
        }
        ::-webkit-scrollbar-thumb {
            height: 30px;
            background: ${Palette.gray.gray3};
            border-radius: 10px;
        }
    }

    ${Screen.desktop} {
        &&& {
            width: 100%;
        }
        height: 50px;
    }
`;
