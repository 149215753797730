import styled, { DefaultTheme, css } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';

interface Wrap extends DefaultTheme {
    image: string;
    withMobileHeroImage: boolean;
    isHomePage: boolean;
    noSearch?: boolean;
}

export const Wrapper = styled.header<Wrap>`
    display: ${({ withMobileHeroImage, isHomePage }) =>
        withMobileHeroImage || isHomePage ? 'flex' : 'none'};
    width: 100%;
    min-height: 425px;
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0) 120%),
        ${({ isHomePage, image }) => (isHomePage ? `${cssVars.main_background}` : `url(${image})`)}
            no-repeat;
    background-size: cover;
    background-position: 55% 50px;
    padding-top: 50px;
    margin-bottom: ${({ noSearch }) => (noSearch ? '0' : '125px')};
    align-items: center;
    justify-content: center;
    ${Screen.ipad} {
        padding-top: 65px;
        background-position: center 65px;
    }
    ${Screen.desktop} {
        display: flex;
        background-position: center;
        min-height: ${({ isHomePage }) => (isHomePage ? '700px' : '500px')};
        margin-bottom: 0;
        padding-bottom: 130px;
    }

    ${({ noSearch }) =>
        noSearch &&
        css`
            min-height: 260px;
        `}
`;

export const HeroContent = styled.div`
    padding-top: 36px;
    ${Screen.ipad} {
        padding-top: 64px;
    }

    ${Screen.desktop} {
        padding-top: 0;
    }
`;

export const Title = styled.div`
    ${Text.Full.heading1}
    color: ${Palette.white.normal};
    text-align: center;
    width: 100%;
    max-width: 72%;
    min-width: 280px;
    margin: 0 auto 16px;

    ${Screen.ipad} {
        margin: 0 auto 28px;
        min-width: 460px;
    }

    ${Screen.desktop} {
        max-width: 910px;
        margin: 10px auto 25px;
    }
`;

export const Subtitle = styled.h3`
    ${Text.Full.heading3}
    color: ${Palette.white.normal};
    text-align: center;
    margin: 0 auto 24px;

    ${Screen.ipad} {
        margin: 0 auto 72px;
        max-width: 610px;
    }
    ${Screen.desktop} {
        max-width: 910px;
        margin: 0 auto 42px;
    }
`;

export const SubtitleH1 = styled.h1`
    ${Text.Full.heading3}
    color: ${Palette.white.normal};
    text-align: center;
    margin: 0 auto 24px;

    ${Screen.ipad} {
        margin: 0 auto 72px;
        max-width: 610px;
    }
    ${Screen.desktop} {
        max-width: 910px;
        margin: 0 auto 42px;
    }
`;

export const SearchContainer = styled.div<{ mobileNoSearch?: boolean }>`
    background-color: ${cssVars.bg_white_dark3};
    padding: 30px 20px;
    box-shadow: ${cssVars.shadow_card_default};
    border-radius: ${radius.small};
    width: 100%;
    margin-bottom: -80px;

    ${Screen.ipad} {
        margin-bottom: -100px;
        padding: 30px;
    }

    ${Screen.desktop} {
        margin: 0 auto;
        max-width: 910px;
        border-radius: ${radius.large};
        padding: 0;
        height: 60px;
    }

    ${({ mobileNoSearch }) =>
        mobileNoSearch &&
        css`
            display: none;

            ${Screen.desktop} {
                display: block;
            }
        `}
`;
