export const Star = () => {
    return (
        <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.2541 3.73977C10.1854 3.46275 9.91084 3.18573 9.5676 3.18573L7.02769 2.83946L5.86069 0.484785C5.7234 0.207765 5.44882 0 5.17423 0C4.831 0 4.55641 0.207765 4.41912 0.484785L3.25213 2.83946L0.712207 3.18573C0.368975 3.25499 0.163035 3.46275 0.0257425 3.73977C-0.042904 4.01679 0.025742 4.36307 0.231681 4.57083L2.08514 6.37146L1.67326 9.00315C1.60461 9.34943 1.7419 9.62645 2.01649 9.83421C2.29108 10.042 2.63431 10.042 2.9089 9.90347L5.24287 8.65688L7.57686 9.90347C7.71415 9.97272 7.85144 9.97272 7.98873 9.97272C8.12603 9.97272 8.33197 9.90347 8.46926 9.83421C8.74385 9.62645 8.88114 9.34943 8.81249 9.00315L8.40061 6.44072L10.2541 4.64009C10.3227 4.36307 10.3914 4.08605 10.2541 3.73977Z"
                fill="#F2B718"
            />
        </svg>
    );
};
