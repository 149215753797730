import { ReactElement, useCallback } from 'react';
import LeftArrow from './LeftArrow';
import { TDarkArrowsProps } from './LightArrows.domain';
import * as S from './LightArrows.styles';
import RightArrow from './RightArrow';

export const LightArrows = ({
    onLeft,
    onRight,
    leftOff,
    rightOff,
}: TDarkArrowsProps): ReactElement => {
    const left = useCallback(() => {
        if (!leftOff) {
            onLeft();
        }
    }, [leftOff, onLeft]);

    const right = useCallback(() => {
        if (!rightOff) {
            onRight();
        }
    }, [rightOff, onRight]);

    return (
        <S.Container>
            <LeftArrow onClick={left} disabled={leftOff} />
            <RightArrow onClick={right} disabled={rightOff} />
        </S.Container>
    );
};

export default LightArrows;
