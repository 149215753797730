import styled, { css, DefaultTheme } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { Transition } from '@/styles/Themes/KeyFrames';
import { borders } from '@/styles/Themes/Metrics';

export const Wrapper = styled.div`
    position: relative;
    height: 50px;
    width: 100%;

    ${Screen.desktop} {
        width: 250px;
    }
`;

export const Container = styled.div<IWithIsActive>`
    width: 100%;
    background-color: ${cssVars.bg_white_blue};
    border-radius: ${radius.large};
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    position: absolute;
    z-index: 1;
    overflow: hidden;

    ${({ isActive }) =>
        isActive &&
        css`
            border-radius: ${radius.normal};
            padding: 0 0 10px;
            z-index: 2;
        `}
`;

export const Animation = styled.div`
    overflow: hidden;
    @keyframes rolldown {
        from {
            max-height: 0px;
        }

        to {
            max-height: 300px;
        }
    }
    animation-duration: 0.3s;
    animation-name: rolldown;
    animation-timing-function: ease-in-out;
    max-height: 300px;
    margin: 0 10px 0 0;
    overflow-y: scroll;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        height: 30px;
        background: ${Palette.gray.gray3};
        border-radius: 10px;
    }
`;

export const Title = styled.h4`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.large.medium}
    cursor: pointer;
    margin: 0;
    padding: 12px 27px 12px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Screen.ipad} {
        ${Text.Ipad.large.medium}
    }
    ${Screen.desktop} {
        padding: 12px 20px;
        ${Text.Desktop.medium.medium}
    }
`;

interface IWithIsActive extends DefaultTheme {
    isActive: boolean;
}

export const Arrow = styled(DropdownArrow)<IWithIsActive>`
    ${({ isActive }) => isActive && 'transform: rotate(-180deg);'};
    ${Transition.normal};
`;

export const Item = styled.a`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.medium.medium}
    display: flex;
    justify-content: space-between;
    padding: 18px 0;
    margin: 0 40px;
    border-top: 1px solid ${cssVars.bg_ash_darker};
    cursor: pointer;

    ${Screen.ipad} {
        ${Text.Ipad.normal.medium}
    }
    ${Screen.desktop} {
        ${Text.Desktop.normal.medium}
    }

    &:hover {
        margin: 0 20px;
        padding: 18px 20px;
        border-radius: ${radius.small};
        color: ${Palette.green.normal};
        background-color: ${cssVars.bg_ash_blueDark};
        ${Transition.normal}
    }
    &:hover {
        *:last-child {
            color: ${Palette.green.normal};
        }
    }
`;

export const Code = styled.span`
    color: ${Palette.gray.light};
`;
