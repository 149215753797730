export const TalkToUs = (props: { className?: string }) => {
    return (
        <svg
            className={`TalkToUs ${props.className}`}
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.117188"
                y="0.517578"
                width="40"
                height="40"
                rx="20"
                fill="var(--bg-ash-dark3)"
            />
            <path
                d="M23.8548 28.4249C21.1404 28.4249 18.0971 27.1997 15.7941 24.831C13.9845 23.034 12.7507 20.6653 12.3395 18.3783C11.9282 15.8463 12.504 13.641 13.9845 12.1707C14.149 12.0074 14.3958 11.844 14.6425 11.7623C14.807 11.6807 15.0538 11.6807 15.3006 11.6807C15.5473 11.6807 15.7941 11.844 16.0408 11.9257C16.2876 12.0074 16.4521 12.2524 16.5343 12.4975L18.7551 15.4379C19.0841 15.928 19.1664 16.418 19.0841 16.9081C19.0841 17.3982 18.8374 17.8883 18.4261 18.2967L18.1794 18.5417C17.8504 18.8684 17.6036 19.3585 17.6036 19.9302C17.6036 20.4203 17.7681 20.9104 18.1794 21.3188L19.4132 22.544C20.1534 23.2791 21.3872 23.2791 22.1275 22.544L22.3742 22.2989C22.7032 21.9722 23.1967 21.7272 23.6903 21.6455C24.1838 21.5638 24.6773 21.7272 25.1708 21.9722L28.2141 24.2592C28.3786 24.3409 28.5431 24.5043 28.7076 24.7493C28.8721 24.9943 28.9544 25.2394 28.9544 25.4844C28.9544 25.7294 28.9544 25.9745 28.8721 26.2195C28.7899 26.4646 28.6254 26.7096 28.4609 26.873C27.3093 27.8531 25.6643 28.4249 23.8548 28.4249ZM15.136 12.9058C15.0538 12.9058 15.0538 12.9058 14.9715 12.9058C14.8893 12.9058 14.8893 12.9875 14.807 12.9875C13.5733 14.131 13.162 16.0096 13.491 18.1333C13.82 20.257 14.9715 22.3806 16.6166 23.9325C20.0712 27.363 25.1708 28.1798 27.6384 25.7294C27.7206 25.6478 27.7206 25.6478 27.7206 25.5661C27.7206 25.4844 27.7206 25.4027 27.7206 25.4027C27.7206 25.321 27.7206 25.2394 27.6384 25.2394C27.6384 25.1577 27.5561 25.1577 27.4739 25.076L24.5128 22.8707C24.3483 22.789 24.1015 22.7073 23.8548 22.789C23.608 22.789 23.4435 22.9524 23.1967 23.1157L22.95 23.3608C21.7162 24.5043 19.6599 24.5043 18.5084 23.3608L17.2746 22.1356C16.7811 21.4821 16.4521 20.6653 16.4521 19.8486C16.4521 19.0318 16.7811 18.215 17.3569 17.6432L17.6036 17.3982C17.7681 17.2348 17.8504 16.9898 17.9326 16.7448C17.9326 16.4997 17.9326 16.2547 17.7681 16.0913L15.5473 13.1509C15.4651 13.0692 15.3828 12.9875 15.3828 12.9875C15.3006 12.9058 15.2183 12.9058 15.136 12.9058Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
