const ErrorMessage = (props: { className?: string }) => {
    return (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`UFO ${props.className}`}
        >
            <circle cx="9" cy="8.5" r="8.25" fill="#EC4652" />
            <path
                d="M8.90729 13.5417C9.27396 13.5417 9.64063 13.2667 9.64063 12.9001C9.64063 12.4417 9.27396 12.1667 8.90729 12.1667C8.54062 12.1667 8.26562 12.4417 8.26562 12.8084C8.26562 13.1751 8.54062 13.5417 8.90729 13.5417Z"
                fill="white"
            />
            <path
                d="M8.08301 3.91675H9.73301L9.45801 11.0667H8.35801L8.08301 3.91675Z"
                fill="white"
            />
        </svg>
    );
};

export default ErrorMessage;
