import { useRef, useState } from 'react';
import { DropdownProps } from './Dropdown.types';
import * as S from './Dropdown.styles';
import { useOnClickOutside } from '@/common/service/hooks';

export const Dropdown = ({
    label,
    value,
    options,
    onChange,
    placeholder,
    undefinedOption,
    color,
    id,
}: DropdownProps) => {
    const [isOpen, setOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => setOpen(false));

    const dropdownOptions =
        value && undefinedOption
            ? [...options]
            : [{ label: placeholder, value: undefined }, ...options];

    const labelSelected = dropdownOptions.find((option) => option.value === value)?.label;

    return (
        <S.Dropdown>
            {label && <S.Label>{label}</S.Label>}
            <S.Control
                id={id}
                isOpen={isOpen}
                onFocus={() => setTimeout(() => setOpen(true), 200)}
                onBlur={() => setTimeout(() => setOpen(false), 200)}
                onClick={() => setOpen(!isOpen)}
                ref={ref}
                color={color}
            >
                <S.DropdownHeader>
                    <S.MenuFirstItem>{labelSelected ?? placeholder ?? 'Select...'}</S.MenuFirstItem>
                    <S.IndicatorContainer onClick={() => setOpen(false)} isOpen={isOpen} />
                </S.DropdownHeader>

                <S.DropdownItems isOpen={isOpen}>
                    {dropdownOptions.map((option) => (
                        <S.MenuItem
                            key={option.value}
                            data-type={option.value}
                            selected={value === option.value}
                            onClick={() => {
                                onChange(option.value);
                            }}
                        >
                            {option.label}
                        </S.MenuItem>
                    ))}
                </S.DropdownItems>
            </S.Control>
        </S.Dropdown>
    );
};
