const DEV_CONTACT_FLOW_ID = '2b8d22cf-2ba8-4207-8a9a-a8f04b6ea408';
const DEV_INSTANCE_ID = '51563b74-fc4f-4c8e-9e5e-772deeac1156';
const DEV_QUEUE_ID = 'f6c0610a-235c-4ffd-a5e4-cf1c8575c637';

const PROD_COMMON_CONTACT_FLOW_ID = 'b311b038-e211-4ab3-8b87-00984d60e9b1';
const PROD_COMMON_INSTANCE_ID = '32f1f200-1b30-454a-8f52-0441cbb4d340';
const PROD_COMMON_QUEUE_ID = '299bada8-6344-436f-80a8-877c6267a59b';

type TContactFlow = {
    ContactFlowId: string;
    InstanceId: string;
    QueueId: string;
};

type TTalkToUsBodyParams = {
    Reservation: TContactFlow;
    Options: TContactFlow;
    ActivityQuestions: TContactFlow;
    baseURL: string;
};

export const getBodyParams = (): TTalkToUsBodyParams => {
    const isDev = process.env.BUILD_ENV === 'staging' || process.env.BUILD_ENV === 'dev';

    const DevConfig = {
        ContactFlowId: DEV_CONTACT_FLOW_ID,
        InstanceId: DEV_INSTANCE_ID,
        QueueId: DEV_QUEUE_ID,
    };

    if (isDev) {
        return {
            Reservation: DevConfig,
            Options: DevConfig,
            ActivityQuestions: DevConfig,
            baseURL: 'https://y0nncpqj0h.execute-api.us-east-1.amazonaws.com/staging/',
        };
    }

    const ProdConfig = {
        InstanceId: PROD_COMMON_INSTANCE_ID,
        ContactFlowId: PROD_COMMON_CONTACT_FLOW_ID,
        QueueId: PROD_COMMON_QUEUE_ID,
    };

    return {
        Reservation: {
            ...ProdConfig,
            ContactFlowId: 'ca31c473-9788-4725-9b33-782586a4ed6f',
            QueueId: 'edb390b0-9881-4b19-8dc1-0cabe3f0e3ea',
        },
        Options: {
            ...ProdConfig,
        },
        ActivityQuestions: {
            ...ProdConfig,
        },
        baseURL: 'https://88fjt7qdz8.execute-api.us-east-1.amazonaws.com/prod/',
    };
};

export const getBodyParamsByQuestion = (questionType: string) => {
    const { Reservation, Options, ActivityQuestions } = getBodyParams();

    if (questionType === 'Options') {
        return Options;
    }

    if (questionType === 'ActivityQuestions') {
        return ActivityQuestions;
    }

    return Reservation;
};

export const testInstanceId = getBodyParams().Reservation.InstanceId;
