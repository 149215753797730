import { cssVars } from '@/styles/Themes/cssVars';
import styled, { css } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { borders, radius } from '@/styles/Themes/Metrics';
import { RecentIcon } from '@/shared/images/icons/RecentIcon';

interface IExpanded {
    isExpandible?: boolean | null;
}

export const CollapsedContainer = styled.div<IExpanded>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: ${radius.large};
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    background-color: ${cssVars.bg_white_blueDark};
    cursor: pointer;

    ${Screen.desktop} {
        flex: 1;
        height: 100%;
        max-height: 35px;
        max-width: 35%;
        border: none;
        ${({ isExpandible }) =>
            isExpandible !== null &&
            css`
                padding: 3px 4px 3px 10px;
            `}
    }
`;

export const CollapsedLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px;
    text-align: left;
    flex: 1;

    ${Screen.desktop} {
        flex-direction: row;
        align-items: center;
    }
`;

export const CollapsedRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`;

export const CollapsedDate = styled.div<{ isSameMonth?: boolean }>`
    ${Text.Desktop.small.regular}
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.desktop} {
        flex: none;
        width: 100%;
        line-height: 12px;
    }
`;

export const RecentDestination = styled.div`
    color: ${cssVars.fg_gray1_white};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: none;
    width: 90%;
    ${Screen.desktop} {
        ${Text.Desktop.medium.medium}
        width: 50%;
    }
`;

export const RecentCategory = styled.div`
    flex: none;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    ${Text.Desktop.medium.regular}
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.desktop} {
        width: 50%;
    }
`;

export const RecentButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 0 20px;
    width: 100%;
    border-radius: ${radius.large};
    background-color: ${cssVars.bg_white_blueDark};

    ${Screen.desktop} {
        height: 35px;
        padding: 0 12px;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const RecentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    ${Screen.desktop} {
        border-top: ${borders.small} ${Palette.dark.dark2};
        margin-top: 27px;
        padding-top: 27px;
        flex-direction: row;
        flex: 1;
    }
`;

export const RecentIconWrapper = styled(RecentIcon)`
    flex: none;
    min-width: 15px;
    min-height: 13px;
`;
