import { ReactElement, memo, ReactNode, useState } from 'react';
import * as S from './Tooltip.styles';

type TooltipProps = {
    children: ReactNode;
    description: ReactNode;
    inCart: boolean;
};

//const FREEZ_TIME = 1000;

export const TooltipContainer = ({ children, description, inCart }: TooltipProps): ReactElement => {
    const [show, setShow] = useState(false);
    //const [freez, setFreez] = useState(false);

    // const clickEvent = (event: { stopPropagation: () => void; preventDefault: () => void }) => {
    //     event.stopPropagation();
    //     event.preventDefault();
    //     if (!freez) {
    //         setShow((prev) => {
    //             if (!prev) {
    //                 setFreez(true);
    //                 setTimeout(() => setFreez(false), FREEZ_TIME);
    //             }

    //             return !show;
    //         });
    //     }
    // };

    return (
        <S.Container
            onTouchEnd={() => setShow((prev) => !prev)}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            {show && description && <S.Title inCart={inCart}>{description}</S.Title>}
            {children}
        </S.Container>
    );
};

export const Tooltip = memo(TooltipContainer);
