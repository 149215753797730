import { Desktop } from './Desktop';
import { Ipad } from './Ipad';
import { Mobile } from './Mobile';
import Full from './Full';

const Text = {
    Desktop,
    Ipad,
    Mobile,
    Full,
};

export default Text;
