import { ReactElement } from 'react';
import { CloseIcon } from 'src/shared/images/icons/CloseIcon';
import { TModalProps } from './Modal.types';
import * as S from './Modal.styles';

export const Modal = ({ open, onClose, title, children, ...props }: TModalProps): ReactElement => {
    return (
        <S.Overlay data-testid="modal-overlay" open={open} onClick={onClose}>
            <S.Modal {...props}>
                <S.ModalTop>
                    <S.ModalTitle>{title}</S.ModalTitle>
                    <S.CloseButton>
                        <CloseIcon />
                    </S.CloseButton>
                </S.ModalTop>
                {children}
            </S.Modal>
        </S.Overlay>
    );
};
