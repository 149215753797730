export const Buy = (props: { className?: string }) => {
    return (
        <div className={`Buy ${props.className}`}>
            <svg viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M39.8529 49.4091V0.599089C39.8529 0.283779 39.531 0 39.1733 0H0.826684C0.433202 0 0.147034 0.283779 0.147034 0.599089L0.182805 49.4091C0.182805 49.8437 0.653181 50.0918 1.05997 49.9683C1.25637 49.9087 1.41737 49.7678 1.59575 49.6663L19.2309 39.6295C19.4093 39.528 19.572 39.3874 19.7707 39.336C19.9994 39.2768 20.2666 39.3238 20.465 39.4768L38.6367 49.819C39.1375 50.1973 39.8529 49.9451 39.8529 49.4091Z"
                    fill="#222F3C"
                />
                <path
                    d="M14.1233 20.9334C14.6646 21.1108 15.0939 21.4094 15.4113 21.8294C15.7286 22.2401 15.8873 22.7534 15.8873 23.3694C15.8873 24.2188 15.5606 24.8768 14.9073 25.3434C14.2539 25.8008 13.3019 26.0294 12.0513 26.0294H7.17926V16.2294H11.7713C12.9286 16.2294 13.8199 16.4581 14.4453 16.9154C15.0706 17.3634 15.3833 17.9841 15.3833 18.7774C15.3833 19.2628 15.2713 19.6921 15.0473 20.0654C14.8233 20.4388 14.5153 20.7281 14.1233 20.9334ZM8.99926 17.6574V20.3594H11.5753C12.2099 20.3594 12.6953 20.2474 13.0313 20.0234C13.3766 19.7901 13.5493 19.4541 13.5493 19.0154C13.5493 18.5674 13.3766 18.2314 13.0313 18.0074C12.6953 17.7741 12.2099 17.6574 11.5753 17.6574H8.99926ZM11.9393 24.6014C13.3486 24.6014 14.0533 24.1301 14.0533 23.1874C14.0533 22.2448 13.3486 21.7734 11.9393 21.7734H8.99926V24.6014H11.9393ZM24.8996 18.5534V26.0294H23.2336V25.0774C22.9536 25.4134 22.6036 25.6748 22.1836 25.8614C21.7636 26.0388 21.311 26.1274 20.8256 26.1274C19.827 26.1274 19.0383 25.8521 18.4596 25.3014C17.8903 24.7414 17.6056 23.9154 17.6056 22.8234V18.5534H19.3556V22.5854C19.3556 23.2574 19.505 23.7614 19.8036 24.0974C20.1116 24.4241 20.5456 24.5874 21.1056 24.5874C21.731 24.5874 22.2256 24.3961 22.5896 24.0134C22.963 23.6214 23.1496 23.0614 23.1496 22.3334V18.5534H24.8996ZM34.1909 18.5534L30.6909 26.6314C30.3643 27.4434 29.9676 28.0128 29.5009 28.3394C29.0343 28.6754 28.4696 28.8434 27.8069 28.8434C27.4336 28.8434 27.0649 28.7828 26.7009 28.6614C26.3369 28.5401 26.0383 28.3721 25.8049 28.1574L26.5049 26.8694C26.6729 27.0281 26.8689 27.1541 27.0929 27.2474C27.3263 27.3408 27.5596 27.3874 27.7929 27.3874C28.1009 27.3874 28.3529 27.3081 28.5489 27.1494C28.7543 26.9908 28.9409 26.7248 29.1089 26.3514L29.2349 26.0574L25.9729 18.5534H27.7929L30.1449 24.0834L32.5109 18.5534H34.1909Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
