import styled, { css } from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';
import { Transition } from '@/styles/Themes/KeyFrames';

export const Container = styled.div`
    position: relative;
    cursor: pointer;
`;

interface IInCart {
    inCart: boolean;
}

export const Title = styled.div<IInCart>`
    color: ${Palette.white.normal};
    background: ${Palette.gradient.light};
    border-radius: ${radius.small};
    padding: 12px 14px;
    position: absolute;
    right: -33px;
    bottom: calc(100% + 5px);
    z-index: 1;
    transition: ${Transition.normal};
    min-width: 270px;

    ${({ inCart }) =>
        inCart
            ? css`
                  &:before {
                      content: '';
                      height: 14px;
                      width: 14px;
                      background: ${Palette.green.normal};
                      transform: rotate(45deg);
                      position: absolute;
                      bottom: -4px;
                      left: 30px;
                      border-radius: 3px;
                  }
              `
            : css`
                  &:before {
                      content: '';
                      height: 14px;
                      width: 14px;
                      background: #17bfd3;
                      transform: rotate(45deg);
                      position: absolute;
                      bottom: -4px;
                      right: 32px;
                      border-radius: 3px;
                  }
              `}

    ${Screen.desktop} {
        padding: 12px 20px;
        left: -30px;

        &:before {
            content: '';
            height: 14px;
            width: 14px;
            background: ${Palette.green.normal};
            transform: rotate(45deg);
            position: absolute;
            bottom: -5px;
            left: 30px;
            border-radius: 3px;
        }
    }
`;
