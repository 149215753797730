import styled from 'styled-components';
import Screen from 'src/styles/Themes/Screen';

export const Block = styled.div`
    background-color: var(--bg-main);
    width: 100vw;
`;

export const Container = styled.div`
    margin: 30px 18px;
    max-width: 1124px;
    ${Screen.ipad} {
        margin: 40px 30px;
        padding: 0 30px;
    }
    ${Screen.desktop} {
        margin: 50px auto;
    }
`;
