import * as S from './TalkToUsInput.styles';

type TTalkToUsInput = {
    value: string;
    type: string;
    placeholder: string;
    label: string;
    errorMessage: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: () => void;
    showError: boolean;
    isValid: boolean;
};

const TalkToUsInput = ({
    value,
    type,
    placeholder,
    label,
    isValid,
    showError,
    errorMessage,
    onChange,
    onBlur,
}: TTalkToUsInput) => {
    return (
        <S.FormGroup>
            <S.Label>{label}</S.Label>
            <S.Input
                type={type}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
            />
            {!isValid && showError && (
                <S.ErrorText>
                    <S.IconWrapper>
                        <S.ErrorIcon />
                    </S.IconWrapper>
                    {errorMessage}
                </S.ErrorText>
            )}
        </S.FormGroup>
    );
};

export { TalkToUsInput };
