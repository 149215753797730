import { ReactElement } from 'react';
import { TalkToUsMessage } from '../../../TalkToUsMessage/TalkToUsMessage';

export type TalkToUsConfirmProps = {
    firstName: string;
    phone: string;
};

const TalkToUsConfirm = ({ firstName, phone }: TalkToUsConfirmProps): ReactElement => {
    return (
        <TalkToUsMessage icon="confirm">
            <span>
                Thanks {firstName}.
                <br />
                We will be calling {phone} in just a few moments.
                <br />
                <br />
                Feel free to browse the site and continue shopping. Closing this window will not
                affect your place in line.
            </span>
        </TalkToUsMessage>
    );
};

export { TalkToUsConfirm };
