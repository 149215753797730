import Link from 'next/link';
import { ReactElement } from 'react';
import * as S from './GiftCard.styles';

export const GiftCard = (): ReactElement => {
    return (
        <S.Wrapper>
            <S.Container data-test-id="Gift_card">
                <S.BuyStripe />
                <S.Title>{`Buy a gift card, choose a date later`}</S.Title>
                <S.Item>
                    <S.Checkbox />
                    {`No expiration`}
                </S.Item>
                <S.Item>
                    <S.Checkbox />
                    {`Great for gifts`}
                </S.Item>
                <S.Item></S.Item>
                <S.BtnWrap>
                    <Link href="/TripShock-com-Gift-Card/details/1025/" passHref>
                        <S.Button data-test-id="Gift_button">Buy Gift Cards</S.Button>
                    </Link>
                </S.BtnWrap>
            </S.Container>
        </S.Wrapper>
    );
};
