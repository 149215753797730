import { memo, ReactNode } from 'react';
import * as S from './Checkbox.styles';

type CheckboxProps = {
    id: string;
    children: ReactNode;
    checked: boolean;
    onChange: () => void;
};

const CheckboxContainer = ({ id, children, checked, onChange }: CheckboxProps) => {
    return (
        <S.Label htmlFor={id}>
            <S.Input onChange={onChange} checked={checked} id={id} type="checkbox" />
            <i />
            <S.Title>{children}</S.Title>
        </S.Label>
    );
};

export const Checkbox = memo(CheckboxContainer);
