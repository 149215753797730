import { radius } from '@/styles/Themes/Metrics';
import styled from 'styled-components';
import { ButtonLoading } from '@/shared/images/icons/ButtonLoading';

export const ButtonLoadingWrapper = styled.div`
    border-radius: 15px;
    background-color: transparent;
    color: transparent;
    display: flex;
    justify-content: center;
    width: 16px;
    height: 16px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .buttonLoading {
        animation: spin 1.2s linear infinite;
    }
`;

export const DotButtonLoading = styled(ButtonLoading)`
    width: 16px;
    height: 16px;
    border-radius: ${radius.small};
    background-color: transparent;
    color: transparent;
`;
