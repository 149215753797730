import { useOnClickOutside } from '@/common/service/hooks';
import { GuestForms } from '@/screens/ActivityPage/ui/Calendar/CalendarBody/GuestForms/GuestForms';
import { ReactElement, memo, ReactNode, useState, useRef, useCallback } from 'react';
import * as S from './TicketsDropdown.styles';

type TicketsDropdownProps = {
    list: { label: ReactNode | string; value: number }[];
    selected?: ReactNode;
    lable?: ReactNode;
    title?: string;
    closeIcon?: boolean;
    needTicketsGuests?: boolean;
    ticketId?: number;
    errorMsg?: string | false;
    onSelect: (item: number) => void;
    closeErrorMsg?: () => void;
    onTouch?: () => void;
};

const TicketsDropdownContainer = ({
    list,
    title,
    selected,
    lable,
    closeIcon,
    needTicketsGuests,
    ticketId,
    errorMsg,
    closeErrorMsg,
    onSelect,
    onTouch,
}: TicketsDropdownProps): ReactElement => {
    const [isActive, setIsActive] = useState(false);
    const refContainer = useRef<HTMLDivElement>(null);
    const countId = selected ? +selected : 0;

    const handleClickOutside = useCallback(() => {
        setIsActive((prev) => {
            if (prev) onTouch?.();
            return false;
        });
    }, [onTouch]);

    const toggleIsActive = useCallback(() => {
        if (list.length) {
            setIsActive((prev) => {
                if (prev) {
                    onTouch?.();
                } else {
                    refContainer.current?.scrollIntoView({ block: 'center' });
                }

                return !prev;
            });
        }
    }, [list, setIsActive, onTouch]);

    useOnClickOutside(refContainer, handleClickOutside);

    const clearSelected = useCallback(
        (event: { stopPropagation: () => void }) => {
            event.stopPropagation();
            onSelect(-1);
        },
        [onSelect]
    );

    return (
        <S.CommonContainer>
            {lable}
            <S.Wrapper>
                <S.Container isActive={isActive} ref={refContainer}>
                    <S.Title onClick={toggleIsActive}>
                        <S.TitleText>
                            {list.length ? selected || title : 'Not Available'}
                        </S.TitleText>
                        {closeIcon && !!selected && (
                            <S.CloseWrapper onClick={clearSelected}>
                                <S.Close />
                            </S.CloseWrapper>
                        )}
                        <S.Arrow isActive={isActive} />
                    </S.Title>
                    {isActive && (
                        <S.Animation onClick={toggleIsActive}>
                            {list.map((item, index) => (
                                <S.Item
                                    key={index}
                                    onClick={() => {
                                        onSelect(item.value);
                                    }}
                                >
                                    {item.label}
                                </S.Item>
                            ))}
                        </S.Animation>
                    )}
                </S.Container>
            </S.Wrapper>
            {errorMsg && (
                <S.SelectTicketType onClick={() => closeErrorMsg?.()}>
                    <span>{errorMsg}</span>
                    <S.RedCrossIcon />
                </S.SelectTicketType>
            )}
            {needTicketsGuests && !!countId && ticketId && (
                <GuestForms ticketId={ticketId} count={countId} />
            )}
        </S.CommonContainer>
    );
};

export const TicketsDropdown = memo(TicketsDropdownContainer);
