import { cssVars } from '@/styles/Themes/cssVars';
import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
//import { RecentIcon } from '@/shared/images/icons/RecentIcon';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';
import { BreadcrumbsArrowRight } from '@/shared/images/icons/BreadcrumbsArrowRight';

export const SearchBarWrapper = styled.div`
    width: 100%;
    border-radius: ${radius.small};
`;
export const SearchBarHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    ${Screen.desktop} {
        display: none;
    }
`;

export const SearchBarTitle = styled.div`
    ${Text.Mobile.large.medium}
    color: ${cssVars.fg_gray2_white};
`;

export const SearchBarLink = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${Text.Mobile.medium.medium}
    color: ${Palette.gray.gray3};
`;

export const SearchBarLinkRightArrow = styled(BreadcrumbsArrowRight)`
    margin: 0 10px 0 0;
`;
