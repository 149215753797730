import 'react-phone-input-2/lib/style.css';
import { StyledPhoneInput } from './PhoneInput.styles';

export type InputPhoneProps = {
    value: string;
    onChange: (value: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    inputProps?: object;
};

export const InputPhone = ({ value, onChange, onBlur, inputProps }: InputPhoneProps) => {
    return (
        <StyledPhoneInput
            preferredCountries={['us']}
            country={'us'}
            value={value}
            onChange={(value) => onChange(`+${value}`)}
            onBlur={onBlur}
            countryCodeEditable={false}
            inputProps={inputProps}
        />
    );
};

export default InputPhone;
