export const BreadcrumbsArrowRight = () => {
    return (
        <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75012 5.22917C5.75012 5.39583 5.66679 5.5625 5.58346 5.64583L1.41679 9.8125C1.16679 10.0625 0.750122 10.0625 0.500122 9.8125C0.250122 9.5625 0.250122 9.14583 0.500122 8.89583L4.25012 5.14583L0.500122 1.39583C0.250122 1.14583 0.250122 0.729167 0.500122 0.479167C0.750122 0.229167 1.16679 0.229167 1.41679 0.479167L5.58346 4.64583C5.66679 4.97917 5.75012 5.14583 5.75012 5.22917Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
