export const Instagram = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.5335 5.94691C14.3274 5.94691 14.1213 6.0156 13.9839 6.15299C13.8466 6.29038 13.7092 6.42776 13.5718 6.63385C13.5031 6.83993 13.5031 7.04601 13.5031 7.25209C13.5718 7.45818 13.6405 7.66426 13.7779 7.80165C13.9153 7.93903 14.1213 8.07642 14.3274 8.07642C14.5335 8.14512 14.7396 8.07642 14.9457 8.00773C15.1517 7.93903 15.2891 7.80165 15.4265 7.59556C15.5639 7.45818 15.6326 7.25209 15.6326 7.04601C15.6326 6.77124 15.4952 6.49646 15.3578 6.29038C15.0144 6.0156 14.8083 5.94691 14.5335 5.94691Z"
                fill="white"
            />
            <path
                d="M13.4345 17.4188H8.0077C5.80948 17.4188 4.02344 15.6327 4.02344 13.4345V8.0077C4.02344 5.80948 5.80948 4.02344 8.0077 4.02344H13.4345C15.6327 4.02344 17.4188 5.80948 17.4188 8.0077V13.4345C17.4188 15.6327 15.6327 17.4188 13.4345 17.4188ZM8.0077 5.05385C6.35904 5.05385 5.05385 6.35904 5.05385 8.0077V13.4345C5.05385 15.0832 6.35904 16.3884 8.0077 16.3884H13.4345C15.0832 16.3884 16.3884 15.0832 16.3884 13.4345V8.0077C16.3884 6.35904 15.0832 5.05385 13.4345 5.05385H8.0077Z"
                fill="white"
            />
            <path
                d="M10.7555 13.9841C8.90072 13.9841 7.45814 12.5415 7.45814 10.7555C7.45814 8.96941 8.90072 7.45814 10.7555 7.45814C12.6102 7.45814 14.0528 8.90072 14.0528 10.7555C14.0528 12.6102 12.5415 13.9841 10.7555 13.9841ZM10.7555 8.48855C9.51897 8.48855 8.48855 9.51897 8.48855 10.7555C8.48855 11.992 9.51897 12.9537 10.7555 12.9537C11.992 12.9537 12.9537 11.9233 12.9537 10.7555C12.9537 9.58766 11.9233 8.48855 10.7555 8.48855Z"
                fill="white"
            />
        </svg>
    );
};
