export const HappyCart = () => {
    return (
        <svg height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="60" cy="60" r="60" fill="var(--bg-ash-dark2)" />
            <path
                d="M76.6231 73.5534H43.8451C42.5179 73.5471 41.2325 73.0885 40.201 72.2534C39.1695 71.4182 38.4534 70.2564 38.1711 68.9596L31.4168 37.0632C31.2012 36.0533 30.6463 35.1477 29.8443 34.4971C29.0424 33.8465 28.0418 33.4902 27.0092 33.4874H22.5308C22.2848 33.4842 22.0498 33.385 21.8759 33.2111C21.702 33.0371 21.6028 32.8022 21.5996 32.5562C21.6028 32.3102 21.702 32.0752 21.8759 31.9013C22.0498 31.7274 22.2848 31.6282 22.5308 31.625H27.0092C28.4623 31.6367 29.8682 32.142 30.9962 33.0579C32.1243 33.9739 32.9074 35.2461 33.2171 36.6658L34.4587 42.3523H85.5377C86.3496 42.3526 87.1507 42.5389 87.8794 42.897C88.6081 43.2551 89.2451 43.7754 89.7414 44.418C90.2377 45.0606 90.5802 45.8083 90.7425 46.6039C90.9048 47.3994 90.8827 48.2216 90.6778 49.0072L85.9226 66.613C85.3176 68.6117 84.0882 70.3641 82.4146 71.6131C80.741 72.8621 78.7113 73.5422 76.6231 73.5534ZM34.8312 44.2147L39.9962 68.5623C40.1856 69.4441 40.6704 70.2348 41.3702 70.8038C42.0701 71.3727 42.9432 71.6857 43.8451 71.6911H76.6231C78.2997 71.6744 79.927 71.122 81.2673 70.1146C82.6076 69.1071 83.5906 67.6974 84.0726 66.0915L88.8279 48.5727C88.967 48.0593 88.9861 47.5208 88.8836 46.9988C88.7811 46.4769 88.5598 45.9856 88.2369 45.563C87.9139 45.1403 87.4979 44.7978 87.0212 44.5618C86.5445 44.3258 86.0199 44.2028 85.488 44.2023H34.8312V44.2147Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M47.4083 90.5998C46.2591 90.5998 45.1356 90.259 44.1801 89.6205C43.2245 88.982 42.4798 88.0745 42.04 87.0128C41.6002 85.951 41.4851 84.7827 41.7093 83.6556C41.9335 82.5284 42.4869 81.493 43.2996 80.6804C44.1122 79.8678 45.1475 79.3144 46.2747 79.0902C47.4018 78.866 48.5702 78.981 49.6319 79.4208C50.6937 79.8606 51.6012 80.6054 52.2397 81.5609C52.8781 82.5165 53.2189 83.6399 53.2189 84.7892C53.2189 86.3302 52.6067 87.8082 51.517 88.8979C50.4273 89.9876 48.9494 90.5998 47.4083 90.5998ZM47.4083 80.8409C46.6274 80.8409 45.864 81.0725 45.2148 81.5063C44.5655 81.9401 44.0594 82.5568 43.7606 83.2782C43.4617 83.9997 43.3836 84.7935 43.5359 85.5594C43.6883 86.3253 44.0643 87.0288 44.6165 87.581C45.1686 88.1332 45.8721 88.5092 46.638 88.6615C47.4039 88.8139 48.1978 88.7357 48.9192 88.4369C49.6407 88.138 50.2573 87.632 50.6911 86.9827C51.125 86.3334 51.3565 85.57 51.3565 84.7892C51.3565 83.742 50.9406 82.7378 50.2001 81.9973C49.4597 81.2569 48.4554 80.8409 47.4083 80.8409Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M75.1329 90.5998C73.9837 90.5998 72.8602 90.259 71.9047 89.6205C70.9491 88.982 70.2044 88.0745 69.7646 87.0128C69.3248 85.951 69.2097 84.7827 69.4339 83.6556C69.6581 82.5284 70.2115 81.493 71.0242 80.6804C71.8368 79.8678 72.8722 79.3144 73.9993 79.0902C75.1265 78.866 76.2948 78.981 77.3565 79.4208C78.4183 79.8606 79.3258 80.6054 79.9643 81.5609C80.6027 82.5165 80.9435 83.6399 80.9435 84.7892C80.9435 86.3302 80.3313 87.8082 79.2416 88.8979C78.1519 89.9876 76.674 90.5998 75.1329 90.5998ZM75.1329 80.8409C74.352 80.8409 73.5887 81.0725 72.9394 81.5063C72.2901 81.9401 71.784 82.5568 71.4852 83.2782C71.1864 83.9997 71.1082 84.7935 71.2605 85.5594C71.4129 86.3253 71.7889 87.0288 72.3411 87.581C72.8932 88.1332 73.5967 88.5092 74.3626 88.6615C75.1285 88.8139 75.9224 88.7357 76.6438 88.4369C77.3653 88.138 77.9819 87.632 78.4158 86.9827C78.8496 86.3334 79.0812 85.57 79.0812 84.7892C79.0812 83.742 78.6652 82.7378 77.9247 81.9973C77.1843 81.2569 76.18 80.8409 75.1329 80.8409Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.264 60.775C74.4671 61.1385 74.3371 61.5978 73.9736 61.8009C71.8443 62.9905 67.9685 65.0403 61.7 65.0403C55.86 65.0403 52.4734 63.1211 50.4035 61.7747C50.0544 61.5477 49.9555 61.0807 50.1826 60.7317C50.4096 60.3826 50.8766 60.2837 51.2256 60.5107C53.1484 61.7613 56.2498 63.5324 61.7 63.5324C67.598 63.5324 71.2084 61.6185 73.2382 60.4846C73.6017 60.2815 74.061 60.4115 74.264 60.775Z"
                fill="#0DD1C5"
            />
            <path
                d="M69.518 52.1867C69.518 52.853 68.978 53.393 68.3117 53.393C67.6455 53.393 67.1055 52.853 67.1055 52.1867C67.1055 51.5205 67.6455 50.9805 68.3117 50.9805C68.978 50.9805 69.518 51.5205 69.518 52.1867Z"
                fill="#0DD1C5"
            />
            <path
                d="M57.9243 52.1867C57.9243 52.853 57.3842 53.393 56.718 53.393C56.0518 53.393 55.5117 52.853 55.5117 52.1867C55.5117 51.5205 56.0518 50.9805 56.718 50.9805C57.3842 50.9805 57.9243 51.5205 57.9243 52.1867Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
