import styled from 'styled-components';
import Screen from 'src/styles/Themes/Screen';
import { radius } from 'src/styles/Themes/Metrics';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import { borders } from '@/styles/Themes/Metrics';
import ErrorMessage from '@/shared/images/icons/ErrorMessage';
import Palette from '@/styles/Themes/Palette';

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
`;

export const Label = styled.label`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.medium.semibold};
    margin-bottom: 6px;

    ${Screen.ipad} {
        ${Text.Ipad.caption.semibold};
    }

    ${Screen.desktop} {
        ${Text.Ipad.caption.semibold};
    }
`;

export const Input = styled.input`
    height: 40px;
    border-radius: ${radius.large};
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    background-color: ${cssVars.bg_white_dark3};
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Mobile.medium.medium};
    padding: 4px 24px;

    ${Screen.ipad} {
        ${Text.Ipad.caption.medium};
        height: 50px;
    }
    ${Screen.desktop} {
        ${Text.Desktop.medium.medium};
    }

    &:focus,
    &:focus-visible {
        border: ${borders.small} ${cssVars.fg_gray2_gray3};
        outline: none;
    }
`;

export const ErrorText = styled.div`
    ${Text.Mobile.normal.semibold};
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: -6px;
    transform: translateY(100%);
    background: ${cssVars.bg_lightBg_darkBg};
    border-radius: ${radius.small};
    border: ${borders.small} ${cssVars.bg_gray4_dark2};
    color: ${Palette.state.error};
    padding: 8px 16px;
    box-shadow: ${cssVars.shadow_card_default};

    ${Screen.ipad} {
        ${Text.Ipad.small.semibold};
        line-height: 17px;
    }

    &:before {
        content: '';
        height: 8px;
        width: 8px;
        border-top: ${borders.small} ${cssVars.bg_gray4_dark2};
        border-right: ${borders.small} ${cssVars.bg_gray4_dark2};
        background: ${cssVars.bg_lightBg_darkBg};
        border-left: none;
        border-bottom: none;
        transform: rotate(-45deg);
        position: absolute;
        top: -6px;
        left: 20px;
        z-index: -1;
    }
`;

export const IconWrapper = styled.div`
    margin-right: 8px;
`;

export const ErrorIcon = styled(ErrorMessage)`
    display: block;
`;
