import {
    memo,
    ReactElement,
    useCallback,
    useState,
    useRef,
    MouseEvent,
    useEffect,
    useMemo,
} from 'react';

import { useOnClickOutside } from '@/common/service/hooks';
import { useMedia } from '@/common/service/hooks/useMedia';

import { Drawer } from '../../drawer';
import { InputSearchResult } from './InputSearchResults';

import {
    IInputSearchProps,
    ISearchOption,
    ISearchState,
} from '@/shared/SearchBar/types/index.types';

import * as S from './InputSearch.styles';
import { Spinner } from '@/shared/images/icons/Spinner';
import { DestinationsInputSearchResult } from './DestinationsInputSearch';

const InputSearchComponent = ({
    value,
    options,
    label,
    placeholder,
    footer,
    onChange,
    mobileState,
    setMobileState,
    loading,
}: IInputSearchProps): ReactElement => {
    const ref = useRef<HTMLLabelElement>(null);
    const refResult = useRef<HTMLDivElement>(null);
    const mobileRef = useRef<HTMLDivElement>(null);

    const isDesktop = useMedia('(min-width: 992px)');

    const [{ open, closeWithDelay, isProgress, isSearch, inputValue }, setState] =
        useState<ISearchState>({
            open: false,
            closeWithDelay: false,
            isProgress: false,
            isSearch: false,
        });

    const isInactiveInput = Boolean(!options.length);

    useOnClickOutside(ref, () => hideResults());

    const handleClose = useCallback(() => {
        if (!open) return;

        setState((prev) => {
            if (prev.inputValue && prev.inputValue !== value) {
                const findResult = options.find((i) => i.label === prev.inputValue)?.value;
                if (findResult) {
                    onChange(findResult);
                }
            }
            return {
                open: false,
                closeWithDelay: false,
                isProgress: false,
                isSearch: false,
            };
        });

        setMobileState && setMobileState({});
    }, [open, setMobileState, value, options, onChange]);

    const closeWithAnimation = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isProgress: true,
        }));

        if (mobileState) {
            mobileRef.current && mobileRef.current.classList.add('hide');
        } else {
            refResult.current && refResult.current.classList.add('hide');
        }

        setTimeout(() => {
            handleClose();
        }, 100);
    }, [handleClose, mobileState]);

    useEffect(() => {
        if (mobileState?.goBack && mobileState?.label === label) {
            closeWithAnimation();
        }
    }, [mobileState, label, closeWithAnimation]);

    const filterResults = useMemo(() => {
        return isSearch
            ? (options || []).filter((i) =>
                  i.label.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase() || '')
              )
            : options || [];
    }, [options, inputValue, isSearch]);

    const changeValue = (label: string) => {
        setState((prev) => ({
            ...prev,
            isSearch: true,
            inputValue: label,
            open: true,
        }));
    };

    const chooseResult = (option: ISearchOption) => {
        if (option) {
            onChange(option.value);
            setState((prev) => ({
                ...prev,
                isSearch: false,
                inputValue: undefined,
                closeWithDelay: !isDesktop,
            }));
            onChange(option.value);
            closeWithAnimation();
            document.body.click();
        }
    };

    const clearResult = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();

        setState((prev) => ({
            ...prev,
            isSearch: false,
            inputValue: '',
        }));

        onChange(undefined);
    };

    const displayResults = () => {
        if (open || isInactiveInput) return;

        setState((prev) => ({
            ...prev,
            open: true,
        }));
        if (setMobileState) {
            setMobileState({
                height: 420,
                label,
            });
        }
    };

    const hideResults = () => {
        if (isProgress) return;
        if (isDesktop && open) {
            closeWithAnimation();
        }
    };

    const inputSearchResult = () => {
        if (label === 'Destination') {
            return (
                <DestinationsInputSearchResult
                    value={value}
                    filterResults={filterResults}
                    chooseResult={chooseResult}
                />
            );
        } else {
            return (
                <InputSearchResult
                    value={value}
                    filterResults={filterResults}
                    chooseResult={chooseResult}
                    footer={footer}
                />
            );
        }
    };

    return (
        <>
            <S.InputSearchWrapper ref={ref} onClick={displayResults} data-test-id="Input_search">
                <S.InputSearchContainer>
                    {label && <S.Label>{label}</S.Label>}
                    <S.InputSearch
                        type="text"
                        placeholder={placeholder}
                        autoComplete="off"
                        value={inputValue ?? value ?? ''}
                        onChange={(e) => changeValue(e.target.value)}
                        onFocus={(e) => {
                            if (!isDesktop) {
                                e.currentTarget.blur();
                            }
                        }}
                    />
                </S.InputSearchContainer>
                <S.InputSearchIcons>
                    {!!loading && (
                        <S.SpinnerWrapper>
                            <Spinner />
                        </S.SpinnerWrapper>
                    )}
                    {(inputValue || value) && (
                        <S.ButtonClose onClick={clearResult}>
                            <S.IconClose />
                        </S.ButtonClose>
                    )}
                    <S.ButtonArrow onClick={hideResults} aria-label={label}>
                        <S.ArrowDown isOpen={open} />
                    </S.ButtonArrow>
                </S.InputSearchIcons>
                {open && !mobileState && (
                    <>
                        {isDesktop ? (
                            <S.ResultsContainer ref={refResult}>
                                {inputSearchResult()}
                            </S.ResultsContainer>
                        ) : (
                            <Drawer
                                open={open}
                                setOpen={handleClose}
                                title={label}
                                closeWithDelay={closeWithDelay}
                            >
                                <S.SearchInput
                                    type="text"
                                    value={inputValue ?? value ?? ''}
                                    placeholder={placeholder}
                                    onChange={(e) => changeValue(e.target.value)}
                                />
                                {inputSearchResult()}
                            </Drawer>
                        )}
                    </>
                )}
            </S.InputSearchWrapper>
            {mobileState && open && (
                <S.MobileAbsoluteContainer isVisible={open} ref={mobileRef}>
                    <S.SearchInput
                        type="text"
                        value={inputValue ?? ''}
                        placeholder={placeholder}
                        onChange={(e) => changeValue(e.target.value)}
                    />
                    {inputSearchResult()}
                </S.MobileAbsoluteContainer>
            )}
        </>
    );
};

export const InputSearch = memo(InputSearchComponent);
