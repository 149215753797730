export const CloseIconPromo = (props: { className?: string }) => {
    return (
        <svg
            className={`CloseIcon ${props.className}`}
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.36447 10.2644C9.59988 10.5009 9.98156 10.5009 10.217 10.2644C10.4524 10.0278 10.4524 9.64428 10.217 9.40773L6.27126 5.44288L10.2655 1.42931C10.5009 1.19276 10.5009 0.809234 10.2655 0.572681C10.03 0.336129 9.64837 0.336129 9.41296 0.572681L5.41876 4.58625L1.42456 0.572678C1.18915 0.336126 0.807476 0.336125 0.572066 0.572678C0.336655 0.80923 0.336655 1.19276 0.572066 1.42931L4.56626 5.44288L0.620549 9.40773C0.385139 9.64429 0.385139 10.0278 0.620549 10.2644C0.85596 10.5009 1.23764 10.5009 1.47305 10.2644L5.41876 6.29951L9.36447 10.2644Z"
                fill="currentColor"
            />
        </svg>
    );
};
