import { ReactElement } from 'react';
import { ButtonLoadingWrapper, DotButtonLoading } from './ButtonLoading.styles';

export const ButtonLoading = (): ReactElement => {
    return (
        <>
            <ButtonLoadingWrapper>
                <DotButtonLoading data-testid="ButtonLoading" />
            </ButtonLoadingWrapper>
        </>
    );
};

export default ButtonLoading;
