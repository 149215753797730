import React, {
    memo,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    DAYS,
    DAYS_LEAP,
    DAYS_OF_THE_WEEK,
    MONTHS,
} from '@/common/ui/inputs/DayPicker/Constants/Contants';
import { LightTheme } from 'src/styles/Themes/LightTheme/LightTheme';
import { addMonths, differenceInMonths, format, startOfToday, subMonths } from 'date-fns';
import LeftArrow from '../../arrows/LightArrows/LeftArrow';
import { RightArrow } from '../../arrows/LightArrows/RightArrow';
import { ThemeProvider } from 'styled-components';
import * as S from '@/common/ui/inputs/DayPicker/DayPicker.styles';
import { addOneWeek, addTwoWeeks } from '@/common/app/utils/addWeeks';
import { Button } from '../../buttons';
import { SearchBarButtonIcon } from '@/shared/SearchBar/ui/SearchForm/SearchForm.styles';

type DayPickerProps = {
    onChange?: (dateStart: Date, dateEnd: Date) => void;
    pastDisabled?: boolean;
    dateStart?: Date;
    dateEnd?: Date;
    isNotAvailable?: (d: number) => boolean;
    onMonthChange?: (date: string) => void;
    withButton?: boolean;
    buttonContent?: ReactNode;
    isLoad?: boolean;
    oneWeekOnly?: boolean;
    withTopDateLine?: boolean;
};

type TPreSelected = {
    from?: Date;
    to?: Date;
};

const ControlledDayPicker = (props: DayPickerProps): ReactElement => {
    const today = new Date();
    const {
        onMonthChange,
        isNotAvailable,
        dateStart: currentDateStart,
        dateEnd: currentDateEnd,
        onChange,
        pastDisabled,
        isLoad,
        oneWeekOnly,
        withButton,
        buttonContent,
        withTopDateLine,
    } = props;
    const [date, setDate] = useState(currentDateStart ?? today);
    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [needsBeDisabled, setBeDisabled] = useState(false);
    const [hoverDays, setHoverDays] = useState<
        { start: number; to: number; end: number | undefined } | undefined
    >(undefined);
    const [preSelected, setPreSelected] = useState<TPreSelected>();

    const dateStart = preSelected ? preSelected.from : currentDateStart;
    const dateEnd = preSelected ? preSelected.to : currentDateEnd;
    const maxEndDate = preSelected?.from ? addTwoWeeks(preSelected.from) : 0;

    const getStartDayOfMonth = (date: Date) => {
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        return startDate === 0 ? 7 : startDate;
    };

    const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

    const onHoverHandler = useCallback(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (date: Date) => {
            const currentSelectedMonth = date.getMonth();

            if (date < startOfToday() || currentSelectedMonth !== month) {
                setHoverDays(undefined);
                return;
            }

            if (oneWeekOnly) {
                const start = date.getDate();
                const newEnd = addOneWeek(date).getDate();
                const end = newEnd < date.getDate() ? undefined : newEnd;
                const to = end ? end : 31;
                setHoverDays({ start, end, to });
                return;
            }

            if (!dateStart || date <= dateStart || !preSelected) {
                setHoverDays({
                    start: date.getDate(),
                    to: 0,
                    end: 0,
                });
                return;
            }

            const maxDate = addTwoWeeks(dateStart);
            const startMonth = dateStart.getMonth();
            const endMonth = maxDate.getMonth();

            if (startMonth !== currentSelectedMonth && endMonth !== currentSelectedMonth) {
                setHoverDays(undefined);
                return;
            }

            const start =
                startMonth === endMonth || startMonth === currentSelectedMonth
                    ? dateStart.getDate()
                    : 0;
            const haveHoverLine = date > dateStart;

            const end = !haveHoverLine
                ? undefined
                : date < maxDate
                ? date.getDate()
                : maxDate.getDate();
            const to = end ? end : 31;
            setHoverDays({ start, end, to });
        },
        [month, dateStart, oneWeekOnly, preSelected]
    );

    useEffect(() => {
        setMonth(date.getMonth());
        setYear(date.getFullYear());
        setStartDay(getStartDayOfMonth(date));
    }, [date]);

    useEffect(() => {
        onMonthChange && onMonthChange(`${year}-${month + 1}`);
    }, [month, onMonthChange, year]);

    useEffect(() => {
        if (new Date() > date) {
            setBeDisabled(true);
        }
        if (new Date() < date) {
            setBeDisabled(false);
        }
    }, [date]);

    const isLeapYear = (year: number) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

    const handleChange = (newDate: Date) => {
        if (oneWeekOnly) {
            onChange?.(newDate, addOneWeek(newDate));
        }

        if (!preSelected?.from || preSelected.from >= newDate || preSelected?.to) {
            setPreSelected({ from: newDate });
            return;
        }

        const maxDate = addTwoWeeks(preSelected.from);
        const newTo = newDate < maxDate ? newDate : maxDate;

        setPreSelected((prev) => ({ ...prev, to: newTo }));
        if (!withButton) {
            onChange?.(preSelected.from, newTo);
        }
    };

    const handleBack = () => {
        const newDate = subMonths(date, 1);
        if (differenceInMonths(newDate, today) < 0 && pastDisabled) return;
        setDate(newDate);
    };

    const handleNext = () => {
        setDate(addMonths(date, 1));
    };

    const selectedDates = useMemo(() => {
        if (!dateStart) {
            return [];
        }

        const startMonth = dateStart.getMonth();
        const endMonth = dateEnd?.getMonth() || 0;
        const startDay = dateStart.getDate();
        const endDay = dateEnd?.getDate() || 0;

        const isCurrentMonth = month === startMonth;

        if (!endDay) {
            if (isCurrentMonth && startDay) {
                return [startDay];
            }

            return [];
        }

        if (startMonth === endMonth && isCurrentMonth && endDay >= startDay) {
            return Array(endDay - startDay + 1)
                .fill(0)
                .map((_, index) => startDay + index);
        }
        if (startDay > endDay) {
            if (isCurrentMonth) {
                return Array(days[startMonth] + 1 - startDay)
                    .fill(0)
                    .map((_, index) => startDay + index);
            }
            if (endMonth === month) {
                return Array(endDay || 1)
                    .fill(0)
                    .map((_, index) => ++index);
            }
        }
        return [];
    }, [dateStart, dateEnd, month, days]);

    const getClasses = useCallback(
        /* eslint-disable sonarjs/cognitive-complexity */
        (d: number): string => {
            const classes = [];
            if (d < 1) {
                classes.push('emptyDay');
            }
            if (isNotAvailable && isNotAvailable(d)) {
                classes.push('notAvail');
            }
            if (hoverDays?.start && d === hoverDays.start) {
                if (!hoverDays.end) {
                    classes.push('woCorners');
                }
                classes.push('startHover');
            }
            if (hoverDays?.end && d === hoverDays.end) {
                classes.push('endHover');
            }
            if (hoverDays && d > hoverDays.start && d <= hoverDays.to) {
                classes.push('hover');
            }
            if (
                selectedDates.includes(d) &&
                d === dateStart?.getDate() &&
                (selectedDates.length > 1 || hoverDays?.end)
            ) {
                classes.push('startSelected');
            }
            if (selectedDates.includes(d) && d === dateEnd?.getDate() && selectedDates.length > 1) {
                if (hoverDays?.end && hoverDays.end > dateEnd?.getDate()) {
                    classes.push('hoverAfter');
                }
                classes.push('endSelected');
            }
            return classes.join(' ');
        },
        [dateEnd, dateStart, hoverDays, isNotAvailable, selectedDates]
    );

    const startToday = startOfToday();

    const isDisabled = (d: number) => {
        const currentDate = new Date(year, month, d);
        return (
            (pastDisabled && currentDate < startToday) ||
            (isNotAvailable && isNotAvailable(d) && !isLoad) ||
            (!!maxEndDate && maxEndDate < currentDate)
        );
    };

    const handleSearch = () => {
        if (preSelected?.to && preSelected.from) {
            onChange?.(preSelected.from, preSelected.to);
        }
    };

    return (
        <ThemeProvider theme={LightTheme}>
            <S.Frame data-test-id="calendar">
                <S.Header>
                    <LeftArrow onClick={handleBack} disabled={needsBeDisabled} />
                    <S.MonthTitle>
                        {MONTHS[month]} {year}
                    </S.MonthTitle>
                    <RightArrow onClick={handleNext} disabled={false} />
                </S.Header>
                <S.Body isLoad={isLoad} onMouseOut={() => setHoverDays(undefined)}>
                    {DAYS_OF_THE_WEEK.map((dayName) => (
                        <S.Day key={dayName}>{dayName}</S.Day>
                    ))}
                    {Array(days[month] + startDay)
                        .fill(null)
                        .map((_, index) => {
                            const d = index - (startDay - 1);
                            const selected = selectedDates.includes(d);
                            return (
                                <S.Day
                                    lastWeekDay={(index + 1) % 7 === 0}
                                    firstWeekDay={index % 7 === 0}
                                    key={index}
                                    isToday={d === today.getDate() && month === today.getMonth()}
                                    isSelected={selected}
                                    daysBetween={
                                        selected &&
                                        d !== dateStart?.getDate() &&
                                        d !== dateEnd?.getDate()
                                    }
                                    onMouseOver={() => onHoverHandler(new Date(year, month, d))}
                                    onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                        if (
                                            (pastDisabled &&
                                                new Date(year, month, d) < startOfToday()) ||
                                            (isNotAvailable && isNotAvailable(d)) ||
                                            d < 1
                                        ) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return;
                                        }

                                        handleChange(new Date(year, month, d));
                                    }}
                                    className={getClasses(d)}
                                    disabled={isDisabled(d)}
                                >
                                    {d > 0 ? d : ''}
                                </S.Day>
                            );
                        })}
                </S.Body>
                {withButton && (
                    <S.ButtonWrapper withIcon={!buttonContent}>
                        <S.Sign>
                            {preSelected &&
                                `${
                                    preSelected.from
                                        ? format(preSelected.from, 'MMM d') + ' - '
                                        : ''
                                }${preSelected.to ? format(preSelected.to, 'MMM d') : ''}`}
                        </S.Sign>
                        <Button
                            variant="primary"
                            onClick={handleSearch}
                            disabled={!preSelected?.to}
                        >
                            {buttonContent || (
                                <>
                                    <SearchBarButtonIcon />
                                    Search
                                </>
                            )}
                        </Button>
                        <S.Reset onClick={() => setPreSelected({})}>Reset X</S.Reset>
                    </S.ButtonWrapper>
                )}
                {withTopDateLine && !!preSelected?.from && (
                    <S.TopDateLine>
                        <S.TopSign>{`${format(preSelected.from, 'MMM d')} ~`}</S.TopSign>
                        <S.ButtonClose onClick={() => setPreSelected({})}>
                            <S.IconClose />
                        </S.ButtonClose>
                    </S.TopDateLine>
                )}
            </S.Frame>
        </ThemeProvider>
    );
};

export const DayPicker = memo(ControlledDayPicker);
