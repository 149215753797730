import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { Transition } from '@/styles/Themes/KeyFrames';
import { borders } from '@/styles/Themes/Metrics';
import star from '@/shared/images/icons/star.png';

export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold}
    cursor: pointer;
    padding: 16px 0;
`;

export const Item = styled.div`
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: 0px 4px 23px ${cssVars.shadow_normal};
    margin-bottom: 10px;
    padding: 0 20px;
    overflow: hidden;
    position: relative;

    ${Screen.ipad} {
        margin-bottom: 16px;
        padding: 0 30px;
    }
    ${Screen.desktop} {
        margin-bottom: 10px;
        max-width: 1064px;
    }
`;

export const Question = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold}
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    padding: 22px 0;
`;

export const Answer = styled.div<{ indexOpen: number | null; withStars: boolean | undefined }>`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular}
    border-top: ${borders.small} ${cssVars.bg_gray4_dark2};
    padding: 0 0 8px;

    > div {
        > ul {
            padding: 0 10px;

            > li {
                padding: 5px 0;
            }
        }
    }
    ul {
        padding: 0;

        ${({ indexOpen, withStars }) =>
            indexOpen === 0 && withStars
                ? css`
                      > li {
                          list-style-position: outside;
                          list-style-image: url(${star.src});
                      }
                  `
                : css`
                      > li {
                          list-style-position: outside;
                      }
                  `}
    }

    ${Screen.ipad} {
        padding: 15px 0;
    }
`;

export const Animation = styled.div<{ isOpen: boolean }>`
    ${({ isOpen }) =>
        isOpen
            ? css`
                  position: relative;

                  @keyframes down {
                      from {
                          max-height: 0;
                      }

                      to {
                          max-height: 100vh;
                      }
                  }

                  animation-duration: 2s;
                  animation-name: down;
                  animation-timing-function: ease-out;
              `
            : css`
                  position: absolute;
              `}
`;

export const Arrow = styled(DropdownArrow)<{ isOpen: boolean }>`
    margin-left: 10px;
    ${({ isOpen }) => isOpen && 'transform: rotate(-180deg);'}
    ${Transition.arrow};
    min-width: 24px;
    min-height: 24px;

    ${Screen.ipad} {
        min-width: 24px;
        min-height: 24px;
    }
    ${Screen.desktop} {
        min-width: 35px;
        min-height: 34px;
    }
`;
