import styled, { keyframes, ThemedStyledProps } from 'styled-components';
import { TTheme } from 'src/styles/Themes/Theme.domain';
import { TSkeletonStyled } from './Skeleton.types';
import { radius } from '@/styles/Themes/Metrics';

const loadingAnimation = ({ ...props }) => keyframes`
    0%  { 
        background-color: ${props.theme.keyframes.background0};
    }
    100%  { 
        background-color: ${props.theme.keyframes.background100};
    }
`;
export const DestinationWrapper = styled.div<ThemedStyledProps<TSkeletonStyled, TTheme>>`
    animation: ${loadingAnimation} 1s linear infinite alternate;
    height: 15rem;
    width: 50rem;
    object-fit: 'cover';
    border-radius: ${radius.small};
`;
