import Palette from '@/styles/Themes/Palette';
import styled, { css } from 'styled-components';

export const CookieNotification = styled.div<{ closed: boolean }>`
    font-size: 12px;
    position: relative;
    bottom: 0;
    background: ${Palette.white.normal};
    z-index: 100000;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    ${({ closed }) =>
        closed &&
        css`
            display: none;
        `};
`;

export const CookieContainer = styled.div`
    padding: 10px;
    width: 100%;
`;

export const PrivacyPolicyButton = styled.a`
    font-size: 12px;
`;
export const IconContainer = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
`;
export const TextContainer = styled.div`
    width: 90%;
    display: inline-block;
    left: 32px;
    position: relative;
    @media (max-width: 767px) {
        width: 85%;
    }
`;
export const Text = styled.span`
    font-size: 12px;
    padding-left: 10px;
    position: relative;
    margin: auto;
    width: 50%;
    text-align: justify;
`;
export const CloseIcon = styled.span`
    font-size: 12px;
    display: inline-block;
    margin: 0;
    position: absolute;
    top: 50%;
    right: 25px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
    @media (max-width: 767px) {
        right: 15px;
    }
`;
