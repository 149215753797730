import { useEffect, useState } from 'react';

export const useScrollingUp = (position: number): boolean => {
    const [scrollY, setScrollY] = useState(false);

    useEffect(() => {
        if (window.scrollY >= position && !scrollY) {
            setScrollY(true);
        }

        const onScroll = () => {
            if (window.scrollY >= position && !scrollY) {
                setScrollY(true);
            }
            if (window.scrollY < position && scrollY) {
                setScrollY(false);
            }
        };

        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, [scrollY, position]);

    return scrollY;
};
