export const SearchIcon = (props: { className?: string }) => {
    return (
        <svg
            className={`SearchIcon ${props.className}`}
            aria-hidden="true"
            focusable="false"
            role="img"
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5695 9.6882L9.44881 8.11768C10.1115 7.33243 10.4429 6.28542 10.4429 5.17297C10.4429 2.55545 8.25592 0.395996 5.60505 0.395996C2.95419 0.395996 0.833496 2.62089 0.833496 5.23841C0.833496 7.85593 3.02046 10.0154 5.67132 10.0154C6.86421 10.0154 7.92456 9.62276 8.78609 8.90294L11.0393 10.5389C11.1056 10.6043 11.2381 10.6043 11.3044 10.6043C11.437 10.6043 11.6358 10.5389 11.702 10.408C11.8346 10.2117 11.7683 9.88451 11.5695 9.6882ZM1.82757 5.23841C1.82757 3.14439 3.55063 1.443 5.67132 1.443C7.79202 1.443 9.44881 3.14439 9.44881 5.23841C9.44881 7.33243 7.72574 8.96838 5.67132 8.96838C3.6169 8.96838 1.82757 7.26699 1.82757 5.23841Z"
                fill="white"
            />
        </svg>
    );
};
