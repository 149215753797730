import { memo, ReactElement, useState } from 'react';

import { useSearchContext } from '@/common/app/contexts';

import { Drawer } from '@/common/ui/drawer';

import * as S from './SearchBar.styles';
import { SearchForm } from '../SearchForm/SearchForm';
import { RecentSearches } from '../RecentsSearches/RecentSearches';

type SearchBarProps = {
    onHandleSearch?: () => void;
};

const SearchBarComponent = ({ onHandleSearch }: SearchBarProps): ReactElement => {
    const { recents, onRecentSearch } = useSearchContext();

    const [openRecents, setOpenRecents] = useState(false);
    const [closeOpenRecentsWithDelay, setCloseOpenRecentsWithDelay] = useState(false);

    const isRecents = recents && Boolean(recents.length);

    const closeOpenRecents = () => {
        setOpenRecents(false);
        setCloseOpenRecentsWithDelay(false);
    };

    return (
        <S.SearchBarWrapper>
            <S.SearchBarHeader>
                <S.SearchBarTitle>
                    {isRecents ? 'New Search' : "Let's find your adventure!"}
                </S.SearchBarTitle>
                {isRecents && (
                    <S.SearchBarLink
                        onClick={() => {
                            setOpenRecents(true);
                        }}
                    >
                        Recent searches&nbsp;
                        <S.SearchBarLinkRightArrow />
                    </S.SearchBarLink>
                )}
            </S.SearchBarHeader>

            <SearchForm onHandleSearch={onHandleSearch} />

            {openRecents && !!recents && (
                <Drawer
                    title={'Recent Searches'}
                    open={openRecents}
                    setOpen={closeOpenRecents}
                    closeWithDelay={closeOpenRecentsWithDelay}
                >
                    <RecentSearches recents={recents} handleRecentSearch={onRecentSearch} />
                </Drawer>
            )}
        </S.SearchBarWrapper>
    );
};

export const SearchBar = memo(SearchBarComponent);
