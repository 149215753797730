import { ReactElement, useState, useEffect } from 'react';
import * as S from './Notification.styles';
import { Button } from '@/common/ui';
import ProgressBar from './ProgressBar/ProgressBar';
import { HappyCart } from '@/shared/images/icons/HappyCart';
import { PromoCircle } from '@/shared/images/icons/PromoCircle';
import { useCartContext } from '@/common/app/contexts';
import { useRouter } from 'next/router';

const MS_IN_ONE_SEC = 1000;
const ANIMATION_DELAY = 200;

const IMAGES = {
    cart: <HappyCart />,
    promo: <PromoCircle />,
};

const Notification = (): ReactElement => {
    const { push } = useRouter();
    const [hideAnimation, setHideAnimation] = useState(false);
    const { toastData: data, clearToastData } = useCartContext();
    const delayMs = data ? data?.duration * MS_IN_ONE_SEC : 0;

    const closeToast = () => {
        clearToastData?.();
        setHideAnimation(false);
    };

    useEffect(() => {
        if (data) {
            setTimeout(() => {
                setHideAnimation(true);
                setTimeout(() => {
                    closeToast();
                }, ANIMATION_DELAY);
            }, delayMs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (!data) {
        return <></>;
    }
    return (
        <S.StyledNotification hide={hideAnimation}>
            <S.CloseButton onClick={closeToast}>
                <S.CloseButtonWithIcon />
            </S.CloseButton>
            <S.LeftPart>{IMAGES[data.image]}</S.LeftPart>
            <S.RightPart>
                <S.NotificationTitle>{data.title}</S.NotificationTitle>
                <S.NotificationParagraph>
                    {data.btnLink ? (
                        <>
                            <S.NotificationTextWithBtn>{data.label}</S.NotificationTextWithBtn>
                            <S.ButtonWrapper>
                                <Button
                                    variant={'primary'}
                                    onClick={() => {
                                        closeToast();
                                        push(data.btnLink || '/');
                                    }}
                                >
                                    Cart
                                </Button>
                            </S.ButtonWrapper>
                        </>
                    ) : (
                        <S.NotificationText>{data.label}</S.NotificationText>
                    )}
                </S.NotificationParagraph>
                <ProgressBar delayMs={delayMs} />
            </S.RightPart>
        </S.StyledNotification>
    );
};

export { Notification };
export default Notification;
