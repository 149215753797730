import { Button, ButtonLoading } from '@/common/ui';
import * as S from './TalkToUsForm.styles';

import { TalkToUsFormProps } from './TalkToUsForm.types';
import { TalkToUsInput } from './talkToUsForm/TalkToUsInput/TalkToUsInput';
import { TalkToUsConfirm } from './talkToUsForm/TalkToUsConfirm/TalkToUsConfirm';
import { Dropdown } from '@/common/ui/inputs/Dropdown';
import { isValidName, isValidEmail, isValidPhone } from '@/common/app/utils/typeUtils';
import { useUserInfo } from '@/entities/Checkout/app/hooks/useUserInfo';
import {
    ErrorIcon,
    ErrorText,
    FormGroup,
    IconWrapper,
    Label,
} from './talkToUsForm/TalkToUsInput/TalkToUsInput.styles';
import { useSelect } from '@/common/app/hooks/useSelect';
import PhoneInput from '@/common/ui/inputs/PhoneInput/PhoneInput';

const TalkToUsForm = ({ onSubmit, confirm, isLoading }: TalkToUsFormProps) => {
    const questions = useSelect();
    const {
        firstName,
        lastName,
        email,
        phone,
        isValidForm,
        phoneChanged,
        setFirstName,
        setLastName,
        setEmail,
        setPhone,
        setPhoneChanged,
    } = useUserInfo();

    if (confirm) {
        return <TalkToUsConfirm firstName={firstName.value} phone={phone.value} />;
    }

    return (
        <>
            <S.Form>
                <TalkToUsInput
                    type="text"
                    placeholder="Your first name"
                    label="First Name"
                    value={firstName.value}
                    errorMessage="Please enter valid first name"
                    onChange={(e) =>
                        setFirstName((prev) => ({
                            ...prev,
                            value: e.target.value,
                            isValid: isValidName(e.target.value),
                        }))
                    }
                    onBlur={() => {
                        if (!firstName.showError) {
                            setFirstName((prev) => ({
                                ...prev,
                                showError: true,
                                isValid: isValidName(firstName.value),
                            }));
                        }
                    }}
                    showError={firstName.showError}
                    isValid={firstName.isValid}
                />
                <TalkToUsInput
                    type="text"
                    placeholder="Your last name"
                    label="Last Name"
                    errorMessage="Please enter valid last name"
                    value={lastName.value}
                    onChange={(e) =>
                        setLastName((prev) => ({
                            ...prev,
                            value: e.target.value,
                            isValid: isValidName(e.target.value),
                        }))
                    }
                    onBlur={() => {
                        if (!lastName.showError) {
                            setLastName((prev) => ({
                                ...prev,
                                showError: true,
                                isValid: isValidName(lastName.value),
                            }));
                        }
                    }}
                    showError={lastName.showError}
                    isValid={lastName.isValid}
                />
                <TalkToUsInput
                    placeholder="Your email"
                    type="email"
                    label="Email"
                    errorMessage="Please enter valid e-mail address"
                    value={email.value}
                    onChange={(e) =>
                        setEmail((prev) => ({
                            ...prev,
                            value: e.target.value,
                            isValid: isValidEmail(e.target.value),
                        }))
                    }
                    onBlur={() => {
                        if (!email.showError) {
                            setEmail((prev) => ({
                                ...prev,
                                showError: true,
                                isValid: isValidEmail(email.value),
                            }));
                        }
                    }}
                    showError={email.showError}
                    isValid={email.isValid}
                />
                <FormGroup>
                    <Label>Phone</Label>
                    <S.PhoneWrapper>
                        <PhoneInput
                            value={phone.value}
                            onChange={(value) => {
                                setPhone((prev) => ({
                                    ...prev,
                                    value,
                                    isValid: isValidPhone(value),
                                }));

                                setPhoneChanged(true);
                            }}
                            onBlur={() => {
                                if (!phone.showError && phoneChanged) {
                                    setPhone((prev) => ({
                                        ...prev,
                                        showError: true,
                                        isValid: isValidPhone(phone.value),
                                    }));
                                }
                            }}
                            inputProps={{ name: 'phone' }}
                        />
                    </S.PhoneWrapper>
                    {!phone.isValid && phone.showError && (
                        <ErrorText>
                            <IconWrapper>
                                <ErrorIcon />
                            </IconWrapper>
                            Please enter valid phone number
                        </ErrorText>
                    )}
                </FormGroup>

                <Dropdown {...questions} />
            </S.Form>
            <S.Actions>
                <S.FooterButton>
                    <Button
                        disabled={!isValidForm || isLoading}
                        variant="primary"
                        onClick={() =>
                            onSubmit({
                                email: email.value,
                                phone: phone.value,
                                firstName: firstName.value,
                                lastName: lastName.value,
                                questionType: questions.value ?? 'Reservation',
                            })
                        }
                    >
                        {isLoading && <ButtonLoading />}
                        <S.ButtonText isLoading={isLoading}>Call Me</S.ButtonText>
                    </Button>
                </S.FooterButton>
            </S.Actions>
        </>
    );
};

export { TalkToUsForm };
