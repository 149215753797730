export const CalendarIcon = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.7051 5.92372C16.369 5.58761 15.8648 5.33553 15.2766 5.33553H14.6044V4.91539C14.6044 4.49525 14.3523 4.24316 14.0162 4.24316C13.6801 4.24316 13.344 4.49525 13.344 4.91539V5.33553H9.31063V4.91539C9.31063 4.49525 8.97452 4.24316 8.63841 4.24316C8.3023 4.24316 8.05022 4.49525 8.05022 4.91539V5.33553H7.46202C6.95786 5.33553 6.36966 5.50358 5.94952 5.92372C5.61341 6.34386 5.36133 6.84802 5.36133 7.43622V15.3348C5.36133 15.839 5.52938 16.3432 5.94952 16.7633C6.28563 17.0994 6.7898 17.3515 7.378 17.3515H15.3606C15.8648 17.3515 16.369 17.0994 16.7891 16.7633C17.1252 16.4272 17.3773 15.923 17.3773 15.3348V7.26816C17.2933 6.764 17.1252 6.25983 16.7051 5.92372ZM16.0329 15.3348C16.0329 15.5029 15.9488 15.6709 15.8648 15.839C15.6967 16.0071 15.5287 16.0071 15.3606 16.0071H7.29397C7.12591 16.0071 6.95786 15.923 6.7898 15.839C6.62175 15.6709 6.62175 15.5029 6.62175 15.3348V8.94872H15.0245C15.3606 8.94872 15.6967 8.69664 15.6967 8.2765C15.6967 7.85636 15.4447 7.60427 15.0245 7.60427H6.62175V7.43622C6.62175 7.18414 6.70577 7.01608 6.87383 6.84802C7.04188 6.67997 7.29397 6.59594 7.46202 6.59594H8.05022C8.55438 6.59594 9.02799 6.59594 9.31063 6.59594H13.428C13.7044 6.59594 14.2487 6.59594 14.6044 6.59594H15.2766C15.4447 6.59594 15.6127 6.67997 15.7808 6.764C15.9488 6.93205 15.9488 7.10011 15.9488 7.26816V15.3348H16.0329Z"
                fill="#0DD1C5"
            />
            <path
                d="M10.403 10.2932H8.55438C8.3023 10.2932 8.13425 10.4612 8.13425 10.7133V11.6376C8.13425 11.8897 8.3023 12.0577 8.55438 12.0577H10.403C10.6551 12.0577 10.8231 11.8897 10.8231 11.6376V10.7133C10.8231 10.4612 10.6551 10.2932 10.403 10.2932Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
