import { memo, ReactElement } from 'react';
import { StyledButton } from './Button.styles';
import { ButtonProps } from './Button.types';

const ButtonComponent = ({ children, variant, styles, ...props }: ButtonProps): ReactElement => {
    return (
        <StyledButton variant={variant} styles={styles} {...props}>
            {children}
        </StyledButton>
    );
};

export const Button = memo(ButtonComponent);
