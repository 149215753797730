import styled from 'styled-components';
import { WeAreClosed } from '@/shared/images/icons/WeAreClosed';
import Screen from 'src/styles/Themes/Screen';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { CallingIcon } from '@/shared/images/icons/CallingIcon';

export const InfoMessage = styled.div`
    display: flex;
    flex-direction: column;
    ${Text.Full.medium.regular};
    color: ${Palette.black};
    box-sizing: border-box;
    padding: 10px 20px 20px;
    ${Screen.desktop} {
        padding: 0 20px 10px;
    }
`;

export const WrapperForIcon = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px;
    ${Screen.ipad} {
        padding: 0;
    }
    ${Screen.desktop} {
        margin: 12px;
        padding: 0;
    }
`;

export const WeAreClosedIcon = styled(WeAreClosed)`
    width: 67px;
    height: 67px;
    ${Screen.ipad} {
        width: 100px;
        height: 100px;
    }
    ${Screen.desktop} {
        width: 85px;
        height: 85px;
    }
`;

export const Calling = styled(CallingIcon)`
    width: 67px;
    height: 67px;
    ${Screen.desktop} {
        width: 85px;
        height: 85px;
    }
`;

export const ClosedCallingForm = styled.ul`
    margin-top: 22px;
    color: ${cssVars.fg_gray2_gray3};
    padding-left: 15px;
    ${Screen.ipad} {
        padding: 10px 0 10px 20px;
    }
    ${Screen.desktop} {
        padding-left: 30px;
    }
`;

export const Online = styled.span`
    color: ${Palette.green.normal};
`;
