const Screen = {
    mobile: `@media (max-width: 499px)`,
    ipad: `@media (min-width: 500px)`,
    ipadMini: `@media(max-width: 768px)`,
    desktop: `@media (min-width: 992px)`,
    full: `@media (min-width: 1064px)`,
    fullWithPadding: `@media (min-width: 1124px)`,
    semiFull: `@media (max-width: 1199px)`,
};

export default Screen;
