import { FlexPresets } from '../../../../styles/Themes/Presets';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { TTheme } from 'src/styles/Themes/Theme.domain';
import { TInputProps } from './Input.types';
import { radius } from 'src/styles/Themes/Metrics';
import { fonts } from '@/styles/Themes/Fonts';

export const InputStyled = styled.input<ThemedStyledProps<TInputProps, TTheme>>`
    width: 100%;
    border: none;
    outline: 0;
    outline-offset: 0;
    height: 50px;
    font: ${fonts.Input};
    color: ${({ theme, disabled }) =>
        disabled ? theme.colors.grays.normal : theme.colors.grays.light};
    padding: 12px 30px;
`;

export const InputWrapper = styled.div<ThemedStyledProps<TInputProps, TTheme>>`
    ${FlexPresets.flexRowCenter}
    overflow: hidden;
    border-radius: ${radius.button};
    border: 1.5px solid
        ${({ theme, disabled }) => (disabled ? theme.colors.shades.gray3 : theme.colors.primary)};

    padding-right: 6px;
    ${({ variant }) =>
        variant === 'primary'
            ? css`
                  border: none;
              `
            : ''};
`;
